import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ContentLoader from "react-content-loader";
import NumberFormat from "react-number-format";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  cardHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2%",
    borderBottom: "1px solid #6240a5",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
    paddingRight: 10,
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    padding: "4%",
    margin: "2%",
  },
  cardIcon: {
    color: "#6240a5",
    marginRight: "1%",
  },
  cardTitle: {
    fontFamily: "Nunito",
    fontSize: "1.12em",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6240a5",
  },
  importe: {
    display: "block",
    color: theme.palette.textColor,
    "@media (max-width: 480px)": {
      fontSize: "20px !important",
      marginTop: "0",
    },
    "@media (min-width: 480px)": {
      fontSize: "24px !important",
      marginTop: "-4px",
    },
  },
  tableContainer: {
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    fontSize: 16,
    color: "#405A84",
  },
  sinCotizaciones: {
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
  },
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
  },
  tableCell: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
  },
  tableCellCenter: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    alignItems: "center",
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  dash: { display: "flex", justifyContent: "center" },
}));

const formatCaption = (key) => {
  if (!key || key === null) return null;

  let keyCapitalized = key.charAt(0).toUpperCase() + key.slice(1);

  return keyCapitalized.match(/[A-Z][a-z]+|[A-Z]+|[0-9]+/g).join(" ");
};

const CardDetalle = ({ title, isLoading, data, IconHeader }) => {
  const classes = useStyles();

  const getValueParsed = (key, value) => {
    if (!value) return <span className={classes.dash}>&mdash;</span>;

    if (Date.parse(value) && value.length === 28) {
      return format(Date.parse(value), "dd'/'MM'/'yyyy", {
        locale: es,
      });
    }

    if (!isNaN(value) && value.toString().indexOf(".") !== -1) {
      return (
        <NumberFormat
          value={value}
          displayType={"text"}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={"."}
          decimalSeparator={","}
          prefix={"$ "}
        />
      );
    }

    if (value.startsWith && value.startsWith("https"))
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          Abrir documento
        </a>
      );

    if (key === "numeroDocumentoAsegurado")
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return value;
  };

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.cardContainer}>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              {IconHeader && <IconHeader className={classes.cardIcon} />}
              <Typography className={classes.cardTitle}>{title}</Typography>
            </div>
            {!isLoading &&
              Object.keys(data).map(
                (key) =>
                  key !== "cuotas" && (
                    <CardItem
                      caption={formatCaption(key)}
                      value={getValueParsed(key, data[key])}
                    />
                  )
              )}
            {isLoading && (
              <>
                <CardItem isLoading />
                <CardItem isLoading />
                <CardItem isLoading />
                <CardItem isLoading />
                <CardItem isLoading />
                <CardItem isLoading />
                <CardItem isLoading />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetalle;

const useStylesCardItem = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  dash: { display: "flex", justifyContent: "center" },
}));

const CardItem = ({ caption, value, isLoading }) => {
  const classes = useStylesCardItem();

  if (isLoading)
    return (
      <div className={classes.container}>
        <div style={{ width: "100%", height: "100%" }}>
          <ContentLoader
            height={20}
            width={"100%"}
            speed={1}
            backgroundColor="#f7f8ff"
            foregroundColor="#d9e8df"
          >
            <rect x="35" y="0" rx="4" ry="4" width="80%" height="20" />
          </ContentLoader>
        </div>
      </div>
    );

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{caption}</Typography>
      <Typography className={classes.value}>
        {value ? value : <div className={classes.dash}>&mdash;</div>}
      </Typography>
    </div>
  );
};
