import React from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Divider } from "@mui/material";
import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {
  setTautos30FileName,
  setExtradFileName,
  setGruposFileName,
  setSubmitting,
  moveArchivosInfoautoFinalDest,
} from "./actions";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { useSelector, useDispatch } from "react-redux";
import environment from "~libs/environment";
import SuccessDialog from "~components/shared/SuccessDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  tableContainer: {
    width: "100%",
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
}));

const filenames = {
  tautos30: setTautos30FileName,
  extrad: setExtradFileName,
  grupos: setGruposFileName,
};

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.subidaArchivosInfoautoReducer,
  }));

const SubidaArchivosInfoauto = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    tautos30FileNameSelected,
    extradFileNameSelected,
    gruposFileNameSelected,
    archivosSubidos,
  } = useReduxSelector();

  const handleFileAdded = (event, filename) => {
    const action = filenames[filename];
    const value = `temp/${event.target.value}`;
    dispatch(
      action({
        value: value,
        valid: true,
      })
    );
  };

  const handleRemoved = (event, filename) => {
    const action = filenames[filename];
    dispatch(
      action({
        value: null,
        valid: false,
      })
    );
  };

  const handleSubirArchivos = () => {
    dispatch(setSubmitting(true));
    handleSubmitForm();
  };

  const handleSubmitForm = async () => {
    if (!tautos30FileNameSelected) {
      dispatch(queueMessage("Debe seleccionar el archivo tautos30.dbf"));
      return;
    }

    if (!extradFileNameSelected) {
      dispatch(queueMessage("Debe seleccionar el archivo extrad.dbf"));
      return;
    }

    if (!gruposFileNameSelected) {
      dispatch(queueMessage("Debe seleccionar el archivo grupos.dbf"));
      return;
    }

    let data = {
      sourceBucket: environment.bucketName,
      tautos30Filename: tautos30FileNameSelected.value,
      extradFilename: extradFileNameSelected.value,
      gruposFilename: gruposFileNameSelected.value,
      destBucket: environment.vehiclesBucketName,
    };
    dispatch(moveArchivosInfoautoFinalDest(data));
  };

  return (
    <div className={classes.root}>
      <SuccessDialog
        message="Archivos subidos correctamente"
        openDialog={archivosSubidos}
      />

      <Paper className={classes.root}>
        <div className={classes.tableContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
          <Divider></Divider>

          <ItemFileUploaderCard
            label="Subir archivo tautos30.dbf"
            onAdded={(event) => handleFileAdded(event, "tautos30")}
            onRemoved={(event) => handleRemoved(event, "tautos30")}
            acceptImage={false}
            acceptPdf={false}
          />
          <ItemFileUploaderCard
            label="Subir archivo extrad.dbf"
            onAdded={(event) => handleFileAdded(event, "extrad")}
            onRemoved={(event) => handleRemoved(event, "extrad")}
            acceptImage={false}
            acceptPdf={false}
          />
          <ItemFileUploaderCard
            label="Subir archivo grupos.dbf"
            onAdded={(event) => handleFileAdded(event, "grupos")}
            onRemoved={(event) => handleRemoved(event, "grupos")}
            acceptImage={false}
            acceptPdf={false}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSubirArchivos}
          >
            Subir Archivos
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default SubidaArchivosInfoauto;
