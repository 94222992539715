import React, { useCallback, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import ItemLoadingCard from "~business/Cards/ItemLoadingCard";
import IconEdit from "~assets/icons/ic_edit.svg";
import useField from "~hooks/useField";
import ListView from "~styled/listView/ListView";
import Next from "@mui/icons-material/ArrowForward";
import filterItems from "~libs/filterItems";
import Search from "~assets/icons/searchGris.png";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #8B8B8B",
    padding: "2%",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
    position: "relative",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  input: {
    padding: "5%",
    textAlign: "right",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "grey",
    paddingLeft: 8,
    cursor: "pointer",
  },
  listView: {
    position: "absolute",
    top: "85%",
    width: "100%",
    zIndex: "2",
  },
}));

const MultipleItemSelectorCard = ({
  id,
  label,
  value,
  onChange,
  onClick,
  placeholder,
  tooltipContent,
  required,
  displayError,
  focus,
  onBlur,
  readOnly,
  isLoading,
  items,
}) => {
  const classes = useStyles();

  const [filter, setFilter] = useState("");
  const [listView, setListView] = useState(false);

  const validateFunction = useCallback(handleValidate(required), [required]);

  const [localValue, setValue, handleEdit, reference] = useField(
    value,
    validateFunction,
    focus,
    isLoading,
    readOnly
  );

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  const handleChangeValue = (value) => {
    setFilter(value);
    setValue((prev) => ({ ...prev, value: value }));
    setListView(true);
  };

  const handleItem = (value) => {
    setFilter("");
    setListView(false);
    setValue((prev) => ({ ...prev, value: value.label }));
    if (onChange) {
      onChange(value);
    }
  };

  const handleClickTooltip = (event) => {
    event.stopPropagation();
    setListView(true);
  };

  const showError = !localValue.valid && displayError;

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {localValue.editing && (
          <>
            <TextField
              id={id}
              style={{ width: "100%" }}
              inputProps={{
                className: classes.input,
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              onChange={(event) => handleChangeValue(event.target.value)}
              onBlur={handleBlur}
              value={localValue.value}
              variant="outlined"
              placeholder={placeholder}
              error={showError}
              inputRef={reference}
            />
            <Tooltip title={tooltipContent}>
              <div onClick={handleClickTooltip} className={classes.buttonLink}>
                <img
                  style={{ width: 28, height: 28 }}
                  alt="Buscar"
                  src={Search}
                ></img>
              </div>
            </Tooltip>
            {listView && (
              <div className={classes.listView}>
                <ListView
                  isLoading={isLoading}
                  onItemSelected={handleItem}
                  IconMostUsed={Next}
                  list={filter === "" ? items : filterItems(items, filter)}
                />
              </div>
            )}
          </>
        )}

        {!localValue.editing && (
          <>
            {value && (
              <Typography className={classes.value}>{value}</Typography>
            )}
            {!value && <div>&mdash;</div>}
            {!readOnly && (
              <div
                className={classes.buttonLink}
                onClick={() => handleEdit(true)}
              >
                <img alt="" src={IconEdit} className={classes.icon} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MultipleItemSelectorCard;

const handleValidate = (required) => (value) => {
  const isDataValid = !required || value ? true : false;

  return isDataValid;
};
