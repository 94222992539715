import React, { useState } from "react";
import DetalleCard from "../../../../business/Cards/DetalleCard.js";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import ColorLabelProductor from "~styled/colorLabelProductor/ColorLabelProductor";
import ColorLabelPas from "./../../../../styled/colorLabelPas/ColorLabelPas";
import { Tooltip, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { getDatosPago, clearDatosPago } from "../actions.js";
import DatosPagoDialog from "~styled/dialogs/DatosPagoDialog";
import pxToRem from "~libs/pxToRem";
import { formasPagoOptions } from "~constants/formasPago";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDateCard from "~business/Cards/ItemDateCard";
import ItemCurrencyCard from "~business/Cards/ItemCurrencyCard";
import ItemCustomCard from "~business/Cards/ItemCustomCard";
import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
    paddingLeft: "0.5%",
  },
  datosPago: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
    paddingRight: pxToRem(4),
  },
}));

const title = "Datos Generales";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle =
      state.detalleSolicitudEmisionReducer.detalleSolicitudEmision;
    const datosEmision = detalle.datosEmision;

    return {
      datosPago: state.detalleSolicitudEmisionReducer.datosPago,
      negocio: detalle.negocio,
      vigenciaDesde: detalle.vigenciaDesde,
      compania: datosEmision.compania,
      coberturaSeleccionada: datosEmision.coberturaSeleccionada,
      franquicia: datosEmision.franquicia,
      cotizacionIdExterno: detalle.cotizacionIdExterno,
      premio: datosEmision.precioInformado,
      formaPago: datosEmision.formaPago,
      certificadoRC: detalle.certificadoRCUrl,
      marcaTC: datosEmision.marcaTarjetaCredito,
      productor: detalle.productor,
      solicitudEmision: detalle,
    };
  });

const DatosGeneralesCard = ({ isLoading, solicitudId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openDatosPago, setOpenDatosPago] = useState(false);

  const {
    negocio,
    productor,
    compania,
    coberturaSeleccionada,
    franquicia,
    cotizacionIdExterno,
    premio,
    vigenciaDesde,
    formaPago,
    datosPago,
    certificadoRC,
    marcaTC,
    solicitudEmision,
  } = useReduxSelector();

  const handleMostrarDatosPago = () => {
    setOpenDatosPago(true);
    dispatch(getDatosPago(solicitudId));
  };

  const showDatosPago =
    formaPago === "DebitoConCBU" || formaPago === "DebitoConTarjetaCredito";

  //TODO: abstraer componente mostrar tarjeta de credito
  return (
    <>
      {!isLoading && (
        <DatosPagoDialog
          isLoading={!datosPago && openDatosPago}
          openDialog={openDatosPago}
          onClose={() => {
            setOpenDatosPago(false);
            dispatch(clearDatosPago());
          }}
          datosPago={datosPago}
          formaPago={formaPago} //detalleSolicitudEmision.datosEmision.
          marcaTC={marcaTC}
        />
      )}

      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemCustomCard isLoading={isLoading} label={"Negocio"}>
          <ColorLabelPas solicitudEmision={solicitudEmision} />
          <ColorLabelNegocio negocio={negocio} />
          <ColorLabelProductor productor={productor} />
        </ItemCustomCard>
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"Compañía"}
          value={compania}
        />
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"Cobertura Seleccionada"}
          value={coberturaSeleccionada}
        />
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"Franquicia"}
          value={franquicia}
        />
        <ItemInputCard
          readOnly
          isLoading={isLoading}
          label={"Nº Cotización Cía."}
          value={cotizacionIdExterno}
        />
        <ItemCurrencyCard
          readOnly
          isLoading={isLoading}
          label={"Premio Mensual"}
          value={premio}
        />
        {showDatosPago && (
          <ItemCustomCard isLoading={isLoading} label={"Forma de Pago"}>
            <Typography className={classes.value}>
              {formasPagoOptions[formaPago]}
            </Typography>

            <Tooltip title={"Mostrar"}>
              <IconButton
                className={classes.iconButton}
                onClick={handleMostrarDatosPago}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </ItemCustomCard>
        )}
        {!showDatosPago && (
          <ItemInputCard
            readOnly
            isLoading={isLoading}
            label={"Forma de Pago"}
            value={"Cupón de pago"}
          />
        )}
        <ItemDateCard
          readOnly
          isLoading={isLoading}
          label={"Vigencia Desde"}
          value={vigenciaDesde}
        />
        <ItemFileUploaderCard
          label="Certificado RC"
          isLoading={isLoading}
          value={certificadoRC}
          readOnly
        />
      </DetalleCard>
    </>
  );
};

export default DatosGeneralesCard;
