import {
  errorUnlessOk,
  buildRequest,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import { paths, cargaPublicaPath } from "~constants/referenceNames";

const siniestrosCargaPublica = () => {
  return {
    postSiniestro: (data) => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.cargaPublicaSiniestroUrl}${paths[cargaPublicaPath]}/cargaPublica`;

      console.log(url);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getPoliza: (id) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      let url =
        environment.cargaPublicaSiniestroUrl +
        paths[cargaPublicaPath] +
        "/polizas" +
        "/" +
        id;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getPolizas: (data) => {
      const request = buildRequest("GET", null, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;
      console.log("as");

      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;

      parameters = data.estado
        ? parameters + `&Estado=${data.estado}`
        : parameters;

      let url =
        environment.cargaPublicaSiniestroUrl +
        paths[cargaPublicaPath] +
        "/polizas" +
        "?" +
        parameters;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { siniestrosCargaPublica };
