import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { setVigenciaDesde, clearVigenciaDesde } from "../actions";

import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import DatePicker from "~styled/datePicker/DatePicker";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

import { calculateDate } from "~libs/dateCalculator";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 8,
    paddingBottom: 100,
    width: "100%",
    maxWidth: 500,
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
  buttonContinuar: {
    paddingTop: 24,
  },
}));

const FechaVigenciaDesdeStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    dispatch(clearVigenciaDesde());
  }, [dispatch]);

  const canContinue = value && !isNaN(value.getTime());
  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) {
      dispatch(setVigenciaDesde({ value: value, selected: true }));
    }
  };

  return (
    <div className={classes.cotizador}>
      <div className={classes.title}>
        <PrimaryTitle
          label="¿En qué fecha iniciará la vigencia del seguro?"
          marginBottom={48}
        />
      </div>

      <DatePicker
        fullWidth
        className={classes.datePicker}
        onChange={(date) => {
          setValue(date);
        }}
        minDate={new Date()}
        maxDate={calculateDate(new Date(), 15)}
        value={value}
        onKeyDown={onKeyDown}
      />
      <div className={classes.buttonContinuar}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            dispatch(setVigenciaDesde({ value: value, selected: true }));
          }}
        />
      </div>
    </div>
  );
};

export default FechaVigenciaDesdeStep;
