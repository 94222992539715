import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { setCuil, clearCuil } from "../actions";

import TextField from "@mui/material/TextField";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";
import CuiInput from "~styled/input/CuiInput";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 8,
    paddingBottom: 100,
    width: "100%",
    maxWidth: 500,
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
  buttonContinuar: {
    paddingTop: 24,
  },
}));

const CuilStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cuil, setNumeroCuil] = useState("");

  useEffect(() => {
    dispatch(clearCuil());
  }, [dispatch]);

  const canContinue = validateCuil(cuil);
  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue)
      dispatch(setCuil({ value: cuil, selected: true }));
  };

  return (
    <div className={classes.cotizador}>
      <div className={classes.title}>
        <PrimaryTitle label="Ingresá el cuil del asegurado" marginBottom={60} />
      </div>

      <TextField
        fullWidth={true}
        onFocus={(event) => event.target.select()}
        autoFocus
        value={cuil}
        type="tel"
        onKeyDown={onKeyDown}
        onChange={(event) => {
          const value = event.target.value.replace(/\s/g, "");

          setNumeroCuil(value);
        }}
        placeholder="Ej: 11-11111111-11"
        InputProps={{
          inputComponent: CuiInput,
        }}
      />
      <div className={classes.buttonContinuar}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            dispatch(setCuil({ value: cuil, selected: true }));
          }}
        />
      </div>
    </div>
  );
};

export default CuilStep;

const validateCuil = (value) => {
  const expression = RegExp("[0-9][0-9](-)?[0-9]{8}(-)?[0-9]");
  const result = expression.test(value);
  return result;
};
