import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import DetalleSolicitudCardItem from "../DetalleSolicitudCardItem";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2%",
    borderBottom: "1px solid #6240a5",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
    paddingRight: 10,
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    padding: "4%",
    margin: "2%",
  },
  cardIcon: {
    color: "#6240a5",
    marginRight: "1%",
  },
  cardTitle: {
    fontFamily: "Nunito",
    fontSize: "1.3em",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6240a5",
  },
  dash: { display: "flex", justifyContent: "center" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleSolicitudEmision:
      state.detalleSolicitudHistoricaReducer.detalleSolicitudEmision,
  }));

const OriginadorCard = () => {
  const classes = useStyles();
  const { detalleSolicitudEmision } = useReduxSelector();
  return (
    <>
      {detalleSolicitudEmision.datosOriginador && (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <PersonIcon className={classes.cardIcon} />
            <Typography className={classes.cardTitle}>
              {"Originador"}
            </Typography>
          </div>
          {detalleSolicitudEmision.negocio === "MercadoAbierto" && (
            <>
              <DetalleSolicitudCardItem
                caption="Nombre"
                value={
                  detalleSolicitudEmision.datosOriginador.nombre !== null ? (
                    detalleSolicitudEmision.datosOriginador.nombre
                  ) : (
                    <span className={classes.dash}>&mdash;</span>
                  )
                }
              />
              <DetalleSolicitudCardItem
                caption="E-Mail"
                value={
                  detalleSolicitudEmision.datosOriginador.email !== null ? (
                    detalleSolicitudEmision.datosOriginador.email
                  ) : (
                    <span className={classes.dash}>&mdash;</span>
                  )
                }
              />
              <DetalleSolicitudCardItem
                caption="Teléfono"
                value={
                  detalleSolicitudEmision.datosOriginador.telefono !== null ? (
                    detalleSolicitudEmision.datosOriginador.telefono
                  ) : (
                    <span className={classes.dash}>&mdash;</span>
                  )
                }
              />
            </>
          )}

          {detalleSolicitudEmision.negocio === "Prendarios" && (
            <DetalleSolicitudCardItem
              caption="Operación"
              value={
                detalleSolicitudEmision.operacionId !== null ? (
                  detalleSolicitudEmision.operacionId
                ) : (
                  <span className={classes.dash}>&mdash;</span>
                )
              }
            />
          )}

          <DetalleSolicitudCardItem
            caption="Oficina"
            value={
              detalleSolicitudEmision.datosOriginador.nombreOficina !== null ? (
                detalleSolicitudEmision.datosOriginador.nombreOficina
              ) : (
                <span className={classes.dash}>&mdash;</span>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Canal Originador"
            value={
              detalleSolicitudEmision.datosOriginador.nombreAgencia !== null ? (
                detalleSolicitudEmision.datosOriginador.nombreAgencia
              ) : (
                <span className={classes.dash}>&mdash;</span>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Fecha creación"
            value={moment(detalleSolicitudEmision.fechaCreacion).format(
              "DD/MM/YYYY HH:mm"
            )}
          />
          <DetalleSolicitudCardItem
            caption="Origen"
            value={
              detalleSolicitudEmision.datosOriginador.origen !== null ? (
                detalleSolicitudEmision.datosOriginador.origen
              ) : (
                <span className={classes.dash}>&mdash;</span>
              )
            }
          />
        </div>
      )}
    </>
  );
};

export default OriginadorCard;
