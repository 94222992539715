import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";

const tiposCobertura = () => {
  return {
    getTiposCobertura: (data) => {
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      return fetch(`${environment.segurosUrl}/tiposCoberturas`, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { tiposCobertura };
