import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import ItemInputCard from "~business/Cards/ItemInputCard";

import {
  setMail,
  setTelefono,
  setNumeroDocumentoAsegurado,
  setNombreAsegurado,
  setSubmitting,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    numeroDocumentoAsegurado:
      state.cargaSiniestroPublicaReducer.numeroDocumentoAseguradoSelected,
    nombreAsegurado: state.cargaSiniestroPublicaReducer.nombreAseguradoSelected,
    mail: state.cargaSiniestroPublicaReducer.mailSelected,
    telefono: state.cargaSiniestroPublicaReducer.telefonoSelected,
    poliza: state.cargaSiniestroPublicaReducer.polizaVinculada,
  }));

const title = "Datos del Asegurado";

const DatosAseguradoCard = ({ isLoading }) => {
  const dispatch = useDispatch();

  const { numeroDocumentoAsegurado, nombreAsegurado, mail, telefono, poliza } =
    useReduxSelector();

  let timer = null;

  const handleChangeNumeroDocumento = (numeroDocumento) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNumeroDocumentoAsegurado(numeroDocumento));
    }, 200);
  };

  const handleChangeNombre = (nombre) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setNombreAsegurado(nombre));
    }, 200);
  };

  const handleChangeMail = (mail) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setMail(mail));
    }, 200);
  };

  const handleChangeTelefono = (telefono) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setTelefono(telefono));
    }, 200);
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  return (
    <DetalleCard title={title} IconHeader={PersonIcon}>
      {!poliza && (
        <>
          <ItemInputCard
            id={"dni"}
            isLoading={isLoading}
            label={"DNI"}
            value={poliza ? numeroDocumentoAsegurado.value : ""}
            onChange={handleChangeNumeroDocumento}
            onBlur={handleBlur}
            focus={numeroDocumentoAsegurado.focus}
            required
          />
          <ItemInputCard
            id={"nombreAsegurado"}
            isLoading={isLoading}
            label={"Nombre Completo"}
            value={poliza ? nombreAsegurado.value : ""}
            onChange={handleChangeNombre}
            onBlur={handleBlur}
            focus={nombreAsegurado.focus}
            required
          />
          <ItemInputCard
            id={"mail"}
            isLoading={isLoading}
            label={"Correo Electrónico"}
            value={mail.value}
            onChange={handleChangeMail}
            focus={mail.focus}
            required
          />
          <ItemInputCard
            id={"telefono"}
            isLoading={isLoading}
            label={"Teléfono"}
            value={telefono.value}
            onChange={handleChangeTelefono}
            focus={telefono.focus}
            required
          />
        </>
      )}
      {poliza && (
        <>
          <ItemInputCard
            id={"dni"}
            isLoading={isLoading}
            label={"DNI"}
            value={numeroDocumentoAsegurado.value}
            readOnly
          />
          <ItemInputCard
            id={"nombreAsegurado"}
            isLoading={isLoading}
            label={"Nombre Completo"}
            value={nombreAsegurado.value}
            readOnly
          />
          <ItemInputCard
            id={"mail"}
            isLoading={isLoading}
            label={"Correo Electrónico"}
            value={mail.value}
            onChange={handleChangeMail}
            focus={mail.focus}
            required={poliza}
            readOnly={!poliza}
          />
          <ItemInputCard
            id={"telefono"}
            isLoading={isLoading}
            label={"Teléfono"}
            value={telefono.value}
            onChange={handleChangeTelefono}
            focus={telefono.focus}
            required={poliza}
            readOnly={!poliza}
          />
        </>
      )}
    </DetalleCard>
  );
};

export default DatosAseguradoCard;
