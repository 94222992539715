import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import { paths, novedadesPath } from "~constants/referenceNames";

let mockActive = false;
let mockActiveNovedades = false;

const novedades = () => {
  return {
    getNovedad: (id) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      let url = environment.segurosUrl + paths[novedadesPath] + "/" + id;
      return mockActive
        ? getNovedadMock()
        : fetch(url, request)
            .then(errorUnlessOk)
            .then((response) => {
              return response.json();
            })
            .catch((error) => {
              console.error(error);
              throw error;
            });
    },

    getNovedades: (data) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;

      parameters = data.negocio
        ? parameters + `&Negocio=${data.negocio}`
        : parameters;

      parameters = data.compania
        ? parameters + `&Compania=${data.compania}`
        : parameters;

      parameters = data.tipoNovedad
        ? parameters + `&TipoNovedad=${data.tipoNovedad}`
        : parameters;

      parameters = data.fechaNovedadDesde
        ? parameters +
          `&FechaNovedadDesde=${data.fechaNovedadDesde.toISOString()}`
        : parameters;

      if (data.fechaNovedadHasta) {
        data.fechaNovedadHasta.setHours(23, 59, 59);
        parameters =
          parameters +
          `&FechaNovedadHasta=${data.fechaNovedadHasta.toISOString()}`;
      }

      parameters = data.inicioVigenciaDesde
        ? parameters +
          `&InicioVigenciaDesde=${data.inicioVigenciaDesde.toISOString()}`
        : parameters;

      if (data.inicioVigenciaHasta) {
        data.inicioVigenciaHasta.setHours(23, 59, 59);
        parameters =
          parameters +
          `&InicioVigenciaHasta=${data.inicioVigenciaHasta.toISOString()}`;
      }

      parameters =
        parameters +
        "&SortBy=FechaCreacionNovedad:desc,PolizaVigenciaDesde:asc";

      let url =
        environment.segurosUrl + paths[novedadesPath] + "?" + parameters;

      return mockActiveNovedades
        ? getNovedadesMock(data)
        : fetch(url, request)
            .then(errorUnlessOk)
            .then((response) => {
              return response.json();
            })
            .catch((error) => {
              console.error(error);
              throw error;
            });
    },
  };
};

export { novedades };

const getNovedadMock = () => {
  return {
    id: "58d619e7-cec4-4e6b-9ecd-cbe48a71d3bc",
    IdOrigen: "eb064ae6-c1a8-4770-ba69-62b8f3f4f59d",
    Negocio: "MercadoAbierto",
    TipoNovedad: "Emision",
    Compania: "Sancor",
    Rama: "Automotores",
    NumeroPoliza: "8439954",
    Orden: 8,
    Dominio: "OZY103",
    FechaCreacionNovedad: "2019-06-27T18:43:54.942Z",
    FechaEmision: "2019-03-28T03:00:00.000Z",
    FechaRenovacion: null,
    PolizaVigenciaDesde: "2019-03-28T03:00:00.000Z",
    PolizaVigenciaHasta: "2019-09-28T03:00:00.000Z",
    FechaAnulacion: null,
    CantidadCuotas: 6,
    PrimaTotal: "8736.60",
    PremioTotal: "12575.00",
    RenuevaA: null,
    CodigoOrganizador: "102224",
    CodigoProductor: "227128",
    TipoCobertura: "9",
    TipoDocumentoAsegurado: "DNI",
    NumeroDocumentoAsegurado: "92969018",
    NombreCompletoAsegurado: "SAAVEDRA ARAVENA SAMUEL IVAN",
    Cuotas: [
      {
        NumeroCuota: 1,
        Prima: "1456.10",
        Premio: "2100.00",
        FechaDesde: "2019-03-28T03:00:00.000Z",
        FechaHasta: "2019-04-28T03:00:00.000Z",
        FechaVencimiento: "2019-03-28T03:00:00.000Z",
      },
      {
        NumeroCuota: 2,
        Prima: "1456.10",
        Premio: "2095.00",
        FechaDesde: "2019-04-28T03:00:00.000Z",
        FechaHasta: "2019-05-28T03:00:00.000Z",
        FechaVencimiento: "2019-04-28T03:00:00.000Z",
      },
      {
        NumeroCuota: 3,
        Prima: "1456.10",
        Premio: "2095.00",
        FechaDesde: "2019-05-28T03:00:00.000Z",
        FechaHasta: "2019-06-28T03:00:00.000Z",
        FechaVencimiento: "2019-05-28T03:00:00.000Z",
      },
      {
        NumeroCuota: 4,
        Prima: "1456.10",
        Premio: "2095.00",
        FechaDesde: "2019-06-28T03:00:00.000Z",
        FechaHasta: "2019-07-28T03:00:00.000Z",
        FechaVencimiento: "2019-06-28T03:00:00.000Z",
      },
      {
        NumeroCuota: 5,
        Prima: "1456.10",
        Premio: "2095.00",
        FechaDesde: "2019-07-28T03:00:00.000Z",
        FechaHasta: "2019-08-28T03:00:00.000Z",
        FechaVencimiento: "2019-07-28T03:00:00.000Z",
      },
      {
        NumeroCuota: 6,
        Prima: "1456.10",
        Premio: "2095.00",
        FechaDesde: "2019-08-28T03:00:00.000Z",
        FechaHasta: "2019-09-28T03:00:00.000Z",
        FechaVencimiento: "2019-08-28T03:00:00.000Z",
      },
    ],
  };
};

const getNovedadesMock = (data) => {
  const novedades = [
    {
      id: "2653903c-bdc9-4ed0-afef-1794ce6cb179",
      IdOrigen: "a275a5aa-009d-4586-ae23-e2412b256745",
      Negocio: "MercadoAbierto",
      TipoNovedad: "Siniestro",
      Compania: "Sancor",
      Rama: "Automotores",
      NumeroPoliza: "8946730",
      Orden: 1,
      Dominio: "LWV662",
      SumaAsegurada: "355000.0",
      FechaCreacionNovedad: "2020-01-03T20:12:54.407Z",
      FechaEmision: null,
      FechaRenovacion: null,
      PolizaVigenciaDesde: "2020-01-23T03:00:00.000Z",
      PolizaVigenciaHasta: "2020-07-23T03:00:00.000Z",
      FechaAnulacion: null,
      CantidadCuotas: 5,
      PrimaTotal: null,
      PremioTotal: null,
      RenuevaA: null,
      CodigoOrganizador: "102446",
      CodigoProductor: "228075",
      TipoCobertura: null,
      TipoDocumentoAsegurado: "DNI",
      NumeroDocumentoAsegurado: "30707621",
      NombreCompletoAsegurado: "ALVAREZ PEREZ MARIBEL",
      Cuotas: [],
      UbicacionTarjeta: null,
      UbicacionPoliza: null,
    },
  ];

  return {
    novedades: novedades,
    totalRegistros: novedades.length,
  };
};
