import { useQuery } from "react-query";
import { cotizaciones } from "~api/cotizaciones";
import { useState } from "react";
import { multicotizador } from "~api/multicotizador";

const MAX_REQUESTS = 10;

const useSeguros = ({
  codia,
  anio,
  codigoPostal,
  localidad,
  provincia,
  uso,
  condicionFiscal,
  tieneGNC,
  formaDePago,
  compania,
}) => {
  const [
    postCotizacionLoading,
    postCotizacionResponse,
    postCotizacionError,
  ] = useQuerySegurosPostPedidoCotizaciones({
    codia,
    anio,
    codigoPostal,
    localidad,
    provincia,
    uso,
    condicionFiscal,
    tieneGNC,
    formaDePago,
  });

  const [
    cotizacionesLoading,
    cotizacionesResponse,
    cotizacionesError,
  ] = useQuerySegurosGetCotizaciones(postCotizacionResponse?.cotizacionId);

  return {
    isLoading: postCotizacionLoading || cotizacionesLoading,
    cotizacionId: postCotizacionResponse?.cotizacionId,
    cotizaciones: cotizacionesResponse,
    error: postCotizacionError || cotizacionesError,
  };
};

export default useSeguros;

const useQuerySegurosPostPedidoCotizaciones = ({
  codia,
  anio,
  codigoPostal,
  localidad,
  provincia,
  uso,
  condicionFiscal,
  tieneGNC,
  formaDePago,
}) => {
  const postCotizaciones = async () => {
    const data = {
      ModeloVehiculo: codia,
      AnioVehiculo: anio,
      CodigoPostal: codigoPostal,
      Localidad: localidad,
      ProvinciaId: provincia,
      Negocio: "MercadoAbierto",
      UsoDelVehiculo: uso,
      CondicionFiscal: condicionFiscal,
      TieneGNC: tieneGNC,
      Periodo: "Anual",
      FormaPago: formaDePago,
    };

    return await multicotizador().postCotizacion(data);
    //return { cotizacionId: "343afcb0-c9b4-49af-a201-f195a63b6d3b" };
  };

  const { isLoading, data, error } = useQuery(
    [
      "postCotizacionesEndoso",
      {
        codia,
        anio,
        codigoPostal,
        localidad,
        provincia,
        uso,
        condicionFiscal,
        tieneGNC,
        formaDePago,
      },
    ],
    postCotizaciones,
    {
      staleTime: 1000 * 60 * 15, //Los datos duran 15 min en cache
      //   enabled: solicitudId,
    }
  );

  return [isLoading, data, error];
};

const useQuerySegurosGetCotizaciones = (cotizacionId) => {
  const [polizasRequestCount, setPolizasRequestCount] = useState(0);

  const isPending = polizasRequestCount <= MAX_REQUESTS;

  const getCotizaciones = async () => {
    setPolizasRequestCount((count) => count + 1);

    return await cotizaciones().getDetalleCotizacion(cotizacionId);
  };

  const { isLoading, data, error } = useQuery(
    ["getCotizacionesEndoso", { cotizacionId }],
    getCotizaciones,
    {
      //staleTime: 1000 * 60 * 15, //Los datos duran 15 min en cache
      enabled: cotizacionId && polizasRequestCount <= MAX_REQUESTS,
      refetchInterval: 1000 * 2 * polizasRequestCount,
    }
  );

  return [isPending || isLoading, data, error];
};

// import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { getDetalleCotizacion } from "./actions";

// const MAX_REQUESTS = 4;

// const useGetCotizaciones = (cotizacionId) => {
//   const dispatch = useDispatch();
//   const [polizasRequestCount, setPolizasRequestCount] = useState(0);

//   const isPending = polizasRequestCount <= MAX_REQUESTS;

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       if (isPending && cotizacionId) {
//         dispatch(getDetalleCotizacion(cotizacionId));
//         setPolizasRequestCount((value) => value + 1);
//       }
//     }, polizasRequestCount * 1000);
//     return () => clearTimeout(timer);
//   }, [dispatch, isPending, cotizacionId, polizasRequestCount]);

//   return isPending;
// };

// export default useGetCotizaciones;
