import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import { paths, siniestrosPath } from "~constants/referenceNames";

const siniestros = () => {
  return {
    getSiniestros: (data) => {
      const dateWithoutTimezone = (date) => {
        const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
        const withoutTimezone = new Date(date.valueOf() - tzoffset);
        return withoutTimezone;
      }
  
      const fechaDesdeUTCClear = data.fechaDesde ? dateWithoutTimezone(data.fechaDesde) : "";
      const fechaHastaUTCClear = data.fechaHasta ? dateWithoutTimezone(data.fechaHasta) : "";
    
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;

      parameters = data.estado
        ? parameters + `&Estado=${data.estado}`
        : parameters;

      parameters = data.tipo && data.tipo !== "Seleccionar"
        ? parameters + `&Tipo=${data.tipo}`
        : parameters;
      
      parameters = data.fechaDesde
        ? parameters + `&FechaDesde=${fechaDesdeUTCClear.toISOString()}`
        : parameters;
      if (data.fechaHasta) {
        fechaHastaUTCClear.setUTCHours(23, 59, 59);
        parameters =
          parameters + `&FechaHasta=${fechaHastaUTCClear.toISOString()}`;
      }

      let url =
        environment.segurosUrl + paths[siniestrosPath] + "?" + parameters;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getSiniestro: (id) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      let url = environment.segurosUrl + paths[siniestrosPath] + "/" + id;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postSiniestro: (data) => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[siniestrosPath]}/`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    patchSiniestroEstado: (siniestroId, data) => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      console.log(data);
      const url = `${environment.segurosUrl}${paths[siniestrosPath]}/${siniestroId}/cambiarEstado`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    patchSiniestroTipo: (siniestroId, data) => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[siniestrosPath]}/${siniestroId}/cambiarTipo`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postSiniestroComentario: (siniestroId, data) => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[siniestrosPath]}/${siniestroId}/comentarios`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { siniestros };
