import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import filterItems from "~libs/filterItems";
import SearcherList from "~styled/searcherList/SearcherList";
import ListView from "~styled/listView/ListView";
import getOpcionesDePago from "./js/getOpcionesDePago";

const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around",
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
  },
}));

const opcionesDePagoList = getOpcionesDePago();

const FormaDePagoSelector = ({ onChange }) => {
  const [filter, setFilter] = useState("");

  const handleItem = (value) => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <div className={classes.searcher}>
      <SearcherList
        onClear={() => setFilter("")}
        filter={filter}
        onChange={handleFilterChange}
        placeholder={"Buscar forma de pago"}
      />
      <ListView
        onItemSelected={handleItem}
        list={
          filter === ""
            ? opcionesDePagoList
            : filterItems(opcionesDePagoList, filter)
        }
      />
    </div>
  );
};

export default FormaDePagoSelector;
