import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import NumberFormat from "react-number-format";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

import DetalleSolicitudCardItem from "../DetalleSolicitudCardItem";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2%",
    borderBottom: "1px solid #6240a5",
  },

  card: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
    paddingRight: 10,
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    padding: "4%",
    margin: "2%",
  },
  cardIcon: {
    color: "#6240a5",
    marginRight: "1%",
  },
  cardTitle: {
    fontFamily: "Nunito",
    fontSize: "1.3em",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6240a5",
  },

  dash: { display: "flex", justifyContent: "center" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleSolicitudEmision:
      state.detalleSolicitudHistoricaReducer.detalleSolicitudEmision,
    isLoading: state.detalleSolicitudHistoricaReducer.isLoading,
  }));

const VehiculoCard = () => {
  const classes = useStyles();
  const { detalleSolicitudEmision, isLoading } = useReduxSelector();

  let marca =
    !isLoading && detalleSolicitudEmision.datosVehiculo.marca
      ? detalleSolicitudEmision.datosVehiculo.marca.charAt(0) +
        detalleSolicitudEmision.datosVehiculo.marca.toLowerCase().slice(1)
      : "";

  let modelo =
    !isLoading && detalleSolicitudEmision.datosVehiculo.modelo
      ? detalleSolicitudEmision.datosVehiculo.modelo.charAt(0) +
        detalleSolicitudEmision.datosVehiculo.modelo.toLowerCase().slice(1)
      : "";
  let vehiculo = `${marca} ${modelo}`;

  return (
    <>
      {detalleSolicitudEmision.datosVehiculo && (
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <DriveEtaIcon className={classes.cardIcon} />
            <Typography className={classes.cardTitle}>{"Vehículo"}</Typography>
          </div>
          <DetalleSolicitudCardItem
            caption="Vehículo"
            value={
              vehiculo !== null ? (
                `(${
                  detalleSolicitudEmision.datosVehiculo.anio
                }) ${vehiculo.toUpperCase()}`
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Localidad"
            value={`(CP ${detalleSolicitudEmision.datosVehiculo.codigoPostal}) ${detalleSolicitudEmision.datosVehiculo.nombreLocalidad} - ${detalleSolicitudEmision.datosVehiculo.nombreProvincia}`}
          />
          <DetalleSolicitudCardItem
            caption="Dominio"
            value={
              detalleSolicitudEmision.datosVehiculo.dominio !== null ? (
                detalleSolicitudEmision.datosVehiculo.dominio
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Número de motor"
            value={
              detalleSolicitudEmision.datosVehiculo.numeroMotor !== null ? (
                detalleSolicitudEmision.datosVehiculo.numeroMotor
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />

          <DetalleSolicitudCardItem
            caption="Número de chasis"
            value={
              detalleSolicitudEmision.datosVehiculo.numeroChasis !== null ? (
                detalleSolicitudEmision.datosVehiculo.numeroChasis
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Suma asegurada"
            value={
              detalleSolicitudEmision.datosVehiculo.sumaAsegurada ? (
                <NumberFormat
                  id="sumaAsegurada"
                  displayType={"text"}
                  value={detalleSolicitudEmision.datosVehiculo.sumaAsegurada}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={0}
                  prefix={"$"}
                />
              ) : (
                <div className={classes.dash}>&mdash;</div>
              )
            }
          />
          <DetalleSolicitudCardItem
            caption="Uso"
            value={detalleSolicitudEmision.datosVehiculo.uso}
          />
          <DetalleSolicitudCardItem
            caption="Tiene GNC"
            value={
              detalleSolicitudEmision.datosVehiculo.tieneGNC === true
                ? "Si"
                : detalleSolicitudEmision.datosVehiculo.tieneGNC === false
                ? "No"
                : null
            }
          />
        </div>
      )}
    </>
  );
};

export default VehiculoCard;
