import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { setTake, setPage } from "../filtros/actions";
import { patchAnularPoliza } from "./actions";
import { paths, polizasMultiramaPath } from "~constants/referenceNames";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import TableLoading from "~styled/tableLoading/TableLoading";
import Filtros from "../filtros/Filtros";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import {
  VIGENTE,
  EMITIDANOVIGENTE,
  ANULADA,
  VENCIDA,
} from "~constants/polizaEstados";
import PopupAnular from "./PopupAnular";
import SuccessDialog from "./SuccessDialog";

import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  tableContainer: {
    width: "100%",
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  sinCotizaciones: {
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
  },
  table: {
    fontSize: 16,
    color: "#405A84",
  },
  tableCell: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
  },
  tableCellCenter: {
    paddingLeft: 25,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  negocioColumnStyle: {
    padding: "0.5%",
  },
  dash: { display: "flex", alignItems: "center", justifyContent: "center" },
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
  },
  iconPdfButton: {
    color: "#b00020",
    padding: 0,
  },
  anularIcon: {
    color: "red",
  },
}));

const emptyListText = "No hay resultados.";

const useReduxSelector = () =>
  useSelector((state) => {
    return {
      take: state.listadoPolizaMultiramaFiltroReducer.take,
      page: state.listadoPolizaMultiramaFiltroReducer.page,
      isLoading: state.listadoPolizaMultiramaReducer.isLoading,
      totalRegistros: state.listadoPolizaMultiramaReducer.totalRegistros,
      polizas: state.listadoPolizaMultiramaReducer.polizas,
      polizaAnulada: state.listadoPolizaMultiramaReducer.polizaAnulada,
    };
  });

const ListadoPolizasMultirama = () => {
  const { page, take, isLoading, totalRegistros, polizas, polizaAnulada } =
    useReduxSelector();

  const dispatch = useDispatch();

  const empty = totalRegistros === 0;

  const classes = useStyles();

  const [isPopupAnularOpened, setIsPopupAnularOpened] = useState(false);
  const [currentPoliza, setCurrentPoliza] = useState(null);

  const handleClickAnular = (poliza) => {
    setCurrentPoliza(poliza);
    setIsPopupAnularOpened(true);
  };

  const handleConfirmarRechazo = (motivo, polizaId, fechaAnulacion) => {
    setIsPopupAnularOpened(false);
    dispatch(patchAnularPoliza(polizaId, motivo, fechaAnulacion));
  };

  const handleClosePopupRechazo = () => {
    setIsPopupAnularOpened(false);
    setCurrentPoliza(null);
  };

  const handleChangePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch(setTake(value));
  };

  const getValueParsed = (value) => {
    if (!value || value === 0)
      return <div className={classes.dash}>&mdash;</div>;

    if (Date.parse(value) && value.length === 28) {
      return format(Date.parse(value), "dd'/'MM'/'yyyy", {
        locale: es,
      });
    }

    return value;
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.tableContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Filtros />
          </div>
          <Divider></Divider>
          {isLoading && <TableLoading length={5} />}
          {!isLoading && currentPoliza !== null && (
            <>
              <PopupAnular
                isOpened={isPopupAnularOpened}
                polizaId={currentPoliza.id}
                compania={currentPoliza.compania}
                nroPoliza={currentPoliza.numeroPoliza}
                titular={currentPoliza.asegurado}
                onClickAceptar={handleConfirmarRechazo}
                onClose={handleClosePopupRechazo}
              />
              <SuccessDialog
                openDialog={polizaAnulada}
                value={"Póliza anulada con éxito"}
              />
            </>
          )}
          {!isLoading && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Compañía"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Nº. Póliza"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Estado"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Rama"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Titular"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"DNI"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Dominio"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Usuario"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Vigencia"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Acciones"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.table}>
                {isLoading && (
                  <div className={classes.progress}>
                    <CircularProgress size={60} />
                  </div>
                )}
                {isLoading && (
                  <div className={classes.progress}>
                    <CircularProgress size={60} />
                  </div>
                )}
                {!isLoading &&
                  !empty &&
                  polizas.map((poliza) => (
                    <TableRow
                      key={"table-polizaes-" + poliza.id}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.compania)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.numeroPoliza)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.estado)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.ramaNombre)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(
                          `${poliza.asegurado.nombre} ${poliza.asegurado.apellido}`
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.asegurado.numeroDocumento)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.dominio)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(poliza.usuarioNombre)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {`${format(
                          Date.parse(poliza.polizaVigenciaDesde),
                          "dd'/'MM'/'yyyy",
                          { locale: es }
                        )}
                          hasta ${format(
                            Date.parse(poliza.polizaVigenciaHasta),
                            "dd'/'MM'/'yyyy",
                            { locale: es }
                          )}`}
                      </TableCell>
                      <TableCell className={classes.tableCellCenter}>
                        <Tooltip title={"Ver detalle de la Póliza"}>
                          <IconButton
                            className={classes.iconButton}
                            component={Link}
                            to={`${paths[polizasMultiramaPath]}/${poliza.id}`}
                          >
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>

                        {(poliza.estado === EMITIDANOVIGENTE ||
                          poliza.estado === VIGENTE) && (
                          <Tooltip title={"Anular Póliza"}>
                            <IconButton
                              className={`${classes.iconButton}`}
                              onClick={() => handleClickAnular(poliza)}
                            >
                              <CancelIcon className={`${classes.anularIcon}`} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {(poliza.estado === ANULADA ||
                          poliza.estado === VENCIDA) && (
                          <Tooltip
                            title={`Póliza ${
                              poliza.estado === ANULADA ? "Anulada" : "Vencida"
                            }`}
                          >
                            <span>
                              <IconButton
                                className={`${classes.iconButton}`}
                                disabled
                              >
                                <CancelIcon className={`disabled`} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}

          {!isLoading && empty ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
              <Typography className={classes.sinCotizaciones}>
                {emptyListText}
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2vh",
                paddingBottom: "2vh",
                width: "100%",
              }}
            >
              {!isLoading && (
                <Pagination
                  rowsPerPage={take}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  totalRegistros={totalRegistros}
                  options={paginationOptions}
                />
              )}
            </div>
          )}
        </div>
      </Paper>
    </>
  );
};

export default ListadoPolizasMultirama;
