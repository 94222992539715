import React, { useState } from "react";
import DetalleCotizacionTable from "../../cotizaciones/detalleCotizacionTable/DetalleCotizacionTable";
import useSeguros from "./useSeguros";
import PolizaDetail from "../../polizaDetail/PolizaDetail";

const DETALLE_COTIZACION = "DETALLE_COTIZACION";
const DETALLE_POLIZA = "DETALLE_POLIZA";

const CoberturaStepper = ({
  onChange,
  compania,
  codia,
  anio,
  codigoPostal,
  localidad,
  provincia,
  uso,
  condicionFiscal,
  tieneGNC,
  formaDePago,
}) => {
  const [currentPoliza, setCurrentPoliza] = useState(null);

  const handleOpen = (value) => {
    setCurrentPoliza(value);
  };

  const handleContratar = (value) => {
    if (onChange) onChange(value.importe, value.idExterno, cotizacionId);
  };

  const { isLoading, cotizaciones, cotizacionId } = useSeguros({
    codia,
    anio,
    codigoPostal,
    localidad,
    provincia,
    uso,
    condicionFiscal,
    tieneGNC,
    formaDePago,
  });

  const detalle = cotizaciones
    ? {
        ...cotizaciones,
        companias: cotizaciones?.companias.filter(
          (comp) => comp.companiaId === compania
        ),
      }
    : null;

  const step = getStep(currentPoliza);
  return (
    <div style={{ minHeight: "90vh" }}>
      {step === DETALLE_COTIZACION && (
        <DetalleCotizacionTable
          isPending={isLoading}
          onClick={handleOpen}
          detalleCotizacion={detalle}
        />
      )}
      {step === DETALLE_POLIZA && (
        <PolizaDetail
          poliza={currentPoliza}
          onClick={(value) => handleOpen(value)}
          onClose={() => handleOpen(null)}
          onContratar={() => handleContratar(currentPoliza)}
        />
      )}
    </div>
  );
};

export default CoberturaStepper;

const getStep = (currentPoliza) => {
  if (!currentPoliza) return DETALLE_COTIZACION;
  if (currentPoliza) return DETALLE_POLIZA;
};
