import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, makeStyles } from "@mui/styles";
import SuccessIcon from "~images/ic_check_circle_24px@3x.png";
import {
  solicitudesPendientesSucursalPath,
  paths,
} from "~constants/referenceNames";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    width: "25%",
    alignSelf: "center",
    paddingBottom: "5%",
  },
  button: {
    alignSelf: "center",
  },
}));

const SuccessDialog = ({ openDialog, value }) => {
  const history = useHistory();

  const [open, setOpen] = useState(openDialog);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    //return <Redirect to={`${paths[solicitudesEmisionIndex]}`} />;
    history.push(paths[solicitudesPendientesSucursalPath]);
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div className={classes.dialogTitle}>
            <img alt="" src={SuccessIcon} className={classes.icon} />
            {value}
          </div>
        </DialogTitle>
        <DialogActions>
          <div className={classes.button}>
            <Button onClick={handleClose} color="primary" autoFocus>
              Ver mas solicitudes
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuccessDialog;
