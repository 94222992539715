import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { setDominio, setNroMotor, setNroChasis } from "../actions";
import { useDispatch, useSelector } from "react-redux";

import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDominioCard from "~business/Cards/ItemDominioCard";

const title = "Vehículo";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle = state.detalleSolicitudEmisionReducer;
    const datosVehiculo = detalle.detalleSolicitudEmision.datosVehiculo;
    return {
      marca: datosVehiculo.marca,
      modelo: datosVehiculo.modelo,
      anio: datosVehiculo.anio,
      codia: datosVehiculo.codia,
      codigoPostal: datosVehiculo.codigoPostal,
      nombreProvincia: datosVehiculo.nombreProvincia,
      nombreLocalidad: datosVehiculo.nombreLocalidad,
      uso: datosVehiculo.uso,
      tieneGNC: datosVehiculo.tieneGNC,
      dominio: detalle.dominioSelected,
      numeroMotor: detalle.nroMotorSelected,
      numeroChasis: detalle.nroChasisSelected,
    };
  });
/*
 */

const DatosVehiculoCard = ({ isLoading, editable, onBlur, displayError }) => {
  const dispatch = useDispatch();
  const {
    marca,
    modelo,
    anio,
    codia,
    codigoPostal,
    nombreProvincia,
    nombreLocalidad,
    uso,
    tieneGNC,
    dominio,
    numeroMotor,
    numeroChasis,
  } = useReduxSelector();

  let timer = null;

  const handleChangeDominio = (dominio) => {
    clearTimeout(timer);
    if (dominio.value.length <= 7) {
      timer = setTimeout(() => {
        dispatch(
          setDominio({ ...dominio, value: dominio.value.toUpperCase() })
        );
      }, 200);
    }
  };

  const handleChangeNroMotor = (nroMotor) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(
        setNroMotor({ ...nroMotor, value: nroMotor.value.toUpperCase() })
      );
    }, 200);
  };

  const handleChangeNroChasis = (nroChasis) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(
        setNroChasis({ ...nroChasis, value: nroChasis.value.toUpperCase() })
      );
    }, 200);
  };

  const localidad =
    nombreProvincia && nombreLocalidad
      ? `(CP ${codigoPostal}) ${nombreLocalidad}, ${nombreProvincia}`
      : `(CP ${codigoPostal})`;
  const tieneGNCFormatted =
    tieneGNC === null ? "No informado" : tieneGNC ? "SI" : "NO";

  const vehiculo = marca && modelo ? `(${anio}) ${marca} ${modelo}` : "";
  return (
    <DetalleCard title={title} IconHeader={DriveEtaIcon}>
      <ItemInputCard
        isLoading={isLoading}
        label={"Vehículo"}
        value={vehiculo.toUpperCase()}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"CODIA"}
        value={codia}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Localidad"}
        value={localidad}
        readOnly
      />
      <ItemDominioCard
        id={"dominio"}
        isLoading={isLoading}
        label={"Dominio"}
        value={dominio.value}
        editable={editable}
        onChange={handleChangeDominio}
        onBlur={onBlur}
        displayError={displayError}
        focus={dominio.focus}
      />
      <ItemInputCard
        id={"nroMotor"}
        isLoading={isLoading}
        label={"Número de Motor"}
        value={numeroMotor.value}
        focus={numeroMotor.focus}
        onChange={handleChangeNroMotor}
        onBlur={onBlur}
        displayError={displayError}
      />
      <ItemInputCard
        id={"nroChasis"}
        isLoading={isLoading}
        label={"Número de Chasis"}
        value={numeroChasis.value}
        caption="Número de Chasis"
        onChange={handleChangeNroChasis}
        onBlur={onBlur}
        displayError={displayError}
        focus={numeroChasis.focus}
      />
      <ItemInputCard isLoading={isLoading} label={"Uso"} value={uso} readOnly />
      <ItemInputCard
        isLoading={isLoading}
        label={"Tiene GNC"}
        value={tieneGNCFormatted}
        readOnly
      />
    </DetalleCard>
  );
};

export default DatosVehiculoCard;
