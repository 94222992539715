import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Selector from "~styled/selector/Selector";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@mui/styles";
import ItemLoadingCard from "./ItemLoadingCard";

import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  selector: {
    width: "100%",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
}));

const errorText = "Requerido";

const ItemSelectorCard = ({
  id,
  label,
  value,
  onChange,
  options,
  valueOptions,
  variant,
  isLoading,
  displayError,
  readOnly,
}) => {
  const classes = useStyles();
  const [localValue, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChangeValue = (value) => {
    setValue(value);

    if (onChange) onChange({ value: value, valid: true, focus: false });
  };

  if (isLoading || !options) return <ItemLoadingCard label={label} />;

  const optionsInverse = getOptionsInverse(valueOptions, options);

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {!readOnly && (
          <div className={classes.selector}>
            <Selector
              id={id}
              value={optionsInverse[localValue]}
              options={options}
              onChange={handleChangeValue}
              error={displayError}
              variant={variant}
            />
          </div>
        )}
        {readOnly && value && (
          <Typography className={classes.value}>
            {optionsInverse[value]}
          </Typography>
        )}
        {readOnly && !value && <div className={classes.value}>&mdash;</div>}
        {displayError && (
          <Tooltip title={errorText}>
            <div
              className={classes.buttonLink}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#f77221",
                paddingLeft: 4,
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon style={{ width: 30, height: 30 }} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ItemSelectorCard;

const getOptionsInverse = (valueOptions, options) => {
  const optionsInverse = {};
  if (options)
    options.forEach((option) => (optionsInverse[option.value] = option.label));

  return valueOptions ? valueOptions : optionsInverse;
};
