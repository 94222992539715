import React, { useEffect } from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";

import ReceiptIcon from "@mui/icons-material/Receipt";
import { useSelector, useDispatch } from "react-redux";
import { setCuota } from "../actions";
import ItemCuotaCard from "~business/Cards/ItemCuotaCard";

const moment = require("moment");

const title = "Cuotas";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle = state.renovacionPolizaReducer;
    return {
      cuotasSelected: detalle.cuotasSelected,
      total: detalle.premioTotalSelected.value,
      cantidadCuotasSelected: detalle.cantidadCuotasSelected.value,
    };
  });

const CuotasCard = ({ isLoading, displayError, onBlur }) => {
  const dispatch = useDispatch();

  const { total, cantidadCuotasSelected, cuotasSelected, vigenciaDesde } =
    useReduxSelector();

  useEffect(() => {
    let newImporteCuota = total / cantidadCuotasSelected;
    let newImportePrimerCuota = newImporteCuota;

    let decimals = newImporteCuota % 1;

    if (decimals !== 0) {
      newImportePrimerCuota = newImportePrimerCuota + decimals * 5;
      newImporteCuota = newImporteCuota - decimals;
    }

    for (let i = 1; i <= cantidadCuotasSelected; i++) {
      let fechaVtoCuota = moment(vigenciaDesde).add(i, "months");

      let importeCuota = i === 1 ? newImportePrimerCuota : newImporteCuota;

      const newCuota = {
        id: i,
        importe: {
          value: importeCuota,
          valid: true,
          focus: false,
        },
        fechaVencimiento: { value: fechaVtoCuota, valid: true, focus: false },
      };

      dispatch(setCuota(newCuota));
    }

    //eslint-disable-next-line
  }, [total]);

  const handleChangeCuota = (cuota) => {
    dispatch(setCuota(cuota));
    updateOthersCuotas(cuota);
  };

  const updateOthersCuotas = (cuota) => {
    const sumCuotasLoaded = Object.keys(cuotasSelected)
      .slice(0, cuota.id)
      .map((key) => parseFloat(cuotasSelected[key].importe.value))
      .reduce((accumulator, value) => accumulator + value);

    const newImporte =
      (total - sumCuotasLoaded) / (cantidadCuotasSelected - cuota.id);

    Object.keys(cuotasSelected).map((key) => {
      const currentCuota = cuotasSelected[key];

      if (currentCuota.id > cuota.id) {
        const newCuota = {
          ...currentCuota,
          importe: { ...currentCuota.importe, value: newImporte },
        };

        dispatch(setCuota(newCuota));
        return newCuota;
      }

      return currentCuota;
    });

    Object.keys(cuotasSelected).map((key) => {
      const currentCuota = cuotasSelected[key];

      if (cuota.id === 1 && currentCuota.id !== cuota.id) {
        let newFechaVto = moment(cuota.fechaVencimiento.value).add(
          currentCuota.id - cuota.id,
          "months"
        );
        const newCuota = {
          ...currentCuota,
          fechaVencimiento: {
            ...currentCuota.fechaVencimiento,
            value: newFechaVto,
          },
        };

        dispatch(setCuota(newCuota));
        return newCuota;
      }

      return cuota;
    });
  };

  return (
    <DetalleCard isLoading={isLoading} title={title} IconHeader={ReceiptIcon}>
      {Object.keys(cuotasSelected).map((key) => (
        <ItemCuotaCard
          key={key}
          title={`Cuota ${key}`}
          index={key}
          cuota={cuotasSelected[key]}
          onChange={handleChangeCuota}
          displayError={displayError}
          onBlur={onBlur}
          focus={
            cuotasSelected[key].importe.focus ||
            cuotasSelected[key].fechaVencimiento.focus
          }
        />
      ))}
    </DetalleCard>
  );
};

export default CuotasCard;
