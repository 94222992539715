import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { useSelector } from "react-redux";

import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDominioCard from "~business/Cards/ItemDominioCard";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";
import { getNombreProvincia } from "~styled/vehiculoProvinciaSelector/getProvincias";
import { tieneGNCFilter, tieneGNCOptions } from "~constants/endosos";

const title = "Vehículo";

const useReduxSelector = () =>
  useSelector((state) => {
    const datosVehiculo = state.detallePolizaNuevoReducer.currentPoliza
      ? state.detallePolizaNuevoReducer.currentPoliza.datosVehiculo
      : {};

    return {
      ...datosVehiculo,
    };
  });
/*
 */

const DatosVehiculoCard = ({ isLoading }) => {
  const {
    marca,
    modelo,
    anio,
    codia,
    codigoPostal,
    provincia,
    localidad,
    tieneGNC,
    dominio,
    numeroMotor,
    numeroChasis,
  } = useReduxSelector();

  const localidadFormatted =
    provincia && localidad
      ? `(CP ${codigoPostal}) ${getNombreProvincia(provincia)}, ${localidad}`
      : `(CP ${codigoPostal})`;

  const vehiculo = marca && modelo ? `(${anio}) ${marca} ${modelo}` : "";
  return (
    <DetalleCard title={title} IconHeader={DriveEtaIcon}>
      <ItemInputCard
        isLoading={isLoading}
        label={"Vehículo"}
        value={vehiculo.toUpperCase()}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"CODIA"}
        value={codia}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Localidad"}
        value={localidadFormatted}
        readOnly
      />
      <ItemDominioCard
        isLoading={isLoading}
        label={"Dominio"}
        value={dominio}
        readOnly
      />
      <ItemInputCard
        label={"Número de Motor"}
        isLoading={isLoading}
        value={numeroMotor}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Número de Chasis"}
        value={numeroChasis}
        readOnly
      />
      <ItemSelectorCard
        isLoading={isLoading}
        label={"Tiene GNC"}
        value={tieneGNC ? tieneGNC : null}
        options={tieneGNCFilter}
        valueOptions={tieneGNCOptions}
        type="selector"
        variant="big"
        readOnly
      />
    </DetalleCard>
  );
};

export default DatosVehiculoCard;
