import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setPage,
  setTake,
  selectSearchCriteria,
  selectRama,
  selectCompania,
  selectEstado,
} from "./actions";
import Searcher from "~styled/searcher/searcher";
import { Typography, Button, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "~styled/pagination/Pagination";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { paginationOptions } from "~constants/pagination";
import Selector from "~styled/selector/Selector";
import { clearData } from "./actions";
import { getRamas } from "../listadoPolizasMultirama/actions";
import { paths, altaPolizaMultiramaPath } from "~constants/referenceNames";
import { companiasSegurosOptions, companiasFilter } from "./companias";
import { useFeaturesEnabled } from "~hooks/useFeatureEnabled";
import {
  estadosPolizasOptions,
  estadosPolizasFilter,
} from "~constants/polizaEstados";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: 20,
    width: "100%",
    minWidth: "10px",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: "20px",
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingLeft: 5,
    paddingBottom: 5,
  },
  horizontalSpace: { paddingRight: "10px" },
  horizontalSpaceSearcher: { paddingRight: "10px", width: "100%" },
  searcherAndTitle: {
    display: "flex",
    flexDirection: "column",
    width: "220px",
  },
  dateSelectorAndTitle: {
    display: "flex",
    flexDirection: "column",
    width: "220px",
  },
  btnPoliza: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "200px",
    paddingLeft: "15px",
    marginTop: "12px",
  },
  ramaSelector: {
    width: "300px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.listadoPolizaMultiramaReducer.isLoading,
    ramasOptions: state.listadoPolizaMultiramaReducer.ramasOptions,
    ramasFilter: state.listadoPolizaMultiramaReducer.ramasFilter,
    totalRegistros: state.listadoPolizaMultiramaReducer.totalRegistros,

    take: state.listadoPolizaMultiramaFiltroReducer.take,
    page: state.listadoPolizaMultiramaFiltroReducer.page,
    searchCriteria: state.listadoPolizaMultiramaFiltroReducer.searchCriteria,
    rama: state.listadoPolizaMultiramaFiltroReducer.rama,
    compania: state.listadoPolizaMultiramaFiltroReducer.compania,
    estado: state.listadoPolizaMultiramaFiltroReducer.estado,
  }));

const messageLoading = "Cargando su consulta previa";

const Filtros = () => {
  const {
    take,
    page,
    isLoading,
    totalRegistros,
    searchCriteria,
    rama,
    compania,
    ramasOptions,
    ramasFilter,
    estado,
  } = useReduxSelector();

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getRamas());

    return () => {
      dispatch(clearData());
    };
    //eslint-disable-next-line
  }, [dispatch]);

  const { polizasMultiramaRegistrarIsEnabled } = useFeaturesEnabled();

  const handleChange = (event, action) => {
    dispatch(setPage(0));
    dispatch(action(event.target.value));
  };

  const handleChangePage = (value) => {
    dispatch(setPage(value));
  };

  return (
    <div className={classes.root}>
      <>
        <div className={classes.filtersContainer}>
          <div className={classes.filters}>
            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                BUSCAR
              </Typography>
              <div className={classes.horizontalSpaceSearcher}>
                <Searcher
                  borderSquared
                  value={searchCriteria}
                  filter={searchCriteria}
                  placeholder="N° Póliza, DNI, Titular, Dominio"
                  onChange={(event) =>
                    handleChange(event, selectSearchCriteria)
                  }
                  onClear={() =>
                    handleChange(
                      { target: { value: "" } },
                      selectSearchCriteria
                    )
                  }
                />
              </div>
            </div>

            <div
              className={`${classes.searcherAndTitle} ${classes.ramaSelector}`}
            >
              <Typography variant="h3" className={classes.label}>
                RAMA
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={ramasOptions[rama]}
                  options={ramasFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectRama)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                COMPAÑÍA
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={companiasSegurosOptions[compania]}
                  options={companiasFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectCompania)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            {polizasMultiramaRegistrarIsEnabled && (
              <div className={classes.btnPoliza}>
                <Tooltip title="Registrar Póliza">
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={paths[altaPolizaMultiramaPath]}
                  >
                    {"REGISTRAR PÓLIZA"}
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </>

      <>
        <div className={classes.filtersContainer}>
          <div className={classes.filters}>
            <div className={classes.searcherAndTitle}>
              <Typography variant="h3" className={classes.label}>
                ESTADO
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={estadosPolizasOptions[estado]}
                  options={estadosPolizasFilter}
                  onChange={(value) =>
                    handleChange({ target: { value } }, selectEstado)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>

          {!isLoading && (
            <Pagination
              rowsPerPage={take}
              onChangeRowsPerPage={(value) =>
                handleChange({ target: { value } }, setTake)
              }
              page={page}
              onChangePage={handleChangePage}
              totalRegistros={totalRegistros}
              options={paginationOptions}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default Filtros;
