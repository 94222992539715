import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import ItemLoadingCard from "./ItemLoadingCard";
import CuilInput from "~styled/input/CuiInput";
import useField from "~hooks/useField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: pxToRem(270),
    width: "50%",
  },

  dash: { display: "flex", justifyContent: "center" },
  icon: {
    color: "#6240a5",
  },
  input: {
    padding: "5%",
    width: "100%",
    color: "#424242",
    "& .MuiOutlinedInput-input": {
      padding: 0,
      textAlign: "right",
    },
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
}));

const errorText = "Requerido";

const ItemCuilICard = ({
  id,
  label,
  value,
  onChange,
  placeholder,
  displayError,
  focus,
  onBlur,
  formatted = false,
  isLoading,
  readOnly,
  required,
}) => {
  const classes = useStyles();

  const validateFunction = useCallback(handleValidate(required), [required]);

  const [localValue, setValue, , reference] = useField(
    value,
    validateFunction,
    focus,
    isLoading,
    readOnly
  );

  const handleChange = (event) => {
    setValue((prev) => ({ ...prev, value: event.target.value }));
    if (onChange)
      onChange({
        value: event.target.value,
        valid: validateFunction(event.target.value),
        focus: false,
      });
  };
  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  const showError = !localValue.valid && displayError;

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {!readOnly && (
          <TextField
            id={id}
            style={{ width: "100%" }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={localValue}
            variant="outlined"
            placeholder={placeholder}
            error={showError}
            inputRef={reference}
            InputProps={{
              className: classes.input,
              inputComponent: CuilInput,
            }}
          />
        )}
        {readOnly && (
          <Typography className={classes.value}>
            {getValueParsed(localValue.value, formatted)}
          </Typography>
        )}

        {showError && (
          <Tooltip title={errorText}>
            <div
              className={classes.buttonLink}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#f77221",
                paddingLeft: 4,
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon style={{ width: 30, height: 30 }} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ItemCuilICard;

const handleValidate = (required) => (value) => {
  const isDataValid = value && value.length > 7;

  return isDataValid;
};

const getValueParsed = (value, formatted) => {
  if (!value) return <span>&mdash;</span>;

  if (!formatted) return value;
  return (
    value.slice(0, 2) +
    "-" +
    value.slice(2, value.length - 1) +
    "-" +
    value.slice(value.length - 1)
  );
};
