import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useDispatch, useSelector } from "react-redux";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";

import {
  setLesionesFisicas,
  setComoOcurrio,
  setDaniosVehiculo,
  setSubmitting,
} from "../actions";

const useReduxSelector = () =>
  useSelector((state) => ({
    lesionesFisicas: state.cargaSiniestroPublicaReducer.lesionesFisicasSelected,
    comoOcurrio: state.cargaSiniestroPublicaReducer.comoOcurrioSelected,
    daniosVehiculo: state.cargaSiniestroPublicaReducer.daniosVehiculoSelected,
  }));

const lesionesFilter = [
  { label: "Seleccionar", value: "" },
  { label: "No", value: "Sin heridas" },
  { label: "Sí, heridas leves", value: "Heridas Leves" },
  { label: "Sí, heridas graves", value: "Heridas Graves" },
];

const title = "Detalles del Siniestro";

const DetalleSiniestroCard = ({ isLoading }) => {
  const dispatch = useDispatch();
  const { daniosVehiculo, comoOcurrio, lesionesFisicas } = useReduxSelector();

  let timer = null;

  const handleChangeLesionesFisicas = (lesionesFisicas) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setLesionesFisicas(lesionesFisicas));
    }, 200);
  };

  const handleChangeComoOcurrio = (comoOcurrio) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setComoOcurrio(comoOcurrio));
    }, 200);
  };

  const handleChangeDaniosVehiculo = (daniosVehiculo) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setDaniosVehiculo(daniosVehiculo));
    }, 200);
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  return (
    <>
      <DetalleCard title={title} IconHeader={QuestionAnswerIcon}>
        <ItemSelectorCard
          id={"lesionesFisicas"}
          isLoading={isLoading}
          label={"Lesiones Físicas"}
          value={lesionesFisicas.value}
          type="selector"
          onChange={handleChangeLesionesFisicas}
          variant="big"
          options={lesionesFilter}
        />
        <ItemInputCard
          id={"comoOcurrio"}
          isLoading={isLoading}
          label={"¿Cómo ocurrió?"}
          value={comoOcurrio.value}
          onChange={handleChangeComoOcurrio}
          onBlur={handleBlur}
          focus={comoOcurrio.focus}
          required
          multiline
        />
        <ItemInputCard
          id={"daniosVehiculo"}
          isLoading={isLoading}
          label={"Daños al Vehículo Asegurado"}
          value={daniosVehiculo.value}
          onChange={handleChangeDaniosVehiculo}
          onBlur={handleBlur}
          focus={daniosVehiculo.focus}
          required
          multiline
        />
      </DetalleCard>
    </>
  );
};

export default DetalleSiniestroCard;
