import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { getLogsUsuario } from "./actions";
import { useParams } from "react-router";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Paper } from "@mui/material/";
import TableContainer from "@mui/material/TableContainer";
import TableLoading from "~styled/tableLoading/TableLoading";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "5%",
  },
  column: {
    color: "hsl(263, 55%, 53%)",
    fontWeight: "bold",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    logsUsuario: state.detalleSolicitudEmisionReducer.logsUsuario,
    isLoading: state.detalleSolicitudEmisionReducer.isLoadingLog,
  }));

const Historia = () => {
  const { logsUsuario, isLoading } = useReduxSelector();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getLogsUsuario(id));
  }, [dispatch, id]);

  return (
    <div className={classes.root}>
      {isLoading && <TableLoading length={5} />}
      {!isLoading && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.column}>Fecha</TableCell>
                  <TableCell className={classes.column}>Evento</TableCell>
                  <TableCell className={classes.column}>Origen</TableCell>
                  <TableCell className={classes.column}>Usuario</TableCell>
                  <TableCell className={classes.column}>Oficina</TableCell>
                </TableRow>
              </TableHead>
              {logsUsuario.items.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>Sin Logs de Usuario</TableCell>
                  </TableRow>
                </TableBody>
              )}
              {logsUsuario &&
                logsUsuario.items.map((log, index) => (
                  <TableBody key={`log-key-${index}`}>
                    <TableRow hover style={{ cursor: "default" }}>
                      <TableCell>
                        {log.fecha &&
                          format(
                            Date.parse(log.fecha),
                            "dd'/'MM'/'yyyy HH:mm 'HS'",
                            {
                              locale: es,
                            }
                          )}
                      </TableCell>
                      <TableCell>{log.evento}</TableCell>
                      <TableCell>{log.origen}</TableCell>
                      <TableCell>
                        {`${log.nombreUsuario} - ${log.emailUsuario}`}
                      </TableCell>
                      <TableCell>{log.oficina}</TableCell>
                    </TableRow>
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default Historia;
