import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import VehiculoModeloSelector from "~business/vehiculoModeloSelector/vehiculoModeloSelector";
import { makeStyles } from "@mui/styles";
import { setVehiculoModelo, clearVehiculoModelo } from "../actions";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: "16px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    modeloSelected: state.multicotizadorReducer.modeloSelected,
    marcaSelected: state.multicotizadorReducer.marcaSelected,
    anioSelected: state.multicotizadorReducer.anioSelected,
  }));

const ModeloStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { modeloSelected, marcaSelected, anioSelected } = useReduxSelector();

  const handleChange = (event) => {
    dispatch(setVehiculoModelo(event.target.value));
  };

  useEffect(() => {
    dispatch(clearVehiculoModelo());
  }, [dispatch]);

  return (
    <>
      <MulticotizadorContent>
        <div className={classes.title}>
          <PrimaryTitle
            label="Ingresá el Modelo del vehículo del asegurado"
            marginBottom={32}
          />
        </div>
        <VehiculoModeloSelector
          value={modeloSelected}
          marca={marcaSelected}
          anio={anioSelected}
          onChange={handleChange}
        />
      </MulticotizadorContent>
    </>
  );
});

export default ModeloStep;
