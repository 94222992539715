import React, { useState, memo } from "react";
import { makeStyles } from "@mui/styles";
import filterItems from "~libs/filterItems";
import SearcherList from "~styled/searcherList/SearcherList";
import ListView from "~styled/listView/ListView";
import getMarcasTarjetaCredito from "./getMarcasTarjetaCredito";

const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around",
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
  },
}));

const marcasTarjetaCreditoList = getMarcasTarjetaCredito();

const MarcaTarjetaCreditoSelector = memo(({ value, onChange }) => {
  const [filter, setFilter] = useState("");

  const handleItem = (value) => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.searcher}>
        <SearcherList
          onClear={() => handleItem({ value: null, label: null })}
          filter={filter}
          onChange={handleFilterChange}
          placeholder={"Buscar marca"}
        />
        <ListView
          onItemSelected={handleItem}
          list={
            filter === ""
              ? marcasTarjetaCreditoList
              : filterItems(marcasTarjetaCreditoList, filter)
          }
        />
      </div>
    </>
  );
});

export default MarcaTarjetaCreditoSelector;
