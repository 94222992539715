import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import serverDownImage from "~images/serverDown.svg";

import Layout from "~shared/layout/Layout";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //background: "linear-gradient(#ffffff,#f0f2ff)",
  },
  imageContainer: {
    marginBottom: 20,
    maxWidth: 500,
    height: "40vh",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  emptyImage: {
    height: "40vh",
  },
  title: {
    marginTop: 40,
    fontWeight: 800,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.74",
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.colours.violeta,
    fontSize: "2.5rem",
  },
  subtitle: {
    display: "flex",
    margin: "auto",
    fontWeight: 600,
    maxWidth: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.4",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#625e5e",
    fontSize: "1.3rem",
    paddingBottom: 20,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonBack: {
    width: "250px",
    marginTop: 20,
    background: theme.colours.white,
    color: "#455597",
    borderRadius: 50,
    border: 0,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  button: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
}));

export const CustomMessageErrorWithoutLayout = ({ errorMessage }) => {
  const classes = useStyles();

  //   const handleRefresh = () => {
  //     window.location.reload();
  //   };

  return (
    <div className={classes.root}>
      <Typography color="textPrimary" className={classes.title}>
        {"¡Lo sentimos!"}
      </Typography>
      <Typography color="textPrimary" className={classes.subtitle}>
        {errorMessage}
      </Typography>
      <div className={classes.imageContainer}>
        <img className={classes.emptyImage} src={serverDownImage} alt="" />
      </div>
      {/* <div className={classes.buttonContainer}>
        <Button
          className={classes.buttonBack}
          onClick={handleRefresh}
          variant="contained"
        >
          INTENTAR DE NUEVO
        </Button>
      </div> */}
    </div>
  );
};

const CustomMessageError = ({ errorMessage }) => {
  return (
    <Layout>
      <CustomMessageErrorWithoutLayout errorMessage={errorMessage} />
    </Layout>
  );
};

export default CustomMessageError;
