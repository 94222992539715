import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

import CompaniaGroup from "./CompaniaGroup";
import icon_rc from "~images/icon_rc.svg";
import icon_tc from "~images/icon_tcbase.svg";
import icon_tp from "~images/icon_tcpremium.svg";
import icon_tr from "~images/icon_tr.svg";

import CotizacionDetail from "./CotizacionDetail";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  mainTitle: {
    width: "187px",
    height: "24px",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
    whiteSpace: "nowrap",
  },
  headerContainer: {
    display: "flex",
    position: "sticky",
    top: "0px",
    zIndex: 1,
    alignItems: "flex-end",
    width: "100%",
    justifyContent: "center",
    background: theme.colours.white,
  },
  iconTextContainer: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerColumn: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderBottom: `5px solid ${theme.colours.violeta}`,
  },
  headerColumnMiddle: {
    flex: 1,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    borderBottom: `5px solid ${theme.colours.lightIndigo}`,
  },
  divider: {
    width: 1,
    height: 90,
  },
  headerTitle: {
    textAlign: "center",
    fontWeight: 800,
    paddingTop: 5,
    color: theme.colours.violeta,
    lineHeight: 1.1,
    display: "block",
    flex: 1,
    whiteSpace: "pre-wrap",
  },
}));

const DetalleCotizacionTable = ({ isPending, onClick, detalleCotizacion }) => {
  const classes = useStyles();

  const isLoading = !detalleCotizacion;

  const [isOpened, setIsOpened] = useState(false);
  const [currentCotizacion, setCurrentCotizacion] = useState(null);
  const [currentCompania, setCurrentCompania] = useState("");

  const handleClick = (poliza, compania) => {
    if (poliza)
      return () => {
        if (onClick) return onClick({ ...poliza, ...compania });
        setCurrentCotizacion(poliza);
        setCurrentCompania(compania);
        setIsOpened(true);
      };
  };

  const handleClose = () => {
    setCurrentCotizacion(null);
    setIsOpened(false);
  };

  if (isLoading) return <LoadingViewGears />;

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingBottom: 60,
      }}
    >
      <div className={classes.root}>
        {currentCotizacion && (
          <CotizacionDetail
            isOpened={isOpened}
            detalleCotizacion={detalleCotizacion}
            cotizacion={currentCotizacion}
            compania={currentCompania}
            onClose={handleClose}
          />
        )}
        <Paper className={classes.headerContainer} elevation={1} square>
          <HeaderColumnTable label={"Responsabilidad\nCivil"} icon={icon_rc} />
          <HeaderColumnTable
            label={"Terceros\nCompleto"}
            icon={icon_tc}
            inMiddle
          />
          <HeaderColumnTable
            label={"Terceros\nPremium"}
            icon={icon_tp}
            inMiddle
          />
          <HeaderColumnTable label={"Todo\nRiesgo"} icon={icon_tr} />
        </Paper>
        {detalleCotizacion.companias.map((data) => {
          return (
            <CompaniaGroup
              key={data.companiaId}
              compania={data}
              onClick={handleClick}
              isPending={isPending}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DetalleCotizacionTable;

const HeaderColumnTable = ({ label, icon, inMiddle }) => {
  const classes = useStyles();
  if (inMiddle)
    return (
      <div className={classes.headerColumnMiddle}>
        <Divider className={classes.divider} />
        <div className={classes.iconTextContainer}>
          <img src={icon} alt="" />
          <Typography className={classes.headerTitle}>{label}</Typography>
        </div>
        <Divider className={classes.divider} />
      </div>
    );

  return (
    <div className={classes.headerColumn}>
      <img src={icon_rc} alt="" />
      <Typography className={classes.headerTitle}>{label}</Typography>
    </div>
  );
};
