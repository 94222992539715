import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import DetalleCard from "~business/Cards/DetalleCard.js";

import DescriptionIcon from "@mui/icons-material/Description";
import {
  setPolizaFileName,
  setTarjetaFileName,
  setCedulaMercosurFileName,
  setCuponeraFileName,
  setIsProcessingPoliza,
  setIsProcessingTarjeta,
  setIsProcessingCedulaMercosur,
  setIsProcessingCuponera,
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";

const useStyles = makeStyles((theme) => ({
  impresosDisabled: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
    paddingTop: "5%",
  },
}));

const title = "Documentación";

const adjuntos = {
  poliza: setPolizaFileName,
  tarjeta: setTarjetaFileName,
  cedulaMercosur: setCedulaMercosurFileName,
  cuponera: setCuponeraFileName,
};

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle = state.detalleSolicitudEmisionReducer;
    return {
      negocio: detalle.detalleSolicitudEmision.negocio,
    };
  });

const ImpresosCard = ({ isLoading, disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { negocio } = useReduxSelector();

  if (isLoading)
    return (
      <DetalleCard
        isLoading={isLoading}
        title={title}
        IconHeader={DescriptionIcon}
      />
    );

  const handleAdded = (event, adjunto) => {
    const action = adjuntos[adjunto];
    const value = `temp/${event.target.value}`;
    dispatch(
      action({
        value: value,
        valid: true,
      })
    );
  };
  const handleRemoved = (event, adjunto) => {
    const action = adjuntos[adjunto];
    dispatch(
      action({
        value: null,
        valid: false,
      })
    );
  };

  const handleProcessingPoliza = (value) => {
    dispatch(setIsProcessingPoliza(value));
  };

  const handleProcessingTarjeta = (value) => {
    dispatch(setIsProcessingTarjeta(value));
  };

  const handleProcessingCedulaMercosur = (value) => {
    dispatch(setIsProcessingCedulaMercosur(value));
  };

  const handleProcessingCuponera = (value) => {
    dispatch(setIsProcessingCuponera(value));
  };

  return (
    <DetalleCard
      isLoading={isLoading}
      title={title}
      IconHeader={DescriptionIcon}
    >
      {disabled && (
        <Typography className={classes.impresosDisabled}>
          {"Se requiere ingresar Número de Póliza para cargar Documentación"}
        </Typography>
      )}
      {!disabled && (
        <>
          <ItemFileUploaderCard
            label={"Póliza (requerido)"}
            onAdded={(event) => handleAdded(event, "poliza")}
            onRemoved={(event) => handleRemoved(event, "poliza")}
            onProcessStart={handleProcessingPoliza}
            acceptImage={false}
            acceptPdf={true}
          />
          <ItemFileUploaderCard
            label={`Tarjeta ${negocio === "Prendarios" ? "(requerido)" : ""}`}
            onAdded={(event) => handleAdded(event, "tarjeta")}
            onRemoved={(event) => handleRemoved(event, "tarjeta")}
            onProcessStart={handleProcessingTarjeta}
            acceptImage={false}
            acceptPdf={true}
          />
          <ItemFileUploaderCard
            label={"Cédula Mercosur"}
            onAdded={(event) => handleAdded(event, "cedulaMercosur")}
            onRemoved={(event) => handleRemoved(event, "cedulaMercosur")}
            onProcessStart={handleProcessingCedulaMercosur}
            acceptImage={false}
            acceptPdf={true}
          />
          {negocio && negocio === "MercadoAbierto" && (
            <ItemFileUploaderCard
              label={"Cuponera"}
              onAdded={(event) => handleAdded(event, "cuponera")}
              onRemoved={(event) => handleRemoved(event, "cuponera")}
              onProcessStart={handleProcessingCuponera}
              acceptImage={false}
              acceptPdf={true}
            />
          )}
        </>
      )}
    </DetalleCard>
  );
};

export default ImpresosCard;
