import React from "react";

import DetalleCard from "~business/Cards/DetalleCard.js";

import DescriptionIcon from "@mui/icons-material/Description";
import {
  setPolizaFileName,
  setTarjetaFileName,
  setCedulaMercosurFileName,
  setCuponeraFileName,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";
import { DATOS } from "~constants/endosos";

const title = "Documentación";

const adjuntos = {
  poliza: setPolizaFileName,
  tarjeta: setTarjetaFileName,
  cedulaMercosur: setCedulaMercosurFileName,
  cuponera: setCuponeraFileName,
};

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle = state.detallePolizaNuevoReducer;

    return {
      ...state.detallePolizaNuevoReducer,
      ubicacionCedulaMercosur: detalle.urlCedulaMercosurSelected,
      ubicacionCuponera: detalle.urlCuponeraSelected,
      ubicacionPoliza: detalle.urlPolizaSelected,
      ubicacionTarjeta: detalle.urlTarjetaSelected,

      canUploadImpresos: state.authenticatedViewReducer.canUploadImpresos,
    };
  });

const ImpresosCard = ({ isLoading, idPoliza, tipo }) => {
  const dispatch = useDispatch();

  const state = useReduxSelector();
  const {
    ubicacionCedulaMercosur,
    ubicacionCuponera,
    ubicacionTarjeta,
    ubicacionPoliza,
  } = state;

  const handleChange = (impreso, adjunto) => {
    const action = adjuntos[adjunto];
    dispatch(
      action({
        value: impreso.value ? `temp/${impreso.value}` : impreso.value,
        valid: impreso.valid,
      })
    );
  };

  return (
    <DetalleCard
      isLoading={isLoading}
      title={title}
      IconHeader={DescriptionIcon}
    >
      <ItemFileUploaderCard
        isLoading={isLoading}
        label={ubicacionPoliza?.value ? "Póliza" : "Póliza (requerido)"}
        onChange={(value) => handleChange(value, "poliza")}
        acceptImage={false}
        acceptPdf={true}
        value={ubicacionPoliza?.value}
        readOnly={tipo !== DATOS}
        required
      />
      <ItemFileUploaderCard
        isLoading={isLoading}
        label={"Tarjeta"}
        onChange={(value) => handleChange(value, "tarjeta")}
        acceptImage={false}
        acceptPdf={true}
        value={ubicacionTarjeta.value}
        readOnly={tipo !== DATOS}
      />
      <ItemFileUploaderCard
        isLoading={isLoading}
        label={"Cédula Mercosur"}
        onChange={(value) => handleChange(value, "cedulaMercosur")}
        acceptImage={false}
        acceptPdf={true}
        value={ubicacionCedulaMercosur.value}
        readOnly={tipo !== DATOS}
      />
      <ItemFileUploaderCard
        isLoading={isLoading}
        label={"Cuponera"}
        onChange={(value) => handleChange(value, "cuponera")}
        acceptImage={false}
        acceptPdf={true}
        value={ubicacionCuponera.value}
        readOnly={tipo !== DATOS}
      />
    </DetalleCard>
  );
};

export default ImpresosCard;
