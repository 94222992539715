import React from "react";
import Typography from "@mui/material/Typography";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@mui/styles";
import ItemLoadingCard from "./ItemLoadingCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  previousValue: {
    fontFamily: "Nunito",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
    textDecoration: "line-through",
    paddingRight: pxToRem(4),
  },
}));

const ItemDetalleEndosoCard = ({
  label,
  value,
  previousValue,
  isLoading,
  variant,
}) => {
  const classes = useStyles();

  if (isLoading) return <ItemLoadingCard label={label} size="small" />;

  return (
    <>
      {value && (
        <div className={classes.container}>
          <Typography className={classes.caption}>{label}</Typography>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            {previousValue && (
              <Typography className={classes.previousValue}>
                {previousValue}
              </Typography>
            )}
            {value && (
              <Typography className={classes.value}>{value}</Typography>
            )}
            {!value && <div>&mdash;</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default ItemDetalleEndosoCard;
