import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import ItemLoadingCard from "./ItemLoadingCard";
import validateDominio from "~libs/dominioValidator";
import IconEdit from "~assets/icons/ic_edit.svg";
import useField from "~hooks/useField";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
}));

const errorText = "Requerido";

const ItemDominioCard = ({
  id,
  label,
  value,
  onChange,
  displayError,
  focus,
  onBlur,
  readOnly,
  isLoading,
  required,
}) => {
  const classes = useStyles();
  const validateFunction = useCallback(handleValidate(required), [required]);

  const [localValue, setValue, handleEdit, reference] = useField(
    value,
    validateFunction,
    focus,
    isLoading,
    readOnly
  );

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  const handleChangeValue = (value) => {
    setValue((prev) => ({ ...prev, value: value.toUpperCase() }));
    if (onChange)
      onChange({
        value: value.toUpperCase(),
        valid: validateFunction(value.toUpperCase()),
        focus: false,
      });
  };

  const showError = !localValue.valid && displayError;

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {localValue.editing && (
          <TextField
            id={id}
            style={{ width: "100%" }}
            inputProps={{ className: classes.input }}
            onChange={(event) => handleChangeValue(event.target.value)}
            onBlur={handleBlur}
            value={localValue.value}
            variant="outlined"
            placeholder="AA000AA"
            error={showError}
            inputRef={reference}
          />
        )}
        {!localValue.editing && (
          <>
            {localValue.value && (
              <Typography className={classes.value}>
                {localValue.value}
              </Typography>
            )}
            {!localValue && <div>&mdash;</div>}
            {!readOnly && (
              <div
                className={classes.buttonLink}
                onClick={() => handleEdit(true)}
              >
                <img alt="" src={IconEdit} className={classes.icon} />
              </div>
            )}
          </>
        )}

        {!localValue.valid && (
          <Tooltip title={errorText}>
            <div
              className={classes.buttonLink}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#f77221",
                paddingLeft: 4,
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon style={{ width: 26, height: 26 }} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ItemDominioCard;

const handleValidate = (required) => (value) => {
  const isDataValid = value && validateDominio(value);

  return isDataValid;
};
