import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import ImpresosCard from "./cards/ImpresosCard";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      paddingTop: "4.5%",
      minWidth: "10px",
    },
    body: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    cardsAndCuotasContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      "@media (max-width:1024px)": {
        width: "100%",
        flexWrap: "wrap",
      },
    },
    cards: {
      display: "flex",
      paddingBottom: "30px",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "50%",
      "@media (max-width:1024px)": {
        width: "100%",
        flexWrap: "wrap",
      },
    },
  };
});

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.detallePolizaMultiramaReducer.isLoading,
  }));

const Documentacion = () => {
  const classes = useStyles();
  const { isLoading } = useReduxSelector();

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.cardsAndCuotasContainer}>
          <CardsColumn>
            <ImpresosCard isLoading={isLoading} />
          </CardsColumn>
        </div>
      </div>
    </div>
  );
};

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};

export default Documentacion;
