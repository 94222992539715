import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setValidadorDatosPago,
  clearValidadorDatosPago,
  setMarcaTarjetaCredito,
  clearMarcaTarjetaCredito,
} from "../actions";
import {
  isCreditCardNumberValid,
  getCreditCardType,
} from "~libs/creditCardValidator";
import TextField from "@mui/material/TextField";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import { makeStyles } from "@mui/styles";
import CreditCardInput from "~components/styled/input/CreditCardInput";
import pxToRem from "~libs/pxToRem";
import visaIcon from "~images/logos/visa-icon.png";
import mastercardIcon from "~images/logos/mastercard-icon.png";
import amexIcon from "~images/logos/amex-icon.png";
import dinersIcon from "~images/logos/diners-icon.png";
import Avatar from "@mui/material/Avatar";
import InputAdornment from "@mui/material/InputAdornment";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
  },
  logoSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const TarjetaCreditoStep = () => {
  const dispatch = useDispatch();
  const [tarjetaCredito, setCampoTarjeta] = useState("");
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearValidadorDatosPago());
    dispatch(clearMarcaTarjetaCredito());
  }, [dispatch]);

  const getCardIcon = (type) => {
    if (type === "MasterCard") return mastercardIcon;
    if (type === "Visa") return visaIcon;
    if (type === "AmericanExpress") return amexIcon;
    if (type === "Diners") return dinersIcon;
    return null;
  };

  let creditCardType = getCreditCardType(tarjetaCredito);
  let cardTypeIcon = getCardIcon(creditCardType);

  let allowContinue =
    creditCardType !== null ? isCreditCardNumberValid(tarjetaCredito) : true;

  const canContinue =
    tarjetaCredito.length >= 13 && tarjetaCredito.length <= 19 && allowContinue;

  const handleNext = (value) => {
    dispatch(setValidadorDatosPago({ value, selected: true }));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) {
      if (creditCardType !== null)
        dispatch(
          setMarcaTarjetaCredito({
            value: creditCardType,
            selected: true,
          })
        );
      handleNext(tarjetaCredito);
    }
  };

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={
          "Ingresá el número de tarjeta de crédito con la que realizas el pago"
        }
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
        type="tel"
        value={tarjetaCredito}
        onChange={(event) => {
          const value = event.target.value.replace(/\s/g, "");

          setCampoTarjeta(value);
        }}
        placeholder="Ej: 1111 2222 3333 4444"
        InputProps={{
          inputComponent: CreditCardInput,
          startAdornment: (
            <InputAdornment position="start">
              {creditCardType !== null && (
                <Avatar
                  alt={creditCardType}
                  src={cardTypeIcon}
                  className={classes.logoSize}
                />
              )}
            </InputAdornment>
          ),
        }}
      ></TextField>
      <div style={{ paddingTop: 24 }}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            if (creditCardType !== null)
              dispatch(
                setMarcaTarjetaCredito({
                  value: creditCardType,
                  selected: true,
                })
              );
            handleNext(tarjetaCredito);
          }}
        />
      </div>
    </MulticotizadorContent>
  );
};

export default TarjetaCreditoStep;
