import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import ListView from "~components/styled/listView/ListView";
import filterItems from "~libs/filterItems";
import { fetchLocalidades } from "./actions";
import localRepository from "../../../localRepository";
import SearcherList from "~styled/searcherList/SearcherList";
const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => {
    const localidadSelectorReducer = state.localidadSelectorReducer;

    return {
      localidades: localidadSelectorReducer.localidades,
      isLoading: localidadSelectorReducer.isLoading,
    };
  });

const LocalidadSelector = ({ label, onSelected, provinciaId }) => {
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const { localidades, isLoading } = useReduxSelector();

  const mostUsedLoc = localRepository.mostUsedLoc
    .get()
    .filter((loc) => loc.provId === provinciaId)
    .sort((x, y) => y.count - x.count)
    .slice(0, 3);

  useEffect(() => {
    if (provinciaId) dispatch(fetchLocalidades(provinciaId));
  }, [dispatch, provinciaId]);

  const handleItemSelected = (item) => {
    if (onSelected) onSelected({ target: { value: item.value } });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <div className={classes.searcher}>
      <SearcherList
        onClear={() => setFilter("")}
        filter={filter}
        onChange={handleFilterChange}
        placeholder={label}
      />
      <ListView
        isLoading={isLoading}
        onItemSelected={handleItemSelected}
        list={filter === "" ? localidades : filterItems(localidades, filter)}
        mostUsedList={filter === "" ? mostUsedLoc : []}
      />
    </div>
  );
};

export default LocalidadSelector;
