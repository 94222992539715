import React, { Fragment } from "react";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Paper,
  Typography,
  Divider,
  Switch,
} from "@mui/material";
import TableLoading from "~styled/tableLoading/TableLoading";
import Filtros from "../Filtros/Filtros";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import Chip from "@mui/material/Chip";
import { setPage, setTake } from "../Filtros/actions";
import { changeStateUsuario } from "../actions";
import { permissionHandleUserActivo } from "~constants/usuarios";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  tableContainer: {
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    fontSize: 16,
    color: "#405A84",
  },
  sinUsuarios: {
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
  },
  tableCell: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  dash: { display: "flex", justifyContent: "center" },
}));

const emptyListText = "No hay resultados.";

const useReduxSelector = () =>
  useSelector((state) => ({
    page: state.filtrosUsuariosReducer.page,
    take: state.filtrosUsuariosReducer.take,

    usuarios: state.usuariosReducer.usuarios,
    totalRegistros: state.usuariosReducer.totalRegistros,
    isLoading: state.usuariosReducer.isLoading,
    permissions: state.authenticatedViewReducer.tokenPermissions,
    getOnlyActivos: state.filtrosUsuariosReducer.getOnlyActivos,
  }));

const ListadoUsuarios = ({ onEdit }) => {
  const {
    page,
    take,
    usuarios,
    totalRegistros,
    isLoading,
    permissions,
    getOnlyActivos,
  } = useReduxSelector();
  const dispatch = useDispatch();
  const empty = totalRegistros === 0;

  const classes = useStyles();

  const handleChangePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch(setTake(value));
  };

  const handleChangeActivo = (event, usuario) => {
    dispatch(
      changeStateUsuario(
        usuario.id,
        usuario.roles,
        usuario.oficinaId,
        event.target.checked
      )
    );
  };

  const canHandleActivo = permissions.includes(permissionHandleUserActivo);

  return (
    <Fragment>
      <Paper className={classes.root}>
        <div className={classes.tableContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Filtros />
          </div>
          <Divider></Divider>
          {isLoading && <TableLoading length={5} />}
          {!isLoading && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Nombre completo"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"E-Mail"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Oficina"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Tipo"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Roles"}
                    </Typography>
                  </TableCell>
                  {canHandleActivo && !getOnlyActivos && (
                    <TableCell className={classes.tableCell}>
                      <Typography
                        color="textSecondary"
                        className={classes.tableCellHeader}
                      >
                        {"Activo"}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className={classes.table}>
                {isLoading && (
                  <div className={classes.progress}>
                    <CircularProgress size={60} />
                  </div>
                )}
                {!isLoading &&
                  !empty &&
                  usuarios.map((usuario) => (
                    <TableRow
                      key={"table-usuarios-" + usuario.id}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell className={classes.tableCell}>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={onEdit(usuario)}
                          style={{ textDecoration: "underline" }}
                        >
                          {usuario.nombre}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {usuario.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {usuario.oficina}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {usuario.tipoUsuario}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div style={{ paddingRight: 5 }}>
                          {usuario.roles &&
                            usuario.roles.map((item) => (
                              <Chip
                                key={`chip-${item}`}
                                style={{
                                  marginRight: 5,
                                  borderRadius: 5,
                                }}
                                variant="outlined"
                                label={item}
                              ></Chip>
                            ))}
                        </div>
                      </TableCell>
                      {canHandleActivo && !getOnlyActivos && (
                        <TableCell className={classes.tableCell}>
                          <Switch
                            checked={usuario.activo}
                            onChange={(event) =>
                              handleChangeActivo(event, usuario)
                            }
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {!isLoading && empty ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
              <Typography className={classes.sinUsuarios}>
                {emptyListText}
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2vh",
                paddingBottom: "2vh",
                width: "100%",
              }}
            >
              {!isLoading && (
                <Pagination
                  rowsPerPage={take}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  totalRegistros={totalRegistros}
                  options={paginationOptions}
                />
              )}
            </div>
          )}
        </div>
      </Paper>
    </Fragment>
  );
};

export default ListadoUsuarios;
