import React from "react";
import pxToRem from "~libs/pxToRem";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  base: (props) => ({
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.38",
    letterSpacing: pxToRem(0.48),
    padding: pxToRem(13),
    minHeight: "6px",
    textTransform: "none",
    marginBottom: props.marginBottom,
  }),
  primary: {
    backgroundColor: theme.palette.secondary.dark,
    color: "#ffffff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark + "80",
    },
    "&:disabled": {
      backgroundColor: "#eeeeee",
      color: "#9e9e9e",
    },
  },
  secondary: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark + "50",
      color: "#ffffff",
    },
    "&:disabled": {
      backgroundColor: "#eeeeee",
      color: "#9e9e9e",
    },
  },
  secondaryemphasis: {
    backgroundColor: "#663ea8",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#7c4cd5",
      color: "#ffffff",
    },
    "&:disabled": {
      backgroundColor: "#eeeeee",
      color: "#9e9e9e",
    },
  },
  outlinedprimary: {
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.dark,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark + "80",
      borderColor: theme.palette.secondary.dark + "80",
      color: "#ffffff",
    },
    "&:disabled": {
      color: "#bdbdbd",
      borderColor: "#bdbdbd",
    },
  },
  outlinedsecondary: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark + "50",
      borderColor: theme.palette.primary.dark + "50",
      color: "#ffffff",
    },
    "&:disabled": {
      color: "#bdbdbd",
      borderColor: "#bdbdbd",
    },
  },
}));
const CallToActionButton = ({
  label,
  onClick,
  variant = "",
  color = "primary",
  disabled,
  marginBottom,
  size,
}) => {
  const classes = useStyles({ marginBottom });
  const fullClasses = [classes.base, classes[`${variant}${color}`]].join(" ");

  return (
    <Button
      fullWidth
      variant={variant === "outlined" ? variant : "text"}
      style={
        size === "small" ? { height: "1.5rem", fontSize: pxToRem(14) } : {}
      }
      disabled={disabled}
      className={fullClasses}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default CallToActionButton;
