import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/lab/Autocomplete";
import Chip from "@mui/material/Chip";
import { putUsuarios } from "../actions";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getOficinas, getRoles } from "./actions";
import ContentLoader from "react-content-loader";
import { queueMessage } from "~shared/snackbarsProvider/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  usuario: {
    textAlign: "center",
    fontWeight: 900,
    color: theme.colours.violeta,
    paddingTop: 15,
    paddingBottom: 15,
    display: "block",
    flex: 1,
  },
  content: {
    paddingBottom: 60,
  },
  title: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
    paddingTop: "2vh",
    paddingBottom: "2vh",
  },
  sentenceText: {
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "5%",
    alignItems: "center",
    maxWidth: 650,
    width: "100%",
  },
  buttonBack: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.white,
    color: "#455597",
    borderRadius: 50,
    border: 0,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  button: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  dash: { display: "flex", justifyContent: "center" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    oficinas: state.usuarioEditorReducer.oficinas,
    rolesDb: state.usuarioEditorReducer.roles,
  }));

const UsuarioEditor = ({ onClose, usuario }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { rolesDb } = useReduxSelector();

  const [roles, setRoles] = useState(null);
  const [oficinaSelected, setOficinaSelected] = useState(null);

  const isLoading = !usuario || !roles;

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(() => {
    if (usuario) {
      setRoles(usuario.roles);
      setOficinaSelected(usuario.oficinaId);
    }
  }, [usuario]);

  useEffect(() => {
    dispatch(getOficinas());
  }, [dispatch]);

  const handleChangeRoles = (roles) => {
    if (roles.length > 0) setRoles(roles);
    else dispatch(queueMessage("Debe seleccionar al menos un Rol"));
  };

  const differenceRoles = (initial) => {
    const result = rolesDb.filter((rol) => {
      const isSelected = initial && initial.find((x) => x === rol);
      return !isSelected;
    });
    return result;
  };

  const handleClickAceptar = () => {
    usuario.roles = roles;
    usuario.oficina = oficinaSelected;
    onClose();

    dispatch(
      putUsuarios(
        usuario.id,
        roles.map((rol) => rol),
        usuario.oficina,
        usuario.activo
      )
    );
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={true}
        scroll="paper"
        onClose={onClose}
        fullScreen={fullScreen}
      >
        <DialogTitle
          style={{
            padding: 0,
          }}
          disableTypography
        >
          <div style={{ display: "flex" }}>
            <div style={{ width: 48 }} />
            <Typography variant={"h5"} className={classes.usuario}>
              USUARIO
            </Typography>

            <div style={{ width: 48 }}>
              <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="Close"
                className={classes.closeButton}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Divider />

          <Typography variant={"subtitle2"} className={classes.title}>
            INFORMACIÓN
          </Typography>
          {isLoading && <SectionLoading />}
          {!isLoading && (
            <Typography variant={"subtitle2"} className={classes.sentenceText}>
              {`${usuario.nombre} - ${usuario.email}`}
            </Typography>
          )}

          <Divider />
          <Typography variant={"subtitle2"} className={classes.title}>
            {"Oficina"}
          </Typography>
          <div style={{ width: "100%" }}>
            {isLoading && <SectionLoading />}
            {!isLoading && (
              <OficinaSelector
                oficinaSelected={oficinaSelected}
                setOficinaSelected={setOficinaSelected}
              />
            )}
          </div>
          <Divider />
          <Typography variant={"subtitle2"} className={classes.title}>
            {"Tipo"}
          </Typography>
          {isLoading && <SectionLoading />}
          {!isLoading && (
            <Typography variant={"subtitle2"} className={classes.sentenceText}>
              {usuario.tipoUsuario}
            </Typography>
          )}
          <Divider />
          <Typography variant={"subtitle2"} className={classes.title}>
            {"Roles"}
          </Typography>

          {isLoading && <SectionLoading />}
          {!isLoading && (
            <Autocomplete
              style={{ padding: 0, margin: 0, minWidth: 200 }}
              multiple
              disableClearable
              id="tags-standard"
              noOptionsText="sin opciones"
              options={differenceRoles(roles)}
              value={roles}
              getOptionLabel={(option) => option}
              onChange={(_, value) => handleChangeRoles(value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Chip
                      style={{
                        marginRight: 5,
                        borderRadius: 5,
                      }}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Agregar Rol"
                  margin="normal"
                  fullWidth
                />
              )}
            />
          )}
        </DialogContent>
        <div className={classes.buttonContainer}>
          <Button
            disabled={isLoading}
            onClick={onClose}
            className={classes.buttonBack}
            variant="contained"
          >
            CANCELAR
          </Button>
          <Button
            disabled={isLoading}
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleClickAceptar}
          >
            ACEPTAR
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default UsuarioEditor;

const OficinaSelector = ({ oficinaSelected, setOficinaSelected }) => {
  const classes = useStyles();
  const { oficinas } = useReduxSelector();

  const handleChange = (event) => {
    setOficinaSelected(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="oficina-select-label">Oficina</InputLabel>
      <Select
        labelId="oficina-select-label"
        id="oficina-select"
        value={oficinaSelected}
        onChange={handleChange}
      >
        {oficinas.map((oficina) => (
          <MenuItem key={`oficina-select${oficina.id}`} value={oficina.id}>
            {oficina.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SectionLoading = () => {
  return (
    <ContentLoader
      height={31}
      width={316}
      speed={1}
      backgroundColor="#f7f8ff"
      foregroundColor="#d9e8df"
    >
      <rect x="35" y="10" rx="4" ry="4" width="250" height="13" />
    </ContentLoader>
  );
};
