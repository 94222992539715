import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { setTake, setPage } from "../filtros/actions";
import { patchSiniestroEstado, patchSiniestroTipo } from "./actions";
import TableLoading from "~styled/tableLoading/TableLoading";
import Filtros from "../filtros/Filtros";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { paths, siniestrosPath } from "~constants/referenceNames";
import PopupEstado from "./PopupEstado";
import PopupTipo from "./PopupTipo";
import SuccessDialog from "./SuccessDialog";
import ColorLabelEstadoSiniestro from "~styled/colorLabelEstadoSiniestro/ColorLabelEstadoSiniestro";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";
import { useFeaturesEnabled } from "~hooks/useFeatureEnabled";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  tableContainer: {
    width: "100%",
    backgroundColor: theme.colours.white,
    overflow: "auto",
    minHeight: 500,
  },
  table: {
    fontSize: 16,
    color: "#405A84",
    width: "auto",
    tableLayout: "auto",
  },
  tableCell: {
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
  },
  tableCellNroCia: {
    paddingLeft: 7,
  },
  tableCellHeader: {
    fontSize: "small",
    paddingLeft: 5,
    paddingRight: 5,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  sinCotizaciones: {
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "2vh",
    paddingBottom: "2vh",
    width: "100%",
  },
  iconButton: {
    color: theme.colours.grey5,
    padding: 0,
  },
  pendienteIcon: {
    color: theme.colours.violeta,
  },
  resueltoIcon: {
    color: theme.colours.verdeMgBroker,
  },
  tipoSiniestroIcon: {
    color: theme.colours.orange,
  },
  rechazadoIcon: {
    color: "red",
  },
}));

const emptyListText = "No hay resultados.";
const oficinaIdCasaCentral = "05b5907b-9cd7-4d9e-a109-8f339fa16da0";

const INGRESADO = "Ingresado";
const PENDIENTE_COMPANIA = "PendienteCompania";
const RESUELTO = "Resuelto";
const RECHAZADO = "Rechazado";

const useReduxSelector = () =>
  useSelector((state) => ({
    take: state.listadoSiniestrosFiltroReducer.take,
    page: state.listadoSiniestrosFiltroReducer.page,
    isLoading: state.listadoSiniestrosReducer.isLoading,
    totalRegistros: state.listadoSiniestrosReducer.totalRegistros,
    siniestros: state.listadoSiniestrosReducer.siniestros,
    siniestroModificado: state.listadoSiniestrosReducer.siniestroModificado,
    oficinaUsuario: state.authReducer.authState.user.seguros.oficinaId,
  }));

const ListadoSiniestros = () => {
  const dispatch = useDispatch();

  const {
    take,
    page,
    isLoading,
    totalRegistros,
    siniestros,
    siniestroModificado,
    oficinaUsuario,
  } = useReduxSelector();

  const { siniestrosCasaCentralIsEnabled } = useFeaturesEnabled();

  const classes = useStyles();

  const empty = totalRegistros === 0;

  const [currentSiniestro, setCurrentSiniestro] = useState(null);
  const [isPopupEstadoOpened, setIsPopupEstadoOpened] = useState(false);
  const [isPopupTipoOpened, setIsPopupTipoOpened] = useState(false);
  const [nuevoEstado, setNuevoEstado] = useState(null);
  const [nuevoTipo, setNuevoTipo] = useState(null);

  const handleClickEstado = (siniestro, nuevoEstado) => {
    setCurrentSiniestro(siniestro);
    setNuevoEstado(nuevoEstado);
    setIsPopupEstadoOpened(true);
  };

  const handleClickTipo = (siniestro, nuevoTipo) => {
    setCurrentSiniestro(siniestro);
    setNuevoTipo(nuevoTipo);
    setIsPopupTipoOpened(true);
  };

  const handleEstado = (
    siniestroId,
    nuevoEstado,
    motivo,
    comentario,
    numeroSiniestroCompania
  ) => {
    setIsPopupEstadoOpened(false);
    dispatch(
      patchSiniestroEstado(
        siniestroId,
        nuevoEstado,
        motivo,
        comentario,
        numeroSiniestroCompania
      )
    );
  };

  const handleTipo = (siniestroId, nuevoTipo) => {
    setIsPopupTipoOpened(false);
    dispatch(patchSiniestroTipo(siniestroId, nuevoTipo));
  };

  const handleClosePopupEstado = () => {
    setIsPopupEstadoOpened(false);
    setCurrentSiniestro(null);
  };

  const handleClosePopupTipo = () => {
    setIsPopupTipoOpened(false);
    setCurrentSiniestro(null);
  };

  const handleChangePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch(setTake(value));
  };

  const getValueParsed = (value) => {
    if (!value || value === 0)
      return <div className={classes.dash}>&mdash;</div>;

    if (Date.parse(value) && value.length === 28) {
      return format(Date.parse(value), "dd'/'MM'/'yyyy", {
        locale: es,
      });
    }

    return value;
  };

  const capitalizeWord = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.tableContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Filtros />
          </div>
          <Divider></Divider>
          {isLoading && <TableLoading length={5} />}
          {!isLoading && currentSiniestro !== null && (
            <>
              <PopupEstado
                isOpened={isPopupEstadoOpened}
                siniestroId={currentSiniestro.id}
                compania={currentSiniestro.compania}
                nroPoliza={currentSiniestro.numeroPoliza}
                nombre={currentSiniestro.nombreAsegurado}
                onClickAceptar={handleEstado}
                nuevoEstado={nuevoEstado}
                onClose={handleClosePopupEstado}
              />
              <SuccessDialog
                openDialog={siniestroModificado}
                value={"Siniestro modificado con éxito"}
              />
            </>
          )}
          {!isLoading && currentSiniestro !== null && (
            <>
              <PopupTipo
                isOpened={isPopupTipoOpened}
                siniestroId={currentSiniestro.id}
                compania={currentSiniestro.compania}
                nroPoliza={currentSiniestro.numeroPoliza}
                nombre={currentSiniestro.nombreAsegurado}
                tipo={currentSiniestro.tipo}
                onClickAceptar={handleTipo}
                nuevoTipo={nuevoTipo}
                onClose={handleClosePopupTipo}
              />
              <SuccessDialog
                openDialog={siniestroModificado}
                value={"Siniestro modificado con éxito"}
              />
            </>
          )}

          {!isLoading && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Estado"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Fecha Ingresado"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Tipo"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"N° Siniestro"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"N° Siniestro Compañía"}
                    </Typography>
                  </TableCell>
                  {oficinaUsuario === oficinaIdCasaCentral && (
                    <TableCell className={classes.tableCell}>
                      <Typography
                        color="textSecondary"
                        className={classes.tableCellHeader}
                      >
                        {"Sucursal"}
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Compañía"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"N° Póliza"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Dominio"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"DNI"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Asegurado"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div style={{ width: "150px" }}>
                      <Typography
                        color="textSecondary"
                        className={classes.tableCellHeader}
                      >
                        {"Acciones"}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.table}>
                {isLoading && (
                  <div className={classes.progress}>
                    <CircularProgress size={60} />
                  </div>
                )}
                {isLoading && (
                  <div className={classes.progress}>
                    <CircularProgress size={60} />
                  </div>
                )}
                {!isLoading &&
                  !empty &&
                  siniestros.map((siniestro) => (
                    <TableRow
                      key={"table-polizaes-" + siniestro.id}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell className={classes.tableCell}>
                        <ColorLabelEstadoSiniestro
                          estado={siniestro.estado}
                          bold
                        />
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {getValueParsed(siniestro.fechaIngresado)}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {capitalizeWord(getValueParsed(siniestro.tipo))}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {getValueParsed(siniestro.numeroSiniestroInterno)}
                      </TableCell>
                      <TableCell
                        className={`${classes.tableCell} ${classes.tableCellNroCia}`}
                      >
                        {getValueParsed(siniestro.numeroSiniestroCompania)}
                      </TableCell>

                      {oficinaUsuario === oficinaIdCasaCentral && (
                        <TableCell className={classes.tableCell}>
                          {getValueParsed(siniestro.sucursal)}
                        </TableCell>
                      )}
                      <TableCell className={classes.tableCell}>
                        {getValueParsed(siniestro.compania)}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {getValueParsed(siniestro.numeroPoliza)}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {getValueParsed(siniestro.vehiculo.dominio)}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {getValueParsed(siniestro.numeroDocumentoAsegurado)}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        {getValueParsed(siniestro.nombreAsegurado)}
                      </TableCell>

                      <TableCell className={classes.tableCell}>
                        <Tooltip title={"Ver detalle del Siniestro"}>
                          <IconButton
                            className={classes.iconButton}
                            component={Link}
                            to={`${paths[siniestrosPath]}/${siniestro.id}`}
                          >
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>

                        {siniestro.estado === INGRESADO &&
                          siniestrosCasaCentralIsEnabled && (
                            <Tooltip title={"Pasar a Pendiente Compañía"}>
                              <IconButton
                                onClick={() =>
                                  handleClickEstado(
                                    siniestro,
                                    PENDIENTE_COMPANIA
                                  )
                                }
                                className={`${classes.iconButton}`}
                              >
                                <ArrowDropDownCircleOutlinedIcon
                                  className={`${classes.pendienteIcon}`}
                                />
                              </IconButton>
                            </Tooltip>
                          )}

                        {siniestro.estado === PENDIENTE_COMPANIA &&
                          siniestrosCasaCentralIsEnabled && (
                            <>
                              <Tooltip title={"Resolver"}>
                                <IconButton
                                  onClick={() =>
                                    handleClickEstado(siniestro, RESUELTO)
                                  }
                                  className={`${classes.iconButton}`}
                                >
                                  <CheckCircleOutlinedIcon
                                    className={`${classes.resueltoIcon}`}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"Rechazar"}>
                                <IconButton
                                  onClick={() =>
                                    handleClickEstado(siniestro, RECHAZADO)
                                  }
                                  className={`${classes.iconButton}`}
                                >
                                  <CancelOutlinedIcon
                                    className={`${classes.rechazadoIcon}`}
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        {siniestrosCasaCentralIsEnabled && (
                          <Tooltip title={"Cambiar Tipo de Siniestro"}>
                            <IconButton
                              onClick={() =>
                                handleClickTipo(siniestro, PENDIENTE_COMPANIA)
                              }
                              className={`${classes.iconButton}`}
                            >
                              <EditOutlinedIcon
                                className={`${classes.tipoSiniestroIcon}`}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}

          {!isLoading && empty ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
              <Typography className={classes.sinCotizaciones}>
                {emptyListText}
              </Typography>
            </div>
          ) : (
            !isLoading && (
              <div className={classes.pagination}>
                <Pagination
                  rowsPerPage={take}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  totalRegistros={totalRegistros}
                  options={paginationOptions}
                />
              </div>
            )
          )}
        </div>
      </Paper>
    </>
  );
};

export default ListadoSiniestros;
