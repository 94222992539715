import React from "react";
import { useParams } from "react-router";
import { makeStyles } from "@mui/styles";

import { useSelector } from "react-redux";
import AseguradoCard from "./cards/AseguradoCard";
import DatosGeneralesCard from "./cards/DatosGeneralesCard";
import DatosVehiculoCard from "./cards/DatosVehiculoCard";
import ImpresosCard from "./cards/ImpresosCard";
import DatosAnulacionCard from "./cards/DatosAnulacionCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },

  cardItem: { minWidth: 300 },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.detallePolizaNuevoReducer,
  }));

const DetallePolizaTab = () => {
  let { id } = useParams();
  const { currentPoliza, isLoading } = useReduxSelector();

  const classes = useStyles();
  return (
    <div className={classes.cardsAndCuotasContainer}>
      <CardsColumn>
        <div className={classes.cardItem}>
          <DatosGeneralesCard isLoading={isLoading || !currentPoliza} />
        </div>
        <div className={classes.cardItem}>
          <DatosVehiculoCard isLoading={isLoading || !currentPoliza} />
        </div>
      </CardsColumn>

      <CardsColumn>
        {currentPoliza && currentPoliza.estado === "Anulada" && (
          <div className={classes.cardItem}>
            <DatosAnulacionCard
              isLoading={isLoading || !currentPoliza}
              data={currentPoliza ? currentPoliza.datosAnulacion : null}
            />
          </div>
        )}

        <div className={classes.cardItem}>
          <AseguradoCard isLoading={isLoading || !currentPoliza} />
        </div>
        <div className={classes.cardItem}>
          <ImpresosCard isLoading={isLoading || !currentPoliza} idPoliza={id} />
        </div>
      </CardsColumn>
    </div>
  );
};

export default DetallePolizaTab;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
