import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { useSelector, useDispatch } from "react-redux";

import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDominioCard from "~business/Cards/ItemDominioCard";
import ItemSelectorCard from "~business/Cards/ItemSelectorCard";
import ItemVehiculoCard from "~business/Cards/itemVehiculoSelector/ItemVehiculoCard";
import { DATOS } from "~constants/endosos";
import ItemLocalidadCard from "~business/Cards/itemLocalidadCard/ItemLocalidadCard";
import {
  setAnio,
  setCodia,
  setModelo,
  setLocalidad,
  setProvincia,
  setCodigoPostal,
  setDominio,
  setNumeroMotor,
  setNumeroChasis,
  setTieneGNC,
} from "../../actions";

const title = "Vehículo";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle = state.detallePolizaNuevoReducer;

    return {
      marca: detalle.marcaSelected,
      modelo: detalle.modeloSelected,
      anio: detalle.anioSelected,
      codigoPostal: detalle.codigoPostalSelected,
      provincia: detalle.provinciaSelected,
      localidad: detalle.localidadSelected,
      tieneGNC: detalle.tieneGNC,
      codia: detalle.codiaSelected,
      dominio: detalle.dominioSelected,
      numeroMotor: detalle.numeroMotorSelected,
      numeroChasis: detalle.numeroChasisSelected,
    };
  });
/*
 */

const DatosVehiculoCard = ({ isLoading, tipo, displayError, onBlur }) => {
  const dispatch = useDispatch();
  const {
    marca,
    modelo,
    anio,
    codia,
    codigoPostal,
    provincia,
    localidad,
    tieneGNC,
    dominio,
    numeroMotor,
    numeroChasis,
  } = useReduxSelector();

  const handleChangeVehiculo = (vehiculo) => {
    dispatch(setModelo(vehiculo.modelo));
    dispatch(setCodia(vehiculo.codia));
    dispatch(setAnio(vehiculo.anio));
  };

  const handleChangeLocalidad = (localidad) => {
    dispatch(setLocalidad(localidad.localidad));
    dispatch(setProvincia(localidad.provincia));
    dispatch(setCodigoPostal(localidad.codigoPostal));
  };

  let timer = null;
  const handleChangeDominio = (dominio) => {
    clearTimeout(timer);
    timer = setTimeout(() => dispatch(setDominio(dominio)), 200);
  };
  const handleChangeNumeroMotor = (numeroMotor) => {
    clearTimeout(timer);
    timer = setTimeout(() => dispatch(setNumeroMotor(numeroMotor)), 200);
  };

  const handleChangeNumeroChasis = (numeroChasis) => {
    clearTimeout(timer);
    timer = setTimeout(() => dispatch(setNumeroChasis(numeroChasis)), 200);
  };

  const handleChangeTieneGNC = (tieneGNC) => {
    dispatch(setTieneGNC(tieneGNC));
  };

  return (
    <DetalleCard title={title} IconHeader={DriveEtaIcon}>
      <ItemVehiculoCard
        isLoading={isLoading}
        label={"Vehículo"}
        marca={marca.value}
        modelo={modelo.value}
        anio={anio.value}
        onChange={handleChangeVehiculo}
        readOnly={tipo !== DATOS}
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"CODIA"}
        value={codia.value}
        readOnly
      />
      <ItemLocalidadCard
        isLoading={isLoading}
        label={"Localidad"}
        provincia={provincia.value}
        localidad={localidad.value}
        codigoPostal={codigoPostal.value}
        onChange={handleChangeLocalidad}
        readOnly={tipo !== DATOS}
      />
      <ItemDominioCard
        isLoading={isLoading}
        label={"Dominio"}
        value={dominio.value}
        onChange={handleChangeDominio}
        onBlur={onBlur}
        focus={dominio.focus}
        displayError={displayError}
        readOnly={tipo !== DATOS}
        required
      />
      <ItemInputCard
        label={"Número de Motor"}
        isLoading={isLoading}
        value={numeroMotor.value}
        onChange={handleChangeNumeroMotor}
        onBlur={onBlur}
        focus={numeroMotor.focus}
        displayError={displayError}
        readOnly={tipo !== DATOS}
        required
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Número de Chasis"}
        value={numeroChasis.value}
        onChange={handleChangeNumeroChasis}
        onBlur={onBlur}
        focus={numeroChasis.focus}
        displayError={displayError}
        readOnly={tipo !== DATOS}
        required
      />
      <ItemSelectorCard
        isLoading={isLoading}
        label={"Tiene GNC"}
        value={tieneGNC.value}
        options={tieneGNCFilter}
        valueOptions={tieneGNCOptions}
        onChange={handleChangeTieneGNC}
        type="selector"
        variant="big"
        readOnly={tipo !== DATOS}
      />
    </DetalleCard>
  );
};

export default DatosVehiculoCard;

const tieneGNCFilter = [
  { label: "SI", value: true },
  { label: "NO", value: false },
  { label: "No Informado", value: null },
];

const tieneGNCOptions = {
  [true]: "SI",
  [false]: "NO",
  [null]: "No Informado",
};
