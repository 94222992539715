import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";

import { DesktopNavBar } from "~styled/layouts/navbarLayout/NavbarLayout";
import { useGetNavbar } from "../../../views/inicio/Inicio";

import waveMobile from "~assets/shapes/wave-mobile.svg";

const NAV_BAR_HEIGHT = 56;

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    paddingLeft: theme.typography.pxToRem(36),
    marginTop: theme.typography.pxToRem(20),
  },
  backButton: {
    display: "flex",
    alignItems: "baseline",
    padding: "16px 0px 0px 0px",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.21,
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.white,
  },
  subtitle: {
    paddingLeft: theme.typography.pxToRem(4),
    fontSize: "18px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.21,
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.white,
  },
  main: {
    height: "100vh",
    width: "auto",
    overflow: "auto",

    backgroundColor: "#f9f7fc",
    flex: 1,
    paddingTop: 0,
    minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
    background:
      "linear-gradient(180deg, rgba(102,62,168,1) 0%, rgba(51,31,84,1) 100%)",
    backgroundSize: "100vw calc(31vh - 90px)",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.mobile]: {
      backgroundImage: `url(${waveMobile})`,
      backgroundSize: "100vw calc(31vh - 30px)",
    },
  },
  columnCentered: {
    paddingTop: theme.typography.pxToRem(24),
    paddingLeft: theme.typography.pxToRem(18),
    paddingRight: theme.typography.pxToRem(18),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      //width: drawerWidth,
      flexShrink: 0,
    },
    display: "flex",
    justifyContent: "center",
  },
  appBar: {
    backgroundColor: theme.colours.white,
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      zIndex: 2,
      paddingLeft: "30px",
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  drawerPaper: {
    alignItems: "flex-start",
    zIndex: 2,
    //width: drawerWidth
  },
}));

const SectionLayout = ({ title, children }) => {
  const classes = useStyles();

  const items = useGetNavbar();
  const [clicked, setClicked] = useState("INICIO");

  return (
    <>
      <div className={classes.appBar}>
        <div className={classes.main}>
          <div className={classes.titleContainer}>
            <Typography color="textPrimary" className={classes.title}>
              {title}
            </Typography>
          </div>
          <div className={classes.columnCentered}>{children}</div>
        </div>
      </div>

      <nav className={classes.drawer}>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DesktopNavBar
              items={items}
              clicked={clicked}
              setClicked={setClicked}
            />
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

export default SectionLayout;
