import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { setDireccion, clearDireccion } from "../actions";
import TextField from "@mui/material/TextField";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  buttonContinuar: {
    paddingTop: 24,
  },
}));

const DireccionStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [direccion, setCampoDireccion] = useState("");

  useEffect(() => {
    dispatch(clearDireccion());
  }, [dispatch]);

  const canContinue = direccion.length > 0 && direccion.includes(" ");

  const handleNext = (value) => {
    dispatch(setDireccion(value));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) handleNext(direccion);
  };

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={"Ingresá la dirección del asegurado"}
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
        value={direccion}
        onChange={(event) => setCampoDireccion(event.target.value)}
        placeholder="Ej: calle XX"
      ></TextField>
      <div className={classes.buttonContinuar}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            handleNext(direccion);
          }}
        />
      </div>
    </MulticotizadorContent>
  );
};

export default DireccionStep;
