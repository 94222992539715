import React, { useState, useRef, useEffect } from "react";
import Typography from "@mui/material/Typography";
import DatePicker from "~styled/datePicker/DatePicker";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import ItemLoadingCard from "./ItemLoadingCard";
import isNullOrEmpty from "~libs/isNullOrEmpty";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  textFieldValue: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
    width: "100%",
  },
  numericFieldValue: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
    width: "100%",
  },
  datePicker: {
    width: "100%",
  },
  dash: { display: "flex", justifyContent: "center" },
  icon: {
    color: "#6240a5",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  selector: {
    width: "100%",
  },
  fileUploadContainer: {
    paddingTop: "2%",
    display: "flex",
    flexDirection: "column",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
}));

const errorText = "Requerido";

const ItemDateCard = ({
  id,
  label,
  value,
  onChange,
  type,
  onValidate,
  displayError,
  focus,
  onBlur,
  isLoading,
  format,
  readOnly,
  maxDate,
  required,
}) => {
  const handleValidate = (value) => {
    const customValidationIsValid = !onValidate || onValidate(value);
    const isDataValid =
      !isNullOrEmpty(value) &&
      moment(value).isValid() &&
      customValidationIsValid;

    return isDataValid;
  };

  const classes = useStyles();
  const reference = useRef();
  const [localValue, setValue] = useState(value);
  const [valid, setValid] = useState(handleValidate(value));

  useEffect(() => {
    if (!isLoading) {
      setValid(handleValidate(value));
      setValue(value);
      if (onChange) onChange({ value: value, valid: valid, focus: false });
    }
    //eslint-disable-next-line
  }, [isLoading]);

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  const handleChangeDate = (value) => {
    const isDataValid = handleValidate(value);
    setValid(isDataValid);
    setValue(value);

    if (onChange) onChange({ value: value, valid: isDataValid, focus: false });
  };

  const showError = !valid && displayError;

  if (isLoading) return <ItemLoadingCard label={label} />;
  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {!readOnly && (
          <DatePicker
            id={id}
            onChange={handleChangeDate}
            onBlur={handleBlur}
            value={localValue}
            className={classes.datePicker}
            error={showError}
            reference={reference}
            maxDate={maxDate}
            required={required}
          />
        )}
        {readOnly && (
          <Typography className={classes.value}>
            {getValueParsed(value, format)}
          </Typography>
        )}
        {showError && (
          <Tooltip title={errorText}>
            <div
              className={classes.buttonLink}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#f77221",
                paddingLeft: 4,
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon style={{ width: 30, height: 30 }} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ItemDateCard;

const getValueParsed = (value, format = "DD/MM/YYYY") => {
  if (!value) return <div>&mdash;</div>;

  const extendedFormat = format === "DD/MM/YYYY" ? "" : " HS";
  return moment(value).format(format) + extendedFormat;
};
