import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@mui/styles";

import { clearSexo, setSexo } from "../actions";

import IllusMasc from "~assets/ilustrations/illus_sexo_masc.svg";
import IllusFem from "~assets/ilustrations/illus_sexo_fem.svg";

import { CheckboxButton } from "~styled/checkboxButton/CheckboxButton";
import Typography from "@mui/material/Typography";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const SEXO_MASCULINO = "M";
const SEXO_FEMENINO = "F";

const SexoStep = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearSexo());
  }, [dispatch]);

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={"¿Cuál es el sexo que figura en su DNI?"}
        marginBottom={60}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: 20,
        }}
      >
        <div style={{ marginRight: 5, width: "100%" }}>
          <CheckboxButton
            onClick={() =>
              dispatch(setSexo({ value: SEXO_FEMENINO, selected: true }))
            }
          >
            <div className={classes.container}>
              <img
                alt=""
                src={IllusFem}
                style={{ height: 63, marginBottom: 17 }}
              />
              <Typography className={classes.btnLabel}>Femenino</Typography>
            </div>
          </CheckboxButton>
        </div>
        <div style={{ marginLeft: 5, width: "100%" }}>
          <CheckboxButton
            onClick={() =>
              dispatch(setSexo({ value: SEXO_MASCULINO, selected: true }))
            }
          >
            <div className={classes.container}>
              <img
                alt=""
                src={IllusMasc}
                style={{ height: 58, marginBottom: 17 }}
              />
              <Typography className={classes.btnLabel}>Masculino</Typography>
            </div>
          </CheckboxButton>
        </div>
      </div>
    </MulticotizadorContent>
  );
};

export default SexoStep;

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 145,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  btnLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    lineHeight: 1.38,
    letterSpacing: 0.48,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
}));
