import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "3%",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignContent: "stretch",
    alignItems: "stretch",
  },
  item: {
    flexGrow: 1,
    flexBasis: "49%",
  },
}));

const NegocioCheckbox = ({ negocios, setNegocios }) => {
  const handleChange = (index) => (event) => {
    let newArr = [...negocios];
    newArr[index].activa = event.target.checked;
    setNegocios(newArr);
  };

  const classes = useStyles();
  return (
    <div>
      <FormGroup>
        <div className={classes.container}>
          {negocios.map((negocio, index) => {
            return (
              <div key={index} className={classes.item}>
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={negocio.activa}
                      onClick={handleChange(index)}
                      name={negocio.id}
                    />
                  }
                  label={negocio.id.replace(/([A-Z])/g, " $1").trim()}
                />
              </div>
            );
          })}
        </div>
      </FormGroup>
    </div>
  );
};
export default NegocioCheckbox;
