import {
    errorUnlessOk,
    buildRequestWithAuth,
    headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
// estadisticas path add
const estadisticas = () => {
    return {
        getEstadisticas: () => {
            const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

            let url =
                environment.segurosUrl +
                '/estadisticas/cotizaciones'

            return fetch(url, request)
                .then(errorUnlessOk)
                .then((response) => {
                    return response.json();
                })
                .catch((error) => {
                    console.error(error);
                    throw error;
                });
        },
    };
};

export { estadisticas };
