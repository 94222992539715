import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTelefono, clearTelefono } from "../actions";

import TextField from "@mui/material/TextField";
import CallToActionButton from "~styled/callToActionButton/CallToActionButton";
import PhoneTipoSelector from "~styled/phone/PhoneTipoSelector";
import PhoneInput from "~styled/phone/PhoneInput";
import usePhone from "~styled/phone/usePhone";
import pxToRem from "~libs/pxToRem";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const ENTER_KEY = 13;

const ContactoTelefonoStep = () => {
  const dispatch = useDispatch();

  const [
    phoneInputRef,
    tel,
    setTelTouched,
    onTelNumberChange,
    onTelNumberBlur,
    onTelIsMobileChange,
  ] = usePhone("");

  useEffect(() => {
    dispatch(clearTelefono());
  }, [dispatch]);

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY) {
      setTelTouched(true);
      if (!tel.error)
        dispatch(setTelefono({ value: tel.number, isMobile: tel.isMobile }));
    }
  };

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={"Ingresá el número de teléfono del asegurado"}
        marginBottom={60}
      />

      <div style={{ display: "flex", flex: 1 }}>
        <div>
          <PhoneTipoSelector
            value={tel.isMobile}
            onChange={onTelIsMobileChange}
          />
        </div>
        <div style={{ flex: 1, paddingLeft: 16 }}>
          <TextField
            inputRef={phoneInputRef}
            label="NÚMERO"
            fullWidth={true}
            onFocus={(event) => event.target.select()}
            onKeyDown={onKeyDown}
            autoFocus
            type="tel"
            value={tel.number}
            error={tel.touched && tel.error ? true : false}
            onChange={onTelNumberChange}
            onBlur={onTelNumberBlur}
            placeholder={
              tel.isMobile ? "Ej: (011) 15 2938-2938" : "Ej: (011) 2938-2938"
            }
            InputProps={{
              inputComponent: PhoneInput,
            }}
            inputProps={{ isMobile: tel.isMobile, area: tel.area }}
            helperText={
              tel.touched && tel.error
                ? tel.error
                : tel.area
                ? tel.area.Description
                : ""
            }
          />
        </div>
      </div>
      <div style={{ paddingTop: pxToRem(24) }}>
        <CallToActionButton
          disabled={tel.number.length === 0}
          label={"Continuar"}
          onClick={() => {
            if (!tel.error)
              dispatch(
                setTelefono({ value: tel.number, isMobile: tel.isMobile })
              );
          }}
        />
      </div>
    </MulticotizadorContent>
  );
};

export default ContactoTelefonoStep;
