import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { setFechaNacimiento, clearFechaNacimiento } from "../actions";

import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import DatePicker from "~styled/datePicker/DatePicker";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 8,
    paddingBottom: 100,
    width: "100%",
    maxWidth: 500,
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
  buttonContinuar: {
    paddingTop: 24,
  },
}));

const FechaNacimientoStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);

  useEffect(() => {
    dispatch(clearFechaNacimiento());
  }, [dispatch]);

  const canContinue = value && !isNaN(value.getTime());
  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) {
      dispatch(setFechaNacimiento({ value: value, selected: true }));
    }
  };

  return (
    <div className={classes.cotizador}>
      <div className={classes.title}>
        <PrimaryTitle
          label="Ingresá la fecha de nacimiento del asegurado"
          marginBottom={48}
        />
      </div>

      <DatePicker
        fullWidth
        className={classes.datePicker}
        onChange={(date) => {
          setValue(date);
        }}
        value={value}
        onKeyDown={onKeyDown}
      />
      <div className={classes.buttonContinuar}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            dispatch(setFechaNacimiento({ value: value, selected: true }));
          }}
        />
      </div>
    </div>
  );
};

export default FechaNacimientoStep;
