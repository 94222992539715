import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "3%",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignContent: "stretch",
    alignItems: "stretch",
  },
  item: {
    flexGrow: 1,
    flexBasis: "49%",
  },
}));

const TipoCobertura = ({ value, onChange }) => {
  const handleChange = (event) => {
    onChange(event);
  };

  const classes = useStyles();

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" />
      <RadioGroup
        aria-label="position"
        name="position"
        value={value}
        onChange={handleChange}
        row
      >
        <div className={classes.container}>
          <div className={classes.item}>
            <FormControlLabel
              value="RC"
              control={<Radio color="default" />}
              label="Responsabilidad Civil"
            />
          </div>
          <div className={classes.item}>
            <FormControlLabel
              value="TCBase"
              control={<Radio color="secondary" />}
              label="Terceros Completo"
            />
          </div>
          <div className={classes.item}>
            <FormControlLabel
              value="TCPremium"
              control={<Radio color="secondary" />}
              label="Terceros Premium"
            />
          </div>
          <div className={classes.item}>
            <FormControlLabel
              value="TR"
              control={<Radio color="primary" />}
              label="Todo Riesgo"
            />
          </div>
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default TipoCobertura;
