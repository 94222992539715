import React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Paper,
  Typography,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { setTake, setPage } from "./Filtros/actions";
import { solicitudesEmisionPath, paths } from "~constants/referenceNames";
import TableLoading from "~styled/tableLoading/TableLoading";
import Pagination from "~styled/pagination/Pagination";
import { paginationOptions } from "~constants/pagination";
import Filtros from "./Filtros/Filtros";
import ColorLabelNegocio from "~styled/colorLabelNegocio/ColorLabelNegocio";
import { estadosSolicitudEmisionOptions } from "./Filtros/estados";
import { estadosSolicitudEmisionAdminOptions } from "./Filtros/estadosAdmin";
import WhatsappIcon from "~assets/icons/whatsapp.svg";
import pxToRem from "~libs/pxToRem";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  tableContainer: {
    backgroundColor: theme.colours.white,
    minHeight: 500,
    overflow: "auto",
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    fontSize: 16,
    color: "#405A84",
  },
  sinCotizaciones: {
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
  },
  iconButton: {
    color: theme.colours.verdeMgBroker,
    padding: 0,
  },
  tableCell: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
  },
  tableCellCenter: {
    paddingLeft: 20,
    paddingRight: 0,
    fontSize: "12px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "center",
    alignItems: "center",
  },
  tableCellHeader: {
    fontSize: "small",
    paddingRight: 0,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.violeta,
  },
  dash: { display: "flex", justifyContent: "center" },
}));

const emptyListText = "No hay resultados.";

const useReduxSelector = () =>
  useSelector((state) => ({
    take: state.solicitudesEmisionFiltrosReducer.take,
    page: state.solicitudesEmisionFiltrosReducer.page,
    solicitudesEmision: state.solicitudesEmisionReducer.solicitudesEmision,
    totalRegistros: state.solicitudesEmisionReducer.totalRegistros,
    isLoading: state.solicitudesEmisionReducer.isLoading,
    isAdminUser: state.authenticatedViewReducer.isAdminUser,
  }));

const ListadoSolicitudesEmision = () => {
  const {
    page,
    take,
    solicitudesEmision,
    totalRegistros,
    isLoading,
    isAdminUser,
  } = useReduxSelector();
  const dispatch = useDispatch();
  const empty = totalRegistros === 0;
  const printTelefonoTitular = (telefonoTitular, esTelefonoMovil) => {
    if (!telefonoTitular) return <div className={classes.dash}>&mdash;</div>;

    if (!esTelefonoMovil) return telefonoTitular;

    let telefonoWhatsappFormat = `54${telefonoTitular
      .replace("0", "")
      .replace("15", "")}`; // just replaces first occurence of '0' and '15'
    return (
      <span style={{ display: "flex", minWidth: pxToRem(140) }}>
        <div style={{ paddingRight: 6 }}>{telefonoTitular}</div>
        <Tooltip title="Chatear por Whatsapp">
          <a
            href={"https://wa.me/" + telefonoWhatsappFormat}
            rel="noopener noreferrer"
            target="_blank"
            tool="true"
          >
            <img
              alt=""
              src={WhatsappIcon}
              style={{ width: "1.3em", color: "#4aba7c" }}
            />
          </a>
        </Tooltip>
      </span>
    );
  };

  const classes = useStyles();

  const handleChangePage = (newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (value) => {
    dispatch(setTake(value));
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.tableContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Filtros />
          </div>
          <Divider></Divider>
          {isLoading && <TableLoading length={5} />}
          {!isLoading && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Negocio"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Estado"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Vigencia Desde"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Origen"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Titular"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"E-Mail"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Teléfono"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Vehículo"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Dominio"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"C.P."}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Compañía"}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      color="textSecondary"
                      className={classes.tableCellHeader}
                    >
                      {"Continuar"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.table}>
                {isLoading && (
                  <div className={classes.progress}>
                    <CircularProgress size={60} />
                  </div>
                )}
                {!isLoading &&
                  !empty &&
                  solicitudesEmision.map((solicitudEmision) => (
                    <TableRow
                      key={"table-solicitudesEmision-" + solicitudEmision.id}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell className={classes.tableCell}>
                        <ColorLabelNegocio
                          abbreviated
                          negocio={solicitudEmision.negocio}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {isAdminUser
                          ? estadosSolicitudEmisionAdminOptions[
                              solicitudEmision.estado
                            ]
                          : estadosSolicitudEmisionOptions[
                              solicitudEmision.estado
                            ]}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {format(
                          Date.parse(solicitudEmision.vigenciaDesde),
                          "dd'/'MM'/'yyyy",
                          { locale: es }
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {solicitudEmision.origen ? (
                          solicitudEmision.origen
                        ) : (
                          <div className={classes.dash}>&mdash;</div>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {solicitudEmision.nombreTitular ? (
                          solicitudEmision.nombreTitular
                        ) : (
                          <div className={classes.dash}>&mdash;</div>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {solicitudEmision.emailTitular ? (
                          solicitudEmision.emailTitular
                        ) : (
                          <div className={classes.dash}>&mdash;</div>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {printTelefonoTitular(
                          solicitudEmision.telefonoTitular,
                          solicitudEmision.esTelefonoMovil
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {`(${solicitudEmision.anio}) ${solicitudEmision.marca} ${solicitudEmision.modelo}`}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {solicitudEmision.dominio ? (
                          solicitudEmision.dominio
                        ) : (
                          <div className={classes.dash}>&mdash;</div>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {solicitudEmision.codigoPostal}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {solicitudEmision.compania}
                      </TableCell>
                      <TableCell className={classes.tableCellCenter}>
                        <Tooltip title={"Ir a Emitir Póliza"}>
                          <IconButton
                            className={classes.iconButton}
                            component={Link}
                            to={`${paths[solicitudesEmisionPath]}/${solicitudEmision.id}`}
                          >
                            <ArrowForwardIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
          {!isLoading && empty ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "2vh",
                paddingBottom: "2vh",
              }}
            >
              <Typography className={classes.sinCotizaciones}>
                {emptyListText}
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2vh",
                paddingBottom: "2vh",
                width: "100%",
              }}
            >
              {!isLoading && (
                <Pagination
                  rowsPerPage={take}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  totalRegistros={totalRegistros}
                  options={paginationOptions}
                />
              )}
            </div>
          )}
        </div>
      </Paper>
    </>
  );
};

export default ListadoSolicitudesEmision;
