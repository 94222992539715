import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@mui/styles";
import ItemLoadingCard from "../ItemLoadingCard";
import IconEdit from "~assets/icons/ic_edit.svg";
import Modal from "../Modal";
import { getNombreProvincia } from "~styled/vehiculoProvinciaSelector/getProvincias";
import LocalidadStepper from "./LocalidadStepper";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
}));

const ItemLocalidadCard = ({
  label,
  onChange,
  displayError,
  onBlur,
  readOnly,
  isLoading,
  provincia,
  localidad,
  codigoPostal,
}) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);

  const handleChangeValue = (localidad, provincia, codigoPostal) => {
    setEdit(false);
    if (onChange)
      onChange({
        localidad: { value: localidad, valid: true, focus: false },
        provincia: { value: provincia, valid: true, focus: false },
        codigoPostal: { value: codigoPostal, valid: true, focus: false },
      });
  };

  if (isLoading) return <ItemLoadingCard label={label} />;

  const localidadFormatted =
    provincia && localidad && codigoPostal
      ? `(CP ${codigoPostal}) ${localidad}, ${getNombreProvincia(
          provincia
        ).toUpperCase()}`
      : null;

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        <Modal
          opened={edit}
          title="Selector de Localidad"
          onClose={() => setEdit(false)}
        >
          <LocalidadStepper onChange={handleChangeValue} />
        </Modal>
        {!edit && (
          <>
            <Typography className={classes.value}>
              {localidadFormatted}
            </Typography>
            {!readOnly && (
              <div className={classes.buttonLink} onClick={() => setEdit(true)}>
                <img alt="" src={IconEdit} className={classes.icon} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ItemLocalidadCard;
