import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import { paths, polizasMultiramaPath } from "~constants/referenceNames";

const polizasMultirama = () => {
  return {
    getPolizasMultirama: (data) => {
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;

      parameters = data.compania
        ? parameters + `&Compania=${data.compania}`
        : parameters;

      parameters = data.rama ? parameters + `&Rama=${data.rama}` : parameters;

      parameters = data.estado
        ? parameters + `&Estado=${data.estado}`
        : parameters;

      parameters = data.fechaVigenciaDesde
        ? parameters +
          `&FechaVigenciaDesde=${data.fechaVigenciaDesde.toISOString()}`
        : parameters;

      if (data.fechaVigenciaHasta) {
        data.fechaVigenciaHasta.setHours(23, 59, 59);
        parameters =
          parameters +
          `&FechaVigenciaHasta=${data.fechaVigenciaHasta.toISOString()}`;
      }

      let url =
        environment.segurosUrl + paths[polizasMultiramaPath] + "?" + parameters;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getPolizaMultirama: (id) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      let url = environment.segurosUrl + paths[polizasMultiramaPath] + "/" + id;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getRamas: () => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      let url = environment.segurosUrl + "/ramas";
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getAsegurado: (documento) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      let url = environment.segurosUrl + `/asegurados/${documento}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postPolizaMultirama: (data) => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasMultiramaPath]}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    patchAnularPoliza: (polizaId, data) => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[polizasMultiramaPath]}/${polizaId}/anular`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { polizasMultirama };
