import React, { useState, memo } from "react";
import { makeStyles } from "@mui/styles";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DateSelector from "~styled/dateFilter/DateSelector";
import { Typography, Button } from "@mui/material";
import { getDay, getDaysOfThisMonth } from "~libs/dateCalculator";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import isNullOrEmpty from "~libs/isNullOrEmpty";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const useStyles = makeStyles((theme) => ({
  selectorRoot: {
    display: "flex",
    flexDirection: "column",
    background: theme.colours.white,
    //zIndex: 1,
    position: "relative",
    width: "100%",
  },
  selectorExpanded: {
    display: "flex",
    padding: "0px 10px 0px 10px",
    borderRadius: "5px",
    border: "solid 1px #e4e4e6",
    backgroundColor: theme.colours.white,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "30px",
  },
  selectorNotExpanded: {
    display: "flex",
    padding: "0px 10px 0px 10px",
    borderRadius: "5px",
    border: "solid 1px #e4e4e6",
    backgroundColor: theme.colours.white,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "30px",
  },
  input: {
    display: "flex",
    flex: 1,
    fontSize: 12,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.brownGrey,
  },
  iconExpand: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "solid 1px #e4e4e6",
    borderRadius: "0px 5px 5px 0px",
    padding: "0px 10px 0px 10px",
    color: theme.colours.brownGrey2,
  },
  iconButton: {
    color: theme.colours.violeta,
    padding: 0,
  },

  borderedButton: {
    width: "33%",
    borderRight: "solid 1px #a7a7a7",
    borderRadius: "0px",
    textAlign: "center",
    justifyContent: "center",
    padding: "5px 0px 0px 0px ",
    textTransform: "none",
  },
  button: {
    width: "34%",
    borderRadius: "0px",
    textAlign: "center",
    justifyContent: "center",
    padding: "5px 0px 0px 0px ",
    textTransform: "none",
  },
  rowTop: {
    display: "flex",
    flexDirection: "row",
    border: "solid 1px #a7a7a7",
    borderRadius: "5px 5px 0px 0px",
    justifyContent: "stretch",
    width: "100%",
  },
  rowBottom: {
    display: "flex",
    flexDirection: "row",
    borderLeft: "solid 1px #a7a7a7",
    borderRight: "solid 1px #a7a7a7",
    borderBottom: "solid 1px #a7a7a7",
    borderRadius: " 0px 0px 5px 5px",
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingBottom: 5,
  },
  arrowPaper: {
    bottom: "100%",
    left: "50%",
    border: "solid transparent",
    height: 10,
    width: 10,
    position: "absolute",
    pointerEvents: "none",
    borderBottomColor: "#a7a7a7",
    borderWidth: "10px",
    marginLeft: "-10px",
  },
  listPaper: {
    border: "solid 1px #e4e4e6",
    borderRadius: "0 0 5px 5px",
    display: "block",
    position: "absolute",
    zIndex: 2,
    width: "100%",
    minWidth: 352,
    background: theme.colours.white,
    top: "44px",
    left: "-50px",
  },
  root: { padding: 10, display: "inline-block" },
}));

const DateRangePicker = ({
  fechaDesde,
  fechaHasta,
  onChangeStartDate,
  onChangeEndDate,
  onChangeInterval,
  onClickDisabled,
  disabled,
  shortcuts,
  maxDate,
}) => {
  const [optionsExpanded, setOptionsExpanded] = useState(false);

  const handleChangeInterval = (value) => () => {
    setOptionsExpanded(false);
    if (isNullOrEmpty(value)) {
      if (onChangeStartDate) onChangeStartDate("");
      if (onChangeEndDate) onChangeEndDate("");
      return;
    }
    if (onChangeInterval) onChangeInterval(value);
  };

  const handleClickAway = () => {
    setOptionsExpanded(false);
  };

  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.selectorRoot}>
        <div>
          <IconButton
            onClick={() => {
              disabled
                ? onClickDisabled()
                : setOptionsExpanded(!optionsExpanded);
            }}
            className={
              optionsExpanded
                ? classes.selectorExpanded
                : classes.selectorNotExpanded
            }
          >
            <div className={classes.input}>
              {fechaDesde
                ? format(Date.parse(fechaDesde), "dd'/'MM'/'yyyy", {
                    locale: es,
                  }) +
                  " a " +
                  format(Date.parse(fechaHasta), "dd'/'MM'/'yyyy", {
                    locale: es,
                  })
                : "Siempre"}
            </div>
            <div className={classes.iconExpand}>
              <DateRangeIcon />
            </div>
          </IconButton>
        </div>
        {optionsExpanded && (
          <ExpandedView
            fechaDesde={fechaDesde}
            fechaHasta={fechaHasta}
            onChangeInterval={handleChangeInterval}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            setOptionsExpanded={setOptionsExpanded}
            shortcuts={shortcuts}
            maxDate={maxDate}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DateRangePicker;

const ExpandedView = memo(
  ({
    fechaDesde,
    fechaHasta,
    onChangeInterval,
    onChangeStartDate,
    onChangeEndDate,
    setOptionsExpanded,
    shortcuts = shortcutsDefault,
    maxDate,
  }) => {
    const classes = useStyles();

    const [startDateSelected, setStartDateSelected] = useState(false);

    const handleChange = (startDate, endDate) => {
      if (onChangeStartDate && startDateSelected) onChangeStartDate(startDate);
      if (onChangeEndDate && startDateSelected) onChangeEndDate(endDate);
      if (setOptionsExpanded && startDateSelected) setOptionsExpanded(false);
      setStartDateSelected(!startDateSelected);
    };

    return (
      <div className={classes.listPaper}>
        <div className={classes.arrowPaper} />
        <div className={classes.triangleInner} />
        <div className={classes.root}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h3" className={classes.label}>
              Mostrar cotizaciones para
            </Typography>
            <div className={classes.rowTop}>
              {shortcuts.slice(0, 3).map((shortcut, index) => (
                <Button
                  key={index}
                  onClick={onChangeInterval(shortcut.value)}
                  className={
                    index === 2 ? classes.button : classes.borderedButton
                  }
                >
                  <Typography
                    key={index}
                    variant="h3"
                    className={classes.label}
                  >
                    {shortcut.label}
                  </Typography>
                </Button>
              ))}
            </div>
            <div className={classes.rowBottom}>
              {shortcuts.slice(3, 6).map((shortcut, index) => (
                <Button
                  key={index}
                  onClick={onChangeInterval(shortcut.value)}
                  className={
                    index === 2 ? classes.button : classes.borderedButton
                  }
                >
                  <Typography
                    key={index}
                    variant="h3"
                    className={classes.label}
                  >
                    {shortcut.label}
                  </Typography>
                </Button>
              ))}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}></div>
          </div>
          <DateSelector
            startDay={fechaDesde}
            endDay={fechaHasta}
            onChange={handleChange}
            maxDate={maxDate}
          />
        </div>
      </div>
    );
  }
);

const shortcutsDefault = [
  { label: "Esta Semana", value: -getDay() },
  { label: "Últimos 7 días", value: -7 },
  { label: "Este mes", value: -getDaysOfThisMonth() },
  { label: "Últimos 30 días", value: -30 },
  { label: "Últimos 60 días", value: -60 },
  { label: "Siempre", value: null },
];
