import React from "react";
import NumberFormat from "react-number-format";
import ContentLoader from "react-content-loader";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  smallFont: {
    fontSize: "normal",
  },
  normalFont: {
    fontSize: "1.1rem",
  },
  button: {
    background: theme.colours.white,
    alignItems: "baseline",
    color: theme.colours.verdeMgBroker,
    fontWeight: "bold",
    width: "100%",
    borderRadius: 0,
  },
  cotizacionesCiaContainer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    minHeight: "120px",
  },
  cotizacionesCiaContainer2: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    width: "100%",
    minHeight: "120px",
  },
  importeContainer: {
    display: "flex",
    flexDirection: "row",
    whiteSpace: "nowrap",
    alignItems: "baseline",
    paddingTop: "1vh",
  },
  buttonSuffix: {
    margin: 2.5,
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "1.5vh",
    textTransform: "lowercase",
  },
  planName: {
    fontFamily: "Nunito",
    fontSize: "15px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "center",
    alignContent: "center",
    justifyContent: "center",
    color: theme.colours.grey5,
  },
  sinCotizaciones: {
    display: "flex",
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "1em",
    textAlign: "center",
    padding: 20,
    textTransform: "uppercase",
    justifyContent: "center",
    alignItems: "center",
  },
  visibility: {
    width: "100%",
    display: "flex",
    alignSelf: "flex-start",
    paddingLeft: "5px",
    color: "lightgray",
  },
  seleccionadaUsuario: {
    border: `2px solid ${theme.colours.lightIndigo}`,
    "&:hover": {
      "& p#mensajeSeleccionada": {
        display: "block",
        color: "black",
      },
    },
  },
  innerTriangle: {
    borderLeft: "20px solid transparent",
    borderRight: `20px solid ${theme.colours.lightIndigo}`,
    borderBottom: "20px solid transparent",
    width: "100%",
  },

  //loading button
  root: {
    display: "flex",
    background: theme.colours.white,
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
    height: 120,
    borderRight: "1px solid #d5d5d5",
    borderTop: "1px solid #d5d5d5",
  },
  buttonLoading: {
    display: "flex",
    flexDirection: "row",
    background: theme.colours.white,
    alignItems: "baseline",
    justifyContent: "center",
    paddingRight: 0,
    paddingLeft: 0,
    height: "100%",
    borderRadius: 0,
  },
  buttonPrefix: {
    color: theme.colours.verdeMgBroker,
    fontSize: "1.2rem",
    padding: "10px 0px 0px 10px",
    fontWeight: "bold",
  },
  buttonSuffixLoading: {
    display: "flex",
    marginLeft: 5,
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "2vh",
    textTransform: "lowercase",
  },

  loading: { width: 60, height: 40 },
}));

const PolizaButton = ({ isLoading, polizas, compania, onClick }) => {
  const classes = useStyles();

  let font = "normalFont";
  if (polizas && polizas.importe >= 10000) font = "smallFont";

  if (isLoading) return <LoadingButton />;

  return (
    <div className={classes.button}>
      {polizas && polizas.length > 0 && (
        <div className={classes.cotizacionesCiaContainer}>
          {polizas.map((row) => (
            <Button
              key={"Poliza-button-" + row.cotizacionPlanId}
              className={classes.button}
              onClick={onClick(row, compania)}
            >
              <div
                className={`${
                  polizas.length > 1
                    ? classes.cotizacionesCiaContainer2
                    : classes.cotizacionesCiaContainer
                } ${row.seleccionada ? classes.seleccionadaUsuario : ""}`}
              >
                {row.seleccionada && <div className={classes.innerTriangle} />}

                {row.enEsquema && (
                  <div className={classes.visibility}>
                    <Tooltip title={"Cotización mostrada al usuario"}>
                      <VisibilityIcon />
                    </Tooltip>
                  </div>
                )}
                {!row.enEsquema && (
                  <div className={classes.visibility}>
                    <Tooltip
                      title={
                        "Cotización fuera de esquema (no disponible para solicitar seguro)"
                      }
                    >
                      <VisibilityOffIcon />
                    </Tooltip>
                  </div>
                )}
                <Typography className={classes.planName}>
                  {row.nombre}
                </Typography>
                <div className={classes.importeContainer}>
                  <NumberFormat
                    className={classes[font]}
                    value={row.importe}
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    suffix={" "}
                  />
                  <Typography className={classes.buttonSuffix}>
                    {" /mes"}
                  </Typography>
                </div>
              </div>
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default PolizaButton;

const LoadingButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.cotizacionesCiaContainer}>
        <Typography className={classes.planName}>
          <ContentLoader
            style={{ width: "90%", height: 50 }}
            speed={1}
            backgroundColor="#f7f8ff"
            foregroundColor="#d9e8df"
          >
            <rect x="4" y="26" rx="4" ry="4" width="140" height="40" />
          </ContentLoader>
        </Typography>

        <div className={classes.buttonLoading}>
          <Typography className={classes.buttonPrefix}>{"$"}</Typography>
          <div className={classes.loading}>
            <ContentLoader
              height={40}
              width={60}
              speed={1}
              backgroundColor="#f7f8ff"
              foregroundColor="#d9e8df"
            >
              <rect x="4" y="26" rx="4" ry="4" width="49" height="16" />
            </ContentLoader>
          </div>
          <Typography className={classes.buttonSuffixLoading}>
            {" /mes"}
          </Typography>
        </div>
      </div>
    </div>
  );
};
