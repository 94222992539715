import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";

import PersonIcon from "@mui/icons-material/Person";
import { useSelector } from "react-redux";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemEmailCard from "~business/Cards/ItemEmailCard";
import ItemDniCard from "~business/Cards/ItemDniCard";
import ItemDateCard from "~business/Cards/ItemDateCard";

const useReduxSelector = () =>
  useSelector((state) => {
    const asegurado = state.detallePolizaNuevoReducer.currentPoliza
      ? state.detallePolizaNuevoReducer.currentPoliza.asegurado
      : {};

    return {
      ...asegurado,
    };
  });

const AseguradoCard = ({ isLoading, onBlur, displayError }) => {
  const {
    nombreCompleto,
    tipoDocumento,
    numeroDocumento,
    fechaNacimiento,
    domicilio,
    email,
    telefonoCelular,
    telefonoFijo,
  } = useReduxSelector();

  const dniFormatted = tipoDocumento
    ? `(${tipoDocumento}) ${numeroDocumento}`
    : numeroDocumento;

  return (
    <DetalleCard title={"Asegurado"} IconHeader={PersonIcon}>
      <ItemInputCard
        isLoading={isLoading}
        label="Nombre"
        value={nombreCompleto}
        readOnly
      />
      <ItemEmailCard
        id="email"
        label="E-mail"
        isLoading={isLoading}
        value={email}
        readOnly
      />
      <ItemInputCard
        label={"Teléfono"}
        isLoading={isLoading}
        value={telefonoCelular ?? telefonoFijo}
        readOnly
      />
      <ItemDniCard
        isLoading={isLoading}
        label={"DNI"}
        value={dniFormatted}
        readOnly
      />
      <ItemDateCard
        isLoading={isLoading}
        label={"Fecha Nacimiento"}
        value={fechaNacimiento}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Domicilio"}
        value={domicilio}
        readOnly
      />
    </DetalleCard>
  );
};

export default AseguradoCard;
