import React, { memo, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import TipoCobertura from "~business/coberturas/tipoCobertura/TipoCobertura";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import NegocioCheckbox from "../../business/coberturas/negocioCheckbox/negocioCheckbox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  compania: {
    textAlign: "center",
    fontWeight: 900,
    color: theme.colours.violeta,
    paddingTop: 15,
    paddingBottom: 15,
    display: "block",
    flex: 1,
  },
  tipoPoliza: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
  },
  importe: {
    textAlign: "center",
    fontWeight: 800,
    color: theme.colours.violeta,
  },
  content: {
    paddingBottom: 60,
  },
  sentenceTitle: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
    paddingTop: "2vh",
    paddingBottom: "2vh",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "5%",
    alignItems: "center",
    maxWidth: 650,
    width: "100%",
  },
  buttonBack: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.white,
    color: "#455597",
    borderRadius: 50,
    border: 0,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  button: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const DescripcionEditor = memo(
  ({ isOpened, onClose, compania, poliza, isNew, onClickAceptar }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [id, setId] = useState("");

    const [descripcion, setDescripcion] = useState("");
    const [nombre, setNombre] = useState("");
    const [tipoCobertura, setTipoCobertura] = useState(null);

    const [negocios, setNegocios] = useState([
      {
        id: "MercadoAbierto",
        activa: false,
      },
      {
        id: poliza ? poliza.negocios[1].id : "Prendarios",
        activa: false,
      },
    ]);

    useEffect(() => {
      if (isNew) {
        setId("");
        setNombre("");
        setDescripcion("");
        setTipoCobertura(null);
        setNegocios([
          {
            id: "MercadoAbierto",
            activa: false,
          },
          {
            id: "Prendarios",
            activa: false,
          },
        ]);
      } else {
        setId(poliza.id);
        setNombre(poliza.nombre);
        setDescripcion(poliza.descripcion);
        setTipoCobertura(poliza.tipoCobertura);
        setNegocios([
          {
            id: poliza.negocios[0].id,
            activa: poliza.negocios[0].activa,
          },
          {
            id: poliza.negocios[1].id,
            activa: poliza.negocios[1].activa,
          },
        ]);
      }
    }, [poliza, isNew]);

    useEffect(() => {
      if (!isOpened) {
        setId("");
        setNombre("");
        setDescripcion("");
        setTipoCobertura(null);
        setNegocios([
          {
            id: "MercadoAbierto",
            activa: false,
          },
          {
            id: "Prendarios",
            activa: false,
          },
        ]);
      }
    }, [isOpened]);

    const handleChangeId = (event) => {
      setId(event.target.value);
    };

    const handleChangeNombre = (event) => {
      setNombre(event.target.value);
    };

    const handleChangeDescription = (event) => {
      setDescripcion(event.target.value);
    };

    const handleChangeTipoCobertura = (event) => {
      setTipoCobertura(event.target.value);
    };

    return (
      <div className={classes.root}>
        <Dialog
          open={isOpened}
          scroll="paper"
          onClose={onClose}
          fullScreen={fullScreen}
        >
          <DialogTitle
            style={{
              padding: 0,
            }}
            disableTypography
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: 48 }} />
              <Typography variant={"h5"} className={classes.compania}>
                {compania.nombreCompania}
              </Typography>

              <div style={{ width: 48 }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={onClose}
                  aria-label="Close"
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            {!isNew && (
              <Typography variant={"subtitle2"} className={classes.tipoPoliza}>
                {`Cobertura ${id}`}
              </Typography>
            )}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Divider />
            {isNew && (
              <div>
                <Typography
                  variant={"subtitle2"}
                  className={classes.sentenceTitle}
                >
                  {"Id"}
                </Typography>
                <TextField
                  label="Id"
                  multiline
                  onChange={(event) => handleChangeId(event)}
                  value={id}
                  fullWidth
                />
              </div>
            )}
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"Nombre"}
              <Tooltip
                arrow={true}
                placement={"right"}
                title={
                  <p style={{ fontSize: 15 }}>
                    Podés usar placeholders para mostrar información
                    dinámicamente:
                    <ul>
                      {"{franquicia}"} mostrará la franquicia, si la cobertura
                      tiene una.
                    </ul>
                  </p>
                }
              >
                <HelpIcon style={{ color: "#afafaf" }} />
              </Tooltip>
            </Typography>
            <TextField
              label="Nombre"
              multiline
              onChange={(event) => handleChangeNombre(event)}
              value={nombre}
              fullWidth
            />
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"Descripción"}
              <Tooltip
                arrow={true}
                placement={"right"}
                title={
                  <p style={{ fontSize: 15 }}>
                    Podés usar placeholders para mostrar información
                    dinámicamente:
                    <ul>
                      {"{franquicia}"} mostrará la franquicia, si la cobertura
                      tiene una.
                    </ul>
                  </p>
                }
              >
                <HelpIcon style={{ color: "#afafaf" }} />
              </Tooltip>
            </Typography>
            <TextField
              label="Descripción"
              multiline
              onChange={(event) => handleChangeDescription(event)}
              value={descripcion}
              fullWidth
            />
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"Tipo Cobertura"}
            </Typography>
            <TipoCobertura
              value={tipoCobertura}
              onChange={handleChangeTipoCobertura}
            />
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"Negocio"}
            </Typography>
            <NegocioCheckbox negocios={negocios} setNegocios={setNegocios} />
          </DialogContent>
          <div className={classes.buttonContainer}>
            <Button
              onClick={onClose}
              className={classes.buttonBack}
              variant="contained"
            >
              CANCELAR
            </Button>
            <Button
              className={classes.button}
              onClick={onClickAceptar(
                poliza,
                id,
                nombre,
                descripcion,
                tipoCobertura,
                negocios
              )}
              variant="contained"
              color="primary"
            >
              ACEPTAR
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
);

export default DescripcionEditor;
