import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";

const oficinas = () => {
  return {
    getOficinas: () => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let url = environment.segurosUrl + "/oficinas";
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { oficinas };
