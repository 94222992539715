import React, { useEffect, Fragment, useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import {
  getEsquemas,
  postEsquemas,
  updateEsquemas,
  setEsquemaSelected,
  patchEsquemas,
} from "./actions";

import { useSelector, useDispatch } from "react-redux";
import EsquemasSortableTable from "~business/coberturas/esquemasSortableTable/EsquemasSortableTable";
import TabSelector from "~business/coberturas/tabSelector/TabSelector";
import MenuItemIcon from "@mui/icons-material/Label";
import TableLoading from "~styled/tableLoading/TableLoading";
import { esquemasPath, paths } from "~constants/referenceNames";
import { useHistory, useLocation } from "react-router-dom";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import CoberturaEditor from "./CoberturaEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    minWidth: "100%",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
  },
  progress: {
    margin: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingTop: "2vh",
    paddingBottom: "2vh",
    alignItems: "center",
    maxWidth: 650,
    width: "100%",
  },
  buttonBack: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.white,
    color: "#455597",
    borderRadius: 50,
    border: 0,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  button: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    esquemas: state.esquemasReducer.esquemas,
    esquemaSelected: state.esquemasReducer.esquemaSelected,
    hasChanged: state.esquemasReducer.hasChanged,
    failed: state.esquemasReducer.failed,
  }));

const ListadoEsquemas = ({ esquemaId }) => {
  const { esquemas, esquemaSelected, hasChanged, failed } = useReduxSelector();
  const isLoading = !esquemaSelected;
  const history = useHistory();

  const [openEditor, setOpenEditor] = useState(false);
  const [companiaSelected, setCompaniaSelected] = useState("");
  const [coberturaSelected, setCoberturaSelected] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEsquemas());
  }, [dispatch]);

  const handleChange = (value) => {
    if (!hasChanged) {
      const url = `${paths[esquemasPath]}/${value}`;
      if (location.pathname !== url) {
        history.push(url);
      }
    } else
      dispatch(
        queueMessage(
          "No es posible cambiar de esquema ya que no se han guardado los cambios actuales"
        )
      );
  };

  useEffect(() => {
    if (esquemas && esquemaId) {
      const esquema = isLoading
        ? null
        : esquemas.find((esquema) => {
            return esquema.id === esquemaId;
          });

      dispatch(setEsquemaSelected(esquema));
    }
    //eslint-disable-next-line
  }, [esquemas, esquemaId]);

  const handleChangeCoberturas = (row) => (coberturas) => {
    row.idsCoberturas = coberturas;
    dispatch(updateEsquemas(esquemas));
  };

  const handleChangeOrder = (esquema) => (coberturas) => {
    coberturas.map((cobertura, index) => {
      return (cobertura.orden = index + 1);
    });
    esquema.idsCoberturas = coberturas;
    dispatch(updateEsquemas(esquemas));
  };

  const handleGuardarButton = () => {
    dispatch(
      postEsquemas(
        esquemaSelected.id,
        esquemaSelected.esquemasCotizacion.map((esquema) => ({
          idCompania: esquema.idCompania,
          orden: esquema.orden,
          idsCoberturas: esquema.idsCoberturas
            ? esquema.idsCoberturas.map((id) => id.idCobertura)
            : null,
        }))
      )
    );
  };

  const handleCancelarButton = () => {
    dispatch(getEsquemas());
  };

  const handleClickCobertura = (idCompania, cobertura) => {
    if (esquemaSelected.id !== "Prendarios") return;

    setCompaniaSelected(idCompania);
    setCoberturaSelected(cobertura);

    setOpenEditor(true);
  };

  const handleCloseEditor = () => {
    setOpenEditor(false);
  };

  const handleAceptarEditor = (
    antiguedad,
    usoVehiculo,
    tiposVehiculoSelected
  ) => {
    if (antiguedad < 0) {
      dispatch(queueMessage("La antigüedad debe ser mayor o igual a 0."));

      return;
    }

    if (tiposVehiculoSelected === null) {
      dispatch(
        queueMessage("Seleccione al menos 1 tipo de vehículo o la opción Todos")
      );

      return;
    }

    let data = {
      compania: companiaSelected,
      coberturaId: coberturaSelected.idCobertura,
      antiguedadVehiculoMinima: antiguedad,
      usoVehiculo,
      tiposVehiculo:
        tiposVehiculoSelected[0] === undefined ? null : tiposVehiculoSelected,
    };

    dispatch(patchEsquemas(data));
    setOpenEditor(false);
  };

  const classes = useStyles();

  if (failed) return <UnexpectedError />;

  return (
    <SectionLayout
      Icon={MenuItemIcon}
      title={"Esquemas"}
      selectedDefault={esquemasPath}
    >
      <div className={classes.root}>
        <CoberturaEditor
          isOpened={openEditor}
          onClose={handleCloseEditor}
          onClickAceptar={handleAceptarEditor}
          compania={companiaSelected}
          cobertura={coberturaSelected}
        />
        <Fragment>
          {isLoading && (
            <div className={classes.container}>
              <TabSelector value={0} list={["", "", ""]}></TabSelector>
              <TableLoading length={5} />
            </div>
          )}
          {!isLoading && (
            <div className={classes.container}>
              <TabSelector
                value={esquemaSelected.id}
                onChange={handleChange}
                list={esquemas}
              ></TabSelector>
              <EsquemasSortableTable
                esquemas={esquemaSelected.esquemasCotizacion.sort(
                  (item1, item2) => {
                    return item1.orden < item2.orden ? -1 : 1;
                  }
                )}
                onClickCobertura={handleClickCobertura}
                onChangeOrder={handleChangeOrder(esquemaSelected)}
                onChangeCoberturas={handleChangeCoberturas}
              />
            </div>
          )}
          <div className={classes.buttonContainer}>
            <Button
              onClick={handleCancelarButton}
              className={classes.buttonBack}
              variant="contained"
              disabled={!hasChanged}
            >
              {isLoading ? "" : "DESHACER"}
            </Button>
            <Button
              className={classes.button}
              onClick={handleGuardarButton}
              variant="contained"
              color="primary"
              disabled={!hasChanged}
            >
              {isLoading ? "" : "GUARDAR"}
            </Button>
          </div>
        </Fragment>
      </div>
    </SectionLayout>
  );
};

export default ListadoEsquemas;
