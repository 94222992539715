import React, { useState, Fragment, memo } from "react";
import { makeStyles } from "@mui/styles";
import getProvincias from "./getProvincias";
import filterItems from "~libs/filterItems";
import SearcherList from "../searcherList/SearcherList";
import ListView from "../listView/ListView";
import localRepository from "../../../localRepository";

const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around",
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
  },
}));

const provincias = getProvincias();

const VehiculoProvinciaSelector = memo(({ onChange }) => {
  const [filter, setFilter] = useState("");

  const mostUsedProv = localRepository.mostUsedProv
    .get()
    .sort((x, y) => y.count - x.count)
    .slice(0, 3);

  const handleItem = (item) => {
    setFilter("");
    if (onChange) onChange({ target: { value: item.value } });
    localRepository.mostUsedProv.set(item);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.searcher}>
        <SearcherList
          onClear={() => setFilter("")}
          filter={filter}
          onChange={handleFilterChange}
          placeholder={"Buscar provincia"}
        />
        <ListView
          onItemSelected={handleItem}
          list={filter === "" ? provincias : filterItems(provincias, filter)}
          mostUsedList={filter === "" ? mostUsedProv : []}
        />
      </div>
    </Fragment>
  );
});

export default VehiculoProvinciaSelector;
