import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFormaDePago, clearFormaDePago } from "../actions";
import { makeStyles } from "@mui/styles";
import FormaDePagoSelector from "~business/formaDePagoSelector/FormaDePagoSelector";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 8,
    paddingBottom: 100,
    maxWidth: 500,
    width: "100%",
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    formaDePagoSelected: state.multicotizadorReducer.formaDePagoSelected,
  }));

const FormaDePagoStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formaDePagoSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(clearFormaDePago());
  }, [dispatch]);

  const handleChange = (event) => {
    dispatch(setFormaDePago(event.target.value));
  };

  return (
    <div className={classes.cotizador}>
      <div className={classes.title}>
        <PrimaryTitle
          label="¿Cómo va a pagar las cuotas el asegurado?"
          marginBottom={32}
        />
      </div>
      <FormaDePagoSelector
        value={formaDePagoSelected}
        onChange={handleChange}
      />
    </div>
  );
});

export default FormaDePagoStep;
