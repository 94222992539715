import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import {
  paths,
  solicitudesEmisionBackendPath,
} from "~constants/referenceNames";

const emisionesManuales = () => {
  return {
    postEmisionManual: (solicitudEmisionId, data) => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${solicitudEmisionId}/poliza`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { emisionesManuales };
