import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils";
import environment from "~libs/environment";
import { paths, coberturasPath } from "~constants/referenceNames";

const coberturas = () => {
  return {
    getCoberturasById: ({ data, id }) => {
      const request = buildRequestWithAuth("GET", data);

      return fetch(
        `${environment.segurosUrl}/coberturas/${id}`,
        request,
        headersBackendSeguros
      )
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getCoberturas: (data) => {
      const request = buildRequestWithAuth("GET", data);
      return fetch(
        `${environment.segurosUrl}/coberturas`,
        request,
        headersBackendSeguros
      )
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    putCoberturas: (data) => {
      const request = buildRequestWithAuth("PUT", data, headersBackendSeguros);
      const url =
        environment.segurosUrl +
        paths[coberturasPath] +
        "/" +
        encodeURIComponent(data.id);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postCoberturas: (data) => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = environment.segurosUrl + paths[coberturasPath];
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { coberturas };
