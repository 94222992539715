import React, { memo, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Selector from "~styled/selector/Selector";
import DatePicker from "~styled/datePicker/DatePicker";

import {
  motivoAnulacionFilter,
  motivoAnulacionOptions,
} from "~constants/endosos";
import pxToRem from "~libs/pxToRem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  compania: {
    textAlign: "center",
    fontWeight: 900,
    color: theme.colours.violeta,
    paddingTop: 15,
    paddingBottom: 8,
    display: "block",
    flex: 1,
  },
  tipoPoliza: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
  },
  polizaTitle: {
    fontSize: pxToRem(12),
  },
  importe: {
    textAlign: "center",
    fontWeight: 800,
    color: theme.colours.violeta,
  },
  content: {
    paddingBottom: 50,
  },
  sentenceTitle: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
    paddingTop: "2vh",
    paddingBottom: "2vh",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "5%",
    alignItems: "center",
    maxWidth: 650,
    width: "100%",
  },
  buttonBack: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.white,
    color: "#455597",
    borderRadius: 50,
    border: 0,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  button: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  motivoSelector: {
    position: "absolute",
    width: "90%",
  },
  datePicker: {},
}));

const PopupAnular = memo(
  ({
    isOpened,
    onClose,
    polizaId,
    compania,
    nroPoliza,
    titular,
    onClickAceptar,
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [motivoAnulacion, setMotivoAnulacion] = useState(null);
    const [fechaAnulacion, setFechaAnulacion] = useState(null);
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
      setEnabled(
        motivoAnulacion !== null &&
          motivoAnulacion !== "" &&
          fechaAnulacion !== null &&
          fechaAnulacion !== ""
      );
    }, [motivoAnulacion, fechaAnulacion]);

    const handleChangeMotivoAnulacion = (motivo) => {
      setMotivoAnulacion(motivo);
    };

    const handleFechaAnulacion = (fechaAnulacion) => {
      const fechaAnulacionISO = fechaAnulacion.toISOString();
      setFechaAnulacion(fechaAnulacionISO);
    };

    return (
      <div className={classes.root}>
        <Dialog
          open={isOpened}
          scroll="paper"
          onClose={onClose}
          fullScreen={fullScreen}
        >
          <DialogTitle
            style={{
              padding: 0,
            }}
            disableTypography
          >
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ width: 48 }} />
              <Typography variant={"h5"} className={classes.compania}>
                {"Anular póliza"}
              </Typography>

              <div style={{ width: 48 }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={onClose}
                  aria-label="Close"
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <Typography variant={"subtitle2"} className={classes.tipoPoliza}>
              <span className={classes.polizaTitle}>Compañía:</span> {compania}
            </Typography>
            <Typography variant={"subtitle2"} className={classes.tipoPoliza}>
              <span className={classes.polizaTitle}>Número de Póliza:</span>{" "}
              {nroPoliza}
            </Typography>
            <Typography variant={"subtitle2"} className={classes.tipoPoliza}>
              <span className={classes.polizaTitle}>Titular:</span>{" "}
              {`${titular.nombre} ${titular.apellido}`}
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Divider />
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"Motivo"}
            </Typography>
            <div className={classes.motivoSelector}>
              <Selector
                id={"motivo"}
                value={motivoAnulacionOptions[motivoAnulacion]}
                options={motivoAnulacionFilter}
                onChange={handleChangeMotivoAnulacion}
                variant={"big"}
              />
            </div>
          </DialogContent>
          <DialogContent className={classes.content}>
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"Fecha de Anulación"}
            </Typography>
            <div className={classes.datePicker}>
              <DatePicker
                onChange={(fechaAnulacion) =>
                  handleFechaAnulacion(fechaAnulacion)
                }
                value={fechaAnulacion}
                fullWidth
              />
            </div>
          </DialogContent>

          <div className={classes.buttonContainer}>
            <Button
              onClick={onClose}
              className={classes.buttonBack}
              variant="contained"
            >
              CANCELAR
            </Button>
            <Button
              className={classes.button}
              onClick={() =>
                onClickAceptar(motivoAnulacion, polizaId, fechaAnulacion)
              }
              variant="contained"
              color="primary"
              disabled={!enabled}
            >
              ACEPTAR
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
);

export default PopupAnular;
