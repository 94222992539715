import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import { paths, esquemasPath } from "~constants/referenceNames";

const esquemas = () => {
  return {
    getEsquemas: (data) => {
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      const url = environment.segurosUrl + paths[esquemasPath];
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postEsquemas: (data) => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = environment.segurosUrl + paths[esquemasPath];
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    patchEsquemas: (data) => {
      const request = buildRequestWithAuth("PATCH", data, headersBackendSeguros);
      const url = environment.segurosUrl + paths[esquemasPath];
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getCoberturas: (compania, negocio) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[esquemasPath]}/${compania}?ProductorId=${negocio}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { esquemas };
