import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { queueMessage } from "~shared/snackbarsProvider/actions";

import {
  getRamas,
  setSubmitting,
  postPolizaMultirama,
  setNumeroPoliza,
  setPremio,
  setPolizaFileName,
  setAseguradoNombre,
  setAseguradoApellido,
  setAseguradoNumeroDocumento,
  setDominio,
  setCobertura,
  clearData,
} from "./actions";
import { paths, polizasMultiramaPath } from "~constants/referenceNames";

import DatosAseguradoCard from "./cards/DatosAseguradoCard";
import DatosGenerales from "./cards/DatosGeneralesCard";
import DatosPoliza from "./cards/DatosPolizaCard";
import ImpresosCard from "./cards/ImpresosCard";
import SuccessDialog from "./SuccessDialog";
import Layout from "~shared/layout/Layout";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";
import { Tooltip, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "1.5%",
    minWidth: "10px",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  btnFinalizar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  cardItem: { minWidth: 300 },
}));

const useReduxSelector = () =>
  useSelector((state) => {
    const ramaSelected = state.altaPolizaMultiramaReducer.ramaSelected
      ? state.altaPolizaMultiramaReducer.ramaSelected
      : {};

    return {
      ...ramaSelected,
      isLoading: state.altaPolizaMultiramaReducer.isLoading,
      isLoadingAsegurado: state.altaPolizaMultiramaReducer.isLoadingAsegurado,
      numeroPoliza: state.altaPolizaMultiramaReducer.numeroPolizaSelected,
      aseguradoNombre: state.altaPolizaMultiramaReducer.aseguradoNombreSelected,
      aseguradoApellido:
        state.altaPolizaMultiramaReducer.aseguradoApellidoSelected,
      aseguradoNumeroDocumento:
        state.altaPolizaMultiramaReducer.aseguradoNumeroDocumentoSelected,
      dominio: state.altaPolizaMultiramaReducer.dominioSelected,
      cobertura: state.altaPolizaMultiramaReducer.coberturaSelected,
      usuario: state.altaPolizaMultiramaReducer.usuarioSelected,
      compania: state.altaPolizaMultiramaReducer.companiaSelected,
      premio: state.altaPolizaMultiramaReducer.premioSelected,
      cuotas: state.altaPolizaMultiramaReducer.cuotasSelected,
      vigencia: state.altaPolizaMultiramaReducer.vigenciaSelected,
      vigenciaDesde: state.altaPolizaMultiramaReducer.vigenciaDesdeSelected,
      vigenciaHasta: state.altaPolizaMultiramaReducer.vigenciaHastaSelected,
      polizaFileName: state.altaPolizaMultiramaReducer.polizaFileNameSelected,
      cuponeraFileName:
        state.altaPolizaMultiramaReducer.cuponeraFileNameSelected,
      cedulaMercosurFileName:
        state.altaPolizaMultiramaReducer.cedulaMercosurFileNameSelected,
      tarjetaFileName: state.altaPolizaMultiramaReducer.tarjetaFileNameSelected,
      creatingPoliza: state.altaPolizaMultiramaReducer.creatingPoliza,
      polizaCreada: state.altaPolizaMultiramaReducer.polizaCreada,
      formaPago: state.altaPolizaMultiramaReducer.formaPagoSelected,
      aseguradoId: state.altaPolizaMultiramaReducer.aseguradoIdSelected,
    };
  });

const AltaPolizaMultirama = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isLoading,
    isLoadingAsegurado,
    numeroPoliza,
    aseguradoNombre,
    aseguradoApellido,
    aseguradoNumeroDocumento,
    dominio,
    cobertura,
    usuario,
    value: ramaValue,
    compania,
    premio,
    cuotas,
    vigenciaDesde,
    vigenciaHasta,
    polizaFileName,
    cuponeraFileName,
    cedulaMercosurFileName,
    tarjetaFileName,
    formaPago,
    creatingPoliza,
    polizaCreada,
    tieneDominio,
    aseguradoId,
  } = useReduxSelector();

  useEffect(() => {
    dispatch(getRamas());

    return () => {
      dispatch(clearData());
    };
    //eslint-disable-next-line
  }, [dispatch]);

  const handleGuardarSolicitud = () => {
    dispatch(setSubmitting(true));

    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    let inputs = [
      {
        setFocusFunction: setAseguradoNumeroDocumento,
        ...aseguradoNumeroDocumento,
      },

      { setFocusFunction: setAseguradoNombre, ...aseguradoNombre },
      { setFocusFunction: setAseguradoApellido, ...aseguradoApellido },
      { setFocusFunction: setNumeroPoliza, ...numeroPoliza },
      { setFocusFunction: setCobertura, ...cobertura },
      { setFocusFunction: setPremio, ...premio },
      { setFocusFunction: setPolizaFileName, ...polizaFileName },
    ];

    if (tieneDominio) {
      inputs = [...inputs, { setFocusFunction: setDominio, ...dominio }];
    }

    let inputToFocus = inputs.find((input) => !input.valid);

    if (inputToFocus)
      dispatch(
        inputToFocus.setFocusFunction({
          value: inputToFocus.value,
          valid: inputToFocus.valid,
          focus: true,
        })
      );
    if (inputToFocus) {
      dispatch(queueMessage("Hay campos sin completar o no válidos"));
      return;
    }

    let data = {
      numeroPoliza: numeroPoliza.value,
      aseguradoId: aseguradoId,
      aseguradoNombre: aseguradoNombre.value,
      aseguradoApellido: aseguradoApellido.value,
      aseguradoNumeroDocumento: aseguradoNumeroDocumento.value,
      dominio: dominio.value,
      usuarioId: usuario.value,
      rama: ramaValue,
      cantidadCuotas: cuotas.value,
      compania: compania.value,
      premio: premio.value,
      vigenciaDesde: vigenciaDesde.value,
      vigenciaHasta: vigenciaHasta.value,
      cobertura: cobertura.value,
      polizaFileName: polizaFileName.value,
      cuponeraFileName: cuponeraFileName.value,
      tarjetaFileName: tarjetaFileName.value,
      cedulaMercosurFileName: cedulaMercosurFileName.value,
      formaPago: formaPago.value,
    };
    dispatch(postPolizaMultirama(data));
  };

  const backAction = () => {
    history.push(paths[polizasMultiramaPath]);
  };

  return (
    <>
      <Layout
        fixed
        title={"Registro de Póliza"}
        subtitle={"Multirama"}
        backAction={backAction}
        selectedDefault={polizasMultiramaPath}
        data={
          <div className={classes.btnFinalizar}>
            <Tooltip title={"Registrar Póliza"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGuardarSolicitud}
                startIcon={<SaveIcon />}
              >
                {"REGISTRAR PÓLIZA"}
              </Button>
            </Tooltip>
          </div>
        }
      >
        {creatingPoliza && <LoadingViewGears />}

        {!creatingPoliza && (
          <div className={classes.root}>
            <div className={classes.body}>
              <div
                onClick={() => {
                  history.push(`/polizasMultirama`);
                }}
              >
                <SuccessDialog
                  openDialog={polizaCreada}
                  value={"Póliza registrada con éxito"}
                />
              </div>
              <div className={classes.cardsAndCuotasContainer}>
                <CardsColumn>
                  <DatosGenerales isLoading={isLoading} />
                  <ImpresosCard isLoading={isLoading} />
                </CardsColumn>
                <CardsColumn>
                  <DatosAseguradoCard
                    isLoadingAsegurado={isLoadingAsegurado}
                    isLoading={isLoading}
                  />
                  <DatosPoliza isLoading={isLoading} />
                </CardsColumn>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default AltaPolizaMultirama;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
