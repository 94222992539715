import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDominio, clearDominio } from "../actions";
import TextField from "@mui/material/TextField";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import pxToRem from "~libs/pxToRem";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

import { Typography, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
  },
  fieldAndCameraContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 32,
  },
  fieldContainer: {
    width: "90%",
  },
  camera: {
    float: "right",
    height: "42px !important",
    width: "42px !important",
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.38,
    color: "#616161",
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: pxToRem(24),
  },
}));

const disableCode = true;

const DominioStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dominio, setCampoDominio] = useState("");

  const canContinue = dominio.length > 0 && validateDominio(dominio);

  useEffect(() => {
    dispatch(clearDominio());
  }, [dispatch]);

  const handleNext = (value) => {
    dispatch(setDominio({ value, selected: true }));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) handleNext(dominio);
  };

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={"Ingresá el dominio del vehículo "}
        marginBottom={60}
      />

      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
        type="email"
        value={dominio}
        onChange={(event) => {
          if (event.target.value.length <= 7)
            setCampoDominio(event.target.value.toUpperCase());
        }}
        placeholder="Ej: AA000AA"
      ></TextField>
      <div style={{ display: "flex", justifyConent: "space-around" }}>
        <Typography className={classes.secondaryText}>
          <Link
            className={classes.secondaryText}
            style={{ marginLeft: 5, marginTop: 5 }}
            component="button"
            underline="always"
            onClick={() => {
              handleNext("");
            }}
          >
            Omitir este paso
          </Link>
        </Typography>
      </div>

      {!disableCode && (
        <div className={classes.infoContainer}>
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>

          <Typography className={classes.text}>
            Recorda que completando todos los pasos podras descargar generar el
            certificado.
          </Typography>
        </div>
      )}
      <div style={{ paddingTop: pxToRem(24) }}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            handleNext(dominio);
          }}
        />
      </div>
    </MulticotizadorContent>
  );
};

export default DominioStep;

const validateDominio = (value) => {
  const expression = RegExp(
    "^[a-zA-Z]{2,2}[0-9]{3,3}[a-zA-Z]{2,2}$|^[a-zA-Z]{3,3}[0-9]{3,3}$"
  );
  const result = expression.test(value);
  return result && value.length <= 7;
};
