import React, { useState, Fragment, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import citroen from "~images/vehiculoMarcaSelector/citroen.svg";
import fiat from "~images/vehiculoMarcaSelector/fiat.svg";
import ford from "~images/vehiculoMarcaSelector/ford.svg";
import honda from "~images/vehiculoMarcaSelector/honda.svg";
import peugeot from "~images/vehiculoMarcaSelector/peugeot.svg";
import renault from "~images/vehiculoMarcaSelector/renault.svg";
import toyota from "~images/vehiculoMarcaSelector/toyota.svg";
import vw from "~images/vehiculoMarcaSelector/volkswagen.svg";
import chevrolet from "~images/vehiculoMarcaSelector/chevrolet.svg";
import filterItems from "~libs/filterItems";
import { useSelector, useDispatch } from "react-redux";
import { fetchVehiculosMarcas } from "./actions";
import Typography from "@mui/material/Typography";
import ListView from "~styled/listView/ListView";
import SearcherList from "~styled/searcherList/SearcherList";
import ContentLoader from "react-content-loader";

const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around",
  },
  brandSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  brandRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonContent: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    "@media (min-width:1024px)": {
      height: "100%",
    },
  },
  brandItemContainer: {
    flex: 1,
  },
  button: {
    marginTop: 10,
    width: "30%",
    height: 105,
    padding: 0,
    borderRadius: 8,
    background: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    marginBottom: "5%",
    "&:hover": {
      backgroundColor: "#d5d5d5",
    },
    "@media (min-width:1024px)": {
      minWidth: "140px",
      minHeight: "140px",
    },
  },
  brandItemLabel: {
    marginBottom: -1,
    borderRadius: "0 0 8px 8px",
    lineHeight: "35px",
    color: theme.colours.brownGrey,
    background: "#e4e4e6",
    fontSize: 13,
    textTransform: "lowercase",
    "&:first-letter": { textTransform: "uppercase" },
  },
  iconContainer: {
    justifyContent: "center",
    height: "71%",
    "&:hover": {
      backgroundColor: "#d5d5d5",
    },
    "@media (min-width:1024px)": {
      display: "flex",
      justifyContent: "center",
      height: "81%",
      minHeight: "105px",
      alignItems: "center",
    },
  },
  brandIcon: {
    objectFit: "contain",
    padding: 2,
    paddingTop: 10,
    paddingBottom: 10,
    width: 70,
    height: 70,
    marginTop: 4,
    marginBottom: -4,
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    marcas: state.vehiculoMarcasReducer.marcas,
    isLoading: state.vehiculoMarcasReducer.isLoading,
  }));

const VehiculoMarcaSelector = memo(
  ({ value = { label: null, value: null }, onChange }) => {
    const { marcas, isLoading } = useReduxSelector();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchVehiculosMarcas());
    }, [dispatch]);

    const [filter, setFilter] = useState("");

    const handleBrandItem = (value) => {
      setFilter("");
      if (onChange) onChange({ target: { value } });
    };

    const handleFilterChange = (event) => {
      setFilter(event.target.value);
    };

    const classes = useStyles();

    return (
      <Fragment>
        {!value.label && (
          <div className={classes.searcher}>
            <SearcherList
              onClear={() => setFilter("")}
              filter={filter}
              onChange={handleFilterChange}
              placeholder={"Buscar marca"}
              borderBottomRounded={true}
            />
            {filter === "" ? (
              <div style={{ paddingTop: 28 }}>
                <Typography
                  variant="overline"
                  display="block"
                  style={{ color: "#a7a7a7" }}
                >
                  MARCAS DESTACADAS
                </Typography>
                <div className={classes.brandSection}>
                  <div className={classes.brandRow}>
                    <BrandItem
                      icon={chevrolet}
                      value={{ value: "CHEVROLET", label: "CHEVROLET" }}
                      onClick={handleBrandItem}
                      isLoading={isLoading}
                    />
                    <BrandItem
                      icon={citroen}
                      value={{ value: "CITROEN", label: "CITROEN" }}
                      onClick={handleBrandItem}
                      isLoading={isLoading}
                    />
                    <BrandItem
                      icon={fiat}
                      value={{ value: "FIAT", label: "FIAT" }}
                      onClick={handleBrandItem}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className={classes.brandRow}>
                    <BrandItem
                      icon={renault}
                      value={{ value: "RENAULT", label: "RENAULT" }}
                      onClick={handleBrandItem}
                      isLoading={isLoading}
                    />
                    <BrandItem
                      icon={vw}
                      value={{ value: "VOLKSWAGEN", label: "VOLKSWAGEN" }}
                      onClick={handleBrandItem}
                      isLoading={isLoading}
                    />
                    <BrandItem
                      icon={peugeot}
                      value={{ value: "PEUGEOT", label: "PEUGEOT" }}
                      onClick={handleBrandItem}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className={classes.brandRow}>
                    <BrandItem
                      icon={ford}
                      value={{ value: "FORD", label: "FORD" }}
                      onClick={handleBrandItem}
                      isLoading={isLoading}
                    />
                    <BrandItem
                      icon={toyota}
                      value={{ value: "TOYOTA", label: "TOYOTA" }}
                      onClick={handleBrandItem}
                      isLoading={isLoading}
                    />
                    <BrandItem
                      icon={honda}
                      value={{ value: "HONDA", label: "HONDA" }}
                      onClick={handleBrandItem}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <ListView
                  isLoading={isLoading}
                  onItemSelected={handleBrandItem}
                  list={filterItems(marcas, filter)}
                />
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }
);

export default VehiculoMarcaSelector;

const BrandItem = ({ isLoading, icon, value, onClick }) => {
  const classes = useStyles();

  if (isLoading)
    return (
      <div className={classes.button}>
        <div className={classes.buttonContent}>
          <div className={classes.iconContainer}>
            <ContentLoader
              height={"80%"}
              width={"80%"}
              speed={1}
              backgroundColor="#f7f8ff"
              foregroundColor="#d9e8df"
            >
              <rect x="12" y="12" rx="4" ry="4" width="80%" height="80%" />
            </ContentLoader>
          </div>
          <div style={{ height: "54px" }} className={classes.brandItemLabel} />
        </div>
      </div>
    );

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={() => onClick(value)}
    >
      <div className={classes.buttonContent}>
        <div className={classes.iconContainer}>
          <img src={icon} className={classes.brandIcon} alt="" />
        </div>
        <Typography
          color="textPrimary"
          component={"div"}
          className={classes.brandItemLabel}
        >
          {value.label ? value.label : ""}
        </Typography>
      </div>
    </Button>
  );
};
