import React, { useState, useEffect } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { enGB } from "date-fns/locale";

const CustomDatePicker = ({
  fullWidth,
  onChange,
  onKeyDown,
  value,
  minDate,
  maxDate,
  className,
  label,
  id,
  error,
  onBlur,
  reference,
}) => {
  const [selectedDate, setSelectedDate] = useState(value);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) onChange(date);
  };

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DatePicker
        fullWidth={fullWidth}
        disableToolbar
        inputFormat="dd/MM/yyyy"
        margin="normal"
        id={id}
        label={label}
        value={selectedDate}
        sx={{
          ".MuiInputBase-root": {
            backgroundColor: "#fff",
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderColor: "gray",
            borderBottom: "1px solid gray",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderBottom: "3px solid black",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
            borderBottom: "1px solid #6240a5",
          },
          ".MuiInputLabel-root": {
            color: "#6B7280",
            fontWeight: "500",
          },
        }}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleDateChange}
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={error ? "Formato de fecha incorrecto" : ""}
            onKeyDown={onKeyDown}
            inputRef={reference}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
