import React from "react";
import access_denied from "~images/auth/access_denied.svg";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import LinkButton from "../linkButton/LinkButton";
import { useAuth0 } from "../../Auth0/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    maxWidth: 600,
  },
  title: {
    color: "#45459c",
    fontSize: 28,
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 800,
    lineHeight: 2.11,
    fontStretch: "normal",
    letterSpacing: "normal",
    paddingBottom: 30,
  },
  subtitle: {
    color: "#434343",
    fontSize: 22,
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 500,
    lineHeight: 1.56,
    fontStretch: "normal",
    letterSpacing: "normal",
    paddingBottom: 30,
  },
}));
const InvalidUser = () => {
  const classes = useStyles();
  const { logout } = useAuth0();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography color="textPrimary" className={classes.title}>
          {"¡Lo sentimos!"}
        </Typography>
        <Typography color="textPrimary" className={classes.subtitle}>
          {"No tenes permisos para"}
          <br /> {"entrar a este sitio"}
        </Typography>
        <img style={{ width: "50%" }} alt="" src={access_denied} />
        <div style={{ padding: 30 }}>
          <LinkButton
            label={"Ingresá con otra cuenta"}
            onClick={() => logout()}
          />
        </div>
      </div>
    </div>
  );
};
export default InvalidUser;
