import React from "react";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";
import AssignmentIcon from "@mui/icons-material/AssignmentIndSharp";
import { makeStyles } from "@mui/styles";
import { estadisticasPath } from "~constants/referenceNames";
import TiempoCard from "./TiempoCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    alignItems: "right",
    width: "100%",
  },
  tableContainer: {
    padding: "5vh",
  },
  table: {
    backgroundColor: theme.colours.white,
    borderRadius: "8px 8px 8px 8px",
    boxShadow: "7px 8px 24px -6px #CCCCE4",
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonFilter: {
    //display: "flex",
    width: "10vw",
    height: "5vh",
    borderRadius: "4px",
  },
}));

const EstadisticasContainer = () => {
  const classes = useStyles();

  return (
    <SectionLayout
      Icon={AssignmentIcon}
      title={"Estadisticas"}
      selectedDefault={estadisticasPath}
    >
      <div className={classes.root}>
        <div className={classes.body}>{<TiempoCard />}</div>
      </div>
    </SectionLayout>
  );
};

export default EstadisticasContainer;
