import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableLoading from "~styled/tableLoading/TableLoading";
import { Tooltip, Button } from "@mui/material";
import PopupComentario from "./PopupComentario";
import { useParams } from "react-router-dom";
import SuccessDialog from "./SuccessDialog";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

import { postSiniestroComentario } from "./actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "3.5%",
    minWidth: "10px",
  },
  column: {
    color: "hsl(263, 55%, 53%)",
    fontWeight: "bold",
  },
  btnComentario: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    paddingBottom: "10px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => {
    const currentSiniestro = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro
      : {};
    return {
      ...currentSiniestro,
      isLoading: state.detalleSiniestroReducer.isLoading,
      comentarioNuevo: state.detalleSiniestroReducer.comentarioNuevo,
    };
  });

const Comentarios = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { comentarios, isLoading, comentarioNuevo } = useReduxSelector();
  const { id } = useParams();
  const [isPopupComentarioOpened, setIsPopupComentarioOpened] = useState(false);

  const handleComentario = (siniestroId, comentario) => {
    setIsPopupComentarioOpened(false);
    dispatch(postSiniestroComentario(siniestroId, comentario));
  };

  const handleClosePopupComentario = () => {
    setIsPopupComentarioOpened(false);
  };

  return (
    <div className={classes.root}>
      {!isLoading && isPopupComentarioOpened !== null && (
        <div>
          <PopupComentario
            isOpened={isPopupComentarioOpened}
            siniestroId={id}
            onClickAceptar={handleComentario}
            onClose={handleClosePopupComentario}
          />
          <SuccessDialog
            openDialog={comentarioNuevo}
            value={"Comentario agregado con éxito"}
          />
        </div>
      )}
      {isLoading && <TableLoading length={5} />}
      {!isLoading && (
        <>
          <div className={classes.btnComentario}>
            <Tooltip title={"Agregar Comentario"}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsPopupComentarioOpened(true)}
                >
                  {"AGREGAR COMENTARIO"}
                </Button>
              </span>
            </Tooltip>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.column}>Fecha</TableCell>
                  <TableCell className={classes.column}>Usuario</TableCell>
                  <TableCell className={classes.column}>Comentario</TableCell>
                </TableRow>
              </TableHead>
              {(comentarios?.length ?? 0) === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>Sin comentarios</TableCell>
                  </TableRow>
                </TableBody>
              )}
              {comentarios &&
                comentarios.map((comentario) => (
                  <TableBody>
                    <TableRow hover style={{ cursor: "default" }}>
                      <TableCell>
                        {comentario.fechaPublicacion &&
                          format(
                            Date.parse(comentario.fechaPublicacion),
                            "dd'/'MM'/'yyyy HH:mm 'HS'",
                            {
                              locale: es,
                            }
                          )}
                      </TableCell>
                      <TableCell>{comentario.usuarioEmail}</TableCell>
                      <TableCell style={{ width: "50%" }}>
                        {comentario.contenidoComentario}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default Comentarios;
