import React, { useState, memo } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import SearcherList from "~styled/searcherList/SearcherList";
import ListView from "~styled/listView/ListView";
import UsuariosInputCard from "./UsuariosInputCard";

import { useDispatch } from "react-redux";
import { getUsuarios } from "../actions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    justifyContent: "space-between",
    padding: "2%",
    paddingTop: "3%",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: "0px",
    borderBottom: "1px solid #8B8B8B",
  },
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "260px",
    justifyContent: "space-around",
    justifySelf: "end",
    marginRight: "10px",
    position: "relative",
  },

  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
  caption: {
    justifyContent: "center",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  listView: {
    position: "absolute",
    top: "85%",
    width: "260px",
    zIndex: "2",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    usuarios: state.altaPolizaMultiramaReducer.usuariosFilter,
    usuario: state.altaPolizaMultiramaReducer.usuarioSelected,
    take: state.altaPolizaMultiramaReducer.take,
  }));

const UsuariosSelector = memo(({ value, onChange, onClear, isLoading }) => {
  const [filter, setFilter] = useState("");
  const [listView, setListView] = useState(false);
  const dispatch = useDispatch();

  const { usuarios, usuario, take } = useReduxSelector();

  const handleUsuarioItem = (value) => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    if (event.target.value && event.target.value.length >= 2)
      dispatch(getUsuarios(0, take, event.target.value));
  };

  const classes = useStyles();

  return (
    <>
      {!value.label ? (
        <div
          className={classes.container}
          onClick={() => setListView(!listView)}
        >
          <Typography className={classes.caption}>{"Usuario"}</Typography>

          <div className={classes.searcher}>
            <SearcherList
              onClear={() => setFilter("")}
              filter={filter}
              onChange={handleFilterChange}
              placeholder={"Ingresá 2+ letras para buscar"}
            />
            {listView && (
              <div className={classes.listView}>
                <ListView
                  isLoading={isLoading}
                  onItemSelected={handleUsuarioItem}
                  list={usuarios}
                  usePagination={false}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <UsuariosInputCard
            id={"usuarios"}
            isLoading={isLoading}
            label={"Usuario"}
            value={usuario.label}
            variant="big"
            onClick={onClear}
          />
        </div>
      )}
    </>
  );
});

export default UsuariosSelector;
