import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

import DetalleCard from "~business/Cards/DetalleCard.js";

import PhotoIcon from "@mui/icons-material/Photo";
import { setDocumentosFileName, setIsProcessingDocumentos } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import ItemMultipleFileUploaderCard from "~business/Cards/ItemMultipleFileUploaderCard";

const useStyles = makeStyles((theme) => ({
  impresosDisabled: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
    paddingTop: "5%",
  },
}));

const title = "Documentación";

const useReduxSelector = () =>
  useSelector((state) => {
    return { documentos: state.cargaSiniestroReducer.documentosSelected };
  });

const DocumentacionCard = ({ isLoading, disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { documentos } = useReduxSelector();

  if (isLoading)
    return (
      <DetalleCard isLoading={isLoading} title={title} IconHeader={PhotoIcon} />
    );

  const handleAdded = (event) => {
    const value = event.target.value.map((doc) => `temp/${doc}`);
    dispatch(setDocumentosFileName(value));
  };

  const handleRemoved = (event) => {
    const value = `temp/${event.target.value}`;
    const fotos = documentos.filter((doc) => doc !== value);
    dispatch(setDocumentosFileName(fotos));
  };

  const handleProcessingDocumentos = (value) => {
    dispatch(setIsProcessingDocumentos(value));
  };

  return (
    <DetalleCard isLoading={isLoading} title={title} IconHeader={PhotoIcon}>
      {disabled && (
        <Typography className={classes.impresosDisabled}>
          {"Se requiere ingresar Número de Póliza para cargar Documentación"}
        </Typography>
      )}
      {!disabled && (
        <>
          <ItemMultipleFileUploaderCard
            label={"Documentos"}
            onAdded={(event) => handleAdded(event)}
            onRemoved={(event) => handleRemoved(event)}
            onProcessStart={handleProcessingDocumentos}
            acceptImage={true}
            acceptPdf={false}
            multiple={true}
          />
        </>
      )}
    </DetalleCard>
  );
};

export default DocumentacionCard;
