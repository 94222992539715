import React from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      //   thousandSeparator={"."}
      //   decimalSeparator={","}
      isNumericString
      //   prefix="$"
      decimalScale={0}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const IntegerInputField = ({
  onChange,
  value,
  className,
  label,
  inputProps,
  id,
  required,
  hasError,
  helperText,
  reference,
  onBlur,
  onKeyDown,
  variant,
  placeholder,
}) => {
  const classes = useStyles();

  const handleChange = (val) => {
    if (onChange) onChange(val);
  };

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  return (
    <div className={classes.container}>
      <TextField
        className={classes.container}
        label={label}
        value={value}
        onChange={(event) => handleChange(event)}
        onBlur={handleBlur}
        id={id}
        InputProps={{
          inputComponent: NumberFormatCustom,
          inputProps,
        }}
        variant={variant}
        error={hasError}
        helperText={helperText}
        inputRef={reference}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default IntegerInputField;
