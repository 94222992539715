import React, { useState, memo } from "react";
import { makeStyles } from "@mui/styles";
import filterItems from "~libs/filterItems";
import getAnios from "./js/getAnios";
import SearcherList from "~styled/searcherList/SearcherList";
import ListView from "~styled/listView/ListView";

const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 10,
    justifyContent: "space-around",
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
  },
}));

const aniosList = getAnios();

const VehiculoAnioSelector = memo(({ onChange }) => {
  const [filter, setFilter] = useState("");

  const handleItem = (item) => {
    setFilter("");
    if (onChange) onChange({ target: { value: item.value } });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.searcher}>
        <SearcherList
          onClear={() => setFilter("")}
          filter={filter}
          onChange={handleFilterChange}
          placeholder={"Buscar año"}
        />
        <ListView
          onItemSelected={handleItem}
          list={filter === "" ? aniosList : filterItems(aniosList, filter)}
        />
      </div>
    </>
  );
});

export default VehiculoAnioSelector;
