import Select from "@mui/material/Select";
import { withStyles } from "@mui/styles";
import pxToRem from "~libs/pxToRem";

const StyledSelect = withStyles((theme) => ({
  select: {
    fontSize: pxToRem(18),
  },
}))(Select);

export default StyledSelect;
