import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import DatePicker from "~styled/datePicker/DatePicker";
import CurrencyInputField from "~styled/currencyInputField/CurrencyInputField";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NumberFormat from "react-number-format";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2%",
    borderBottom: "1px solid #6240a5",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    alignItems: "stretch",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
    paddingRight: 10,
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    padding: "4%",
    margin: "2%",
  },
  cardIcon: {
    color: "#6240a5",
    marginRight: "1%",
  },
  cardTitle: {
    fontFamily: "Nunito",
    fontSize: "1.3em",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6240a5",
  },
  cardBody: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  index: {
    paddingTop: "5%",
  },
  datePicker: {
    width: "40%",
    display: "flex",
  },
  currency: {
    display: "flex",
    width: "40%",
    padding: "2%",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },

  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
}));

const ENTER_KEY = 13;
const errorText = "Requerido";

const ItemCuotaCard = ({
  index,
  cuota,
  onChange,
  onPressEnter,
  displayError,
  focus,
  onBlur,
  readOnly,
}) => {
  const classes = useStyles();

  const [isImporteValid, setIsImporteValid] = useState(true);
  const [isFechaVtoValid, setIsFechaVtoValid] = useState(true);

  const importeReference = useRef();
  const fechaVtoReference = useRef();

  useEffect(() => {
    if (focus) {
      if (!isFechaVtoValid) fechaVtoReference.current.focus();
      if (!isImporteValid) importeReference.current.focus();
    }
    //eslint-disable-next-line
  }, [focus]);

  const onKeyDown = (event) => {
    if (onPressEnter && event.keyCode === ENTER_KEY && cuota.importe.value)
      onPressEnter(cuota);
  };

  const validate = (data) => {
    return data && data !== "";
  };

  const handleFechaChange = (val) => {
    let valid = validate(val);

    valid = valid && moment(val).isValid();
    setIsFechaVtoValid(valid);

    if (onChange)
      onChange({
        ...cuota,
        fechaVencimiento: { value: val, valid: valid, focus: false },
      });
  };

  const handleImporteChange = (event) => {
    let val = event.target.value;
    let valid = validate(val);

    setIsImporteValid(valid);

    if (onChange)
      onChange({
        ...cuota,
        importe: { value: val, valid: valid, focus: false },
      });
  };

  const handleBlur = () => {
    if (onBlur) onBlur(cuota);
  };

  const showErrorOnImporte = !isImporteValid && displayError;
  const showErrorOnFechaVto = !isFechaVtoValid && displayError;

  return (
    <div className={classes.cardBody}>
      <div className={classes.index}>
        <Typography className={classes.cardTitle}>{`${index}°`}</Typography>
      </div>
      <div className={classes.datePicker}>
        {!readOnly && (
          <DatePicker
            label={"Vencimiento"}
            onChange={handleFechaChange}
            value={cuota.fechaVencimiento.value}
            id={`fechaVto_${index}`}
            error={showErrorOnFechaVto}
            onBlur={handleBlur}
            reference={fechaVtoReference}
          />
        )}
        {readOnly && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <Typography className={classes.caption}>{"Vencimiento"}</Typography>
            <Typography className={classes.value}>
              {getValueParsed(cuota.fechaVencimiento.value)}
            </Typography>
          </div>
        )}
        {showErrorOnFechaVto && (
          <Tooltip title={errorText}>
            <div
              className={classes.buttonLink}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#f77221",
                paddingLeft: 4,
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon style={{ width: 30, height: 30 }} />
            </div>
          </Tooltip>
        )}
      </div>
      <div className={classes.currency}>
        {!readOnly && (
          <CurrencyInputField
            label={"Premio"}
            onChange={handleImporteChange}
            onKeyDown={onKeyDown}
            value={cuota.importe.value}
            id={`monto_${index}`}
            hasError={showErrorOnImporte}
            onBlur={handleBlur}
            reference={importeReference}
          />
        )}
        {readOnly && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <Typography className={classes.caption}>{"Premio"}</Typography>
            <Typography className={classes.value}>
              {getCurrencyParsed(cuota.importe.value)}
            </Typography>
          </div>
        )}
        {showErrorOnImporte && (
          <Tooltip title={errorText}>
            <div
              className={classes.buttonLink}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#f77221",
                paddingLeft: 4,
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon style={{ width: 30, height: 30 }} />
            </div>
          </Tooltip>
        )}
      </div>
      <div>
        {readOnly && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <Typography className={classes.caption}>{"Vigencia"}</Typography>
            <Typography className={classes.value} style={{ textAlign: "left" }}>
              {getValueParsed(cuota.vigenciaDesde.value)} a{" "}
              {getValueParsed(cuota.vigenciaHasta.value)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemCuotaCard;

const getValueParsed = (value, format = "DD/MM/YYYY") => {
  if (!value) return <span>&mdash;</span>;

  const extendedFormat = format === "DD/MM/YYYY" ? "" : " HS";
  return moment(value).local().format(format) + extendedFormat;
};

const getCurrencyParsed = (value) => {
  if (!value) return <span>&mdash;</span>;

  return (
    <NumberFormat
      value={value}
      displayType={"text"}
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator={"."}
      decimalSeparator={","}
      prefix={"$ "}
    />
  );
};
