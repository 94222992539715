import React from "react";
import { productores } from "~constants/productores";
import ColorLabel from "../colorLabel/ColorLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: { paddingLeft: "10px" },
}));

const ColorLabelProductor = ({ productor }) => {
  const classes = useStyles();

  const productorIsValid = () => {
    return productor in productores;
  };

  return (
    <span className={classes.container}>
      {productorIsValid() ? (
        <ColorLabel variant={"success"} text={productores[productor]} />
      ) : (
        <ColorLabel text={"Indeterminado"} />
      )}
    </span>
  );
};

export default ColorLabelProductor;
