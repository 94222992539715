import React, { useEffect, useState } from "react";
import { getSolicitudesEmision, getOficinas } from "./actions";
import { useSelector, useDispatch } from "react-redux";
import ListadoSolicitudesEmision from "./ListadoSolicitudesEmision";
import AssignmentIcon from "@mui/icons-material/AssignmentIndSharp";
import { solicitudesEmisionPath } from "~constants/referenceNames";
import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";
import { useHistory, useLocation } from "react-router-dom";
import {
  setTake,
  setPage,
  selectFechaDesde,
  selectFechaHasta,
  selectEstado,
  selectNegocio,
  selectOficina,
  selectSearchCriteria,
} from "./Filtros/actions";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";

const useReduxSelector = () =>
  useSelector((state) => ({
    take: state.solicitudesEmisionFiltrosReducer.take,
    page: state.solicitudesEmisionFiltrosReducer.page,
    fechaDesde: state.solicitudesEmisionFiltrosReducer.fechaDesde,
    fechaHasta: state.solicitudesEmisionFiltrosReducer.fechaHasta,
    searchCriteria: state.solicitudesEmisionFiltrosReducer.searchCriteria,
    estado: state.solicitudesEmisionFiltrosReducer.estado,
    negocio: state.solicitudesEmisionFiltrosReducer.negocio,
    oficina: state.solicitudesEmisionFiltrosReducer.oficina,
    isAdminUser: state.authenticatedViewReducer.isAdminUser,
  }));

const params = [
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
  {
    name: "estado",
    nameUrl: "Estado",
    action: selectEstado,
  },
  {
    name: "negocio",
    nameUrl: "Negocio",
    action: selectNegocio,
  },
  {
    name: "oficina",
    nameUrl: "Oficina",
    action: selectOficina,
  },
  {
    name: "fechaDesde",
    nameUrl: "Desde",
    action: selectFechaDesde,
    isDate: true,
  },
  {
    name: "fechaHasta",
    nameUrl: "Hasta",
    action: selectFechaHasta,
    isDate: true,
  },
  { name: "take", nameUrl: "Take", action: setTake },
];

const SolicitudesEmisionContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentState = useReduxSelector();

  const {
    take,
    page,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    estado,
    negocio,
    oficina,
    isAdminUser,
  } = currentState;

  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: solicitudesEmisionPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [
    dispatch,
    take,
    page,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    estado,
    negocio,
    oficina,
  ]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (url) {
      dispatch(
        getSolicitudesEmision(
          "solicitud",
          page * take,
          take,
          searchCriteria,
          fechaDesde,
          fechaHasta,
          estado,
          negocio,
          oficina
        )
      );
    }
    //eslint-disable-next-line
  }, [dispatch, url]);

  useEffect(() => {
    if (isAdminUser) dispatch(getOficinas());
    //eslint-disable-next-line
  }, [isAdminUser]);

  return (
    <>
      <SectionLayout
        Icon={AssignmentIcon}
        title={"Solicitudes Pendientes"}
        selectedDefault={solicitudesEmisionPath}
      >
        <ListadoSolicitudesEmision />
      </SectionLayout>
    </>
  );
};

export default SolicitudesEmisionContainer;
