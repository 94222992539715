import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";

import PersonIcon from "@mui/icons-material/Person";
import { useSelector, useDispatch } from "react-redux";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemEmailCard from "~business/Cards/ItemEmailCard";
import ItemDniCard from "~business/Cards/ItemDniCard";
import ItemDateCard from "~business/Cards/ItemDateCard";
import { DATOS } from "~constants/endosos";
import { setEmail, setTelefono, setDomicilio } from "../../actions";

const useReduxSelector = () =>
  useSelector((state) => {
    const detalle = state.detallePolizaNuevoReducer;
    const currentPoliza = detalle?.currentPoliza;

    return {
      nombreCompleto: currentPoliza?.asegurado.nombreCompleto,
      numeroDocumento: currentPoliza?.asegurado.numeroDocumento,
      fechaNacimiento: currentPoliza?.asegurado.fechaNacimiento,
      domicilio: detalle.domicilioSelected,
      email: detalle.emailSelected,
      telefono: detalle.telefonoSelected,
    };
  });

const AseguradoCard = ({ isLoading, onBlur, displayError, tipo }) => {
  const dispatch = useDispatch();
  const {
    nombreCompleto,
    numeroDocumento,
    fechaNacimiento,
    domicilio,
    email,
    telefono,
  } = useReduxSelector();

  let timer = null;

  const handleChangeEmail = (value) => {
    clearTimeout(timer);
    timer = setTimeout(() => dispatch(setEmail(value)), 200);
  };

  const handleChangeTelefono = (value) => {
    clearTimeout(timer);
    timer = setTimeout(() => dispatch(setTelefono(value)), 200);
  };

  const handleChangeDireccion = (value) => {
    clearTimeout(timer);
    timer = setTimeout(() => dispatch(setDomicilio(value)), 200);
  };

  return (
    <DetalleCard title={"Asegurado"} IconHeader={PersonIcon}>
      <ItemInputCard
        isLoading={isLoading}
        label="Nombre"
        value={nombreCompleto}
        readOnly
      />
      <ItemEmailCard
        id="email"
        label="E-mail"
        isLoading={isLoading}
        value={email.value}
        onChange={handleChangeEmail}
        placeholder="micorreo@mail.com"
        focus={email.focus}
        onBlur={onBlur}
        displayError={displayError}
        readOnly={tipo !== DATOS}
        required
      />
      <ItemInputCard
        label={"Teléfono"}
        isLoading={isLoading}
        value={telefono.value}
        onChange={handleChangeTelefono}
        onBlur={onBlur}
        focus={telefono.focus}
        displayError={displayError}
        readOnly={tipo !== DATOS}
        required
      />
      <ItemDniCard
        isLoading={isLoading}
        label={"DNI"}
        value={numeroDocumento}
        readOnly
      />
      <ItemDateCard
        isLoading={isLoading}
        label={"Fecha Nacimiento"}
        value={fechaNacimiento}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Domicilio"}
        value={domicilio.value}
        onBlur={onBlur}
        displayError={displayError}
        onChange={handleChangeDireccion}
        focus={domicilio.focus}
        readOnly={tipo !== DATOS}
        required
      />
    </DetalleCard>
  );
};

export default AseguradoCard;
