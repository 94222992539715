import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import AssignmentIcon from "@mui/icons-material/AssignmentIndSharp";
import { novedadesPath } from "~constants/referenceNames";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import ListadoNovedades from "./listadoNovedades/ListadoNovedades";
import { getNovedades } from "./actions";
import {
  setTake,
  setPage,
  selectSearchCriteria,
  selectFechaNovedadDesde,
  selectFechaNovedadHasta,
  selectInicioVigenciaDesde,
  selectInicioVigenciaHasta,
  selectNegocio,
  selectCompania,
  selectTipoNovedad,
} from "./Filtros/actions";
import { useLocation, useHistory } from "react-router-dom";
import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    alignItems: "right",
    width: "100%",
  },
  tableContainer: {
    padding: "5vh",
  },
  table: {
    backgroundColor: theme.colours.white,
    borderRadius: "8px 8px 8px 8px",
    boxShadow: "7px 8px 24px -6px #CCCCE4",
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonFilter: {
    //display: "flex",
    width: "10vw",
    height: "5vh",
    borderRadius: "4px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    failed: state.novedadesReducer.failed,

    take: state.filtrosNovedadesReducer.take,
    page: state.filtrosNovedadesReducer.page,
    inicioVigenciaDesde: state.filtrosNovedadesReducer.inicioVigenciaDesde,
    inicioVigenciaHasta: state.filtrosNovedadesReducer.inicioVigenciaHasta,
    fechaNovedadDesde: state.filtrosNovedadesReducer.fechaNovedadDesde,
    fechaNovedadHasta: state.filtrosNovedadesReducer.fechaNovedadHasta,
    searchCriteria: state.filtrosNovedadesReducer.searchCriteria,
    negocio: state.filtrosNovedadesReducer.negocio,
    compania: state.filtrosNovedadesReducer.compania,
    tipoNovedad: state.filtrosNovedadesReducer.tipoNovedad,
  }));

const params = [
  { name: "negocio", nameUrl: "Negocio", action: selectNegocio },
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
  { name: "compania", nameUrl: "Compania", action: selectCompania },
  { name: "tipoNovedad", nameUrl: "TipoNovedad", action: selectTipoNovedad },
  {
    name: "fechaNovedadDesde",
    nameUrl: "FechaNovedadDesde",
    action: selectFechaNovedadDesde,
    isDate: true,
  },
  {
    name: "fechaNovedadHasta",
    nameUrl: "FechaNovedadHasta",
    action: selectFechaNovedadHasta,
    isDate: true,
  },
  {
    name: "inicioVigenciaDesde",
    nameUrl: "InicioVigenciaDesde",
    action: selectInicioVigenciaDesde,
    isDate: true,
  },
  {
    name: "inicioVigenciaHasta",
    nameUrl: "InicioVigenciaHasta",
    action: selectInicioVigenciaHasta,
    isDate: true,
  },
  { name: "take", nameUrl: "Take", action: setTake },
];

const NovedadesContainer = () => {
  const currentState = useReduxSelector();
  const {
    page,
    take,

    failed,

    fechaNovedadDesde,
    fechaNovedadHasta,
    inicioVigenciaDesde,
    inicioVigenciaHasta,
    searchCriteria,
    negocio,
    compania,
    tipoNovedad,
  } = currentState;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: novedadesPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [
    history,
    fechaNovedadDesde,
    fechaNovedadHasta,
    inicioVigenciaDesde,
    inicioVigenciaHasta,
    searchCriteria,
    negocio,
    compania,
    tipoNovedad,
    page,
    take,
  ]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (url) {
      dispatch(
        getNovedades({
          skip: take * page,
          take,
          fechaNovedadDesde,
          fechaNovedadHasta,
          inicioVigenciaDesde,
          inicioVigenciaHasta,
          searchCriteria,
          compania,
          negocio,
          tipoNovedad,
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, url]);

  const classes = useStyles();

  if (failed) return <UnexpectedError />;

  return (
    <SectionLayout
      Icon={AssignmentIcon}
      title={"Novedades"}
      selectedDefault={novedadesPath}
    >
      <div className={classes.root}>
        <div className={classes.body}>
          <ListadoNovedades />
        </div>
      </div>
    </SectionLayout>
  );
};

export default NovedadesContainer;
