import { createActions, handleActions } from "redux-actions";

const fetchAgencias = () => {
  return async (dispatch, getState, services) => {
    try {
      dispatch(fetchAgenciasInited({ }));
      const response = await services.api.agencias().getAgencias();
      dispatch(fetchAgenciasCompleted(response));
    } catch (error) {
      console.error(error);
      dispatch(fetchAgenciasFailed(error));
    }
  };
};

const {
  agenciasReducer: {
    clear,
    fetchAgenciasInited,
    fetchAgenciasCompleted,
    fetchAgenciasFailed
  }
} = createActions({
  agenciasReducer: {
    clear: () => ({}),
    setId: value => ({ value }),
    setNombre: value => ({ value }),
    fetchAgenciasInited: () => ({}),
    fetchAgenciasCompleted: response => {
      let agencias = [];

      if (typeof response !== "undefined" && response !== null && response.length !== 0) 
      {
        agencias = response.items.map(x => ({
          value: x.id,
          label: x.nombre
        }));
      }

      return { agencias };
    },
    fetchAgenciasFailed: error => ({ error })
  }
});

const initialState = { isLoading: false, agencias: [] };

export const agenciasReducer = handleActions(
  {
    [clear]: (state, action) => {
      return { ...initialState };
    },
    [fetchAgenciasInited]: (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    },
    [fetchAgenciasCompleted]: (state, action) => {
      return {
        ...state,
        agencias: [...action.payload.agencias],
        isLoading: false
      };
    },
    [fetchAgenciasFailed]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    }
  },
  initialState
);

export default agenciasReducer;
export { clear, fetchAgencias };
