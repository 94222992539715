import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import pxToRem from "~libs/pxToRem";
import CardCuota from "~styled/cardCuota/CardCuota";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      paddingTop: "4.5%",
      flexDirection: "column",
      alignItems: "center",
    },
    body: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
    },
    cardsAndCuotasContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      "@media (max-width:1024px)": {
        width: "100%",
        flexWrap: "wrap",
      },
    },
    cards: {
      display: "flex",
      paddingBottom: "30px",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "50%",
      "@media (max-width:1024px)": {
        width: "100%",
        flexWrap: "wrap",
      },
    },
  };
});

const useReduxSelector = () =>
  useSelector((state) => ({
    currentNovedad: state.detalleNovedadReducer.currentNovedad,
  }));

const Cuotas = () => {
  const classes = useStyles();
  const { currentNovedad } = useReduxSelector();

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.cardsAndCuotasContainer}>
          <CardsColumn>
            <div className={classes.cuotasList}>
              <Typography noWrap className={classes.cuotasTitle}>
                {"CUOTAS"}
              </Typography>
              <div style={{ padding: 4 }}>
                <Divider
                  style={{
                    color: "#bdbdbd",
                    marginTop: pxToRem(6),
                    marginBottom: pxToRem(24),
                  }}
                />
              </div>

              {currentNovedad &&
                currentNovedad.cuotas &&
                currentNovedad.cuotas.map((cuota) => (
                  <CardCuota
                    key={"cuota-novedad-" + cuota.numeroCuota}
                    cuota={cuota}
                  />
                ))}

              {currentNovedad &&
                currentNovedad.cuotas &&
                currentNovedad.cuotas.length === 0 && (
                  <CardCuota
                    isEmpty
                    descriptionEmpty="No existen cuotas relacionadas a esta novedad"
                  />
                )}

              {!currentNovedad && (
                <>
                  <CardCuota isLoading />
                  <CardCuota isLoading />
                  <CardCuota isLoading />
                </>
              )}
            </div>
          </CardsColumn>
        </div>
      </div>
    </div>
  );
};

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};

export default Cuotas;
