import React from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setPage,
  setTake,
  selectSearchCriteria,
  selectEstado,
} from "./actions";
import Searcher from "~styled/searcher/searcher";
import { Typography } from "@mui/material";
import Pagination from "~styled/pagination/Pagination";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { paginationOptions } from "~constants/pagination";
import Selector from "~styled/selector/Selector";
import { estadoEndosoFilter, estadoEndosoOptions } from "~constants/endosos";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: 20,
    width: "100%",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  searchers: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: 20,
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingLeft: 5,
    paddingBottom: 5,
  },
  horizontalSpace: { paddingRight: "10px" },
  horizontalSpaceSearcher: { paddingRight: "10px", width: "100%" },
  searcherAndTitle: {
    display: "flex",
    flexDirection: "column",
    width: "220px",
  },
  dateSelectorAndTitle: {
    display: "flex",
    flexDirection: "column",
    width: "220px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.listadoEndososReducer.isLoading,
    totalRegistros: state.listadoEndososReducer.totalRegistros,

    take: state.listadoEndososReducer.take,
    page: state.listadoEndososReducer.page,
    estado: state.listadoEndososReducer.estado,
    searchCriteria: state.listadoEndososReducer.searchCriteria,
  }));

const messageLoading = "Cargando su consulta previa";

const FiltrosEndosos = () => {
  const { isLoading, totalRegistros, take, page, searchCriteria, estado } =
    useReduxSelector();

  const dispatch = useDispatch();

  const handleChange = (event, action) => {
    dispatch(setPage(0));
    dispatch(action(event.target.value));
  };

  const handleChangePage = (value) => {
    dispatch(setPage(value));
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <>
          <div className={classes.filtersContainer}>
            <div className={classes.filters}>
              <div className={classes.searcherAndTitle}>
                <Typography variant="h3" className={classes.label}>
                  ESTADO
                </Typography>

                <div className={classes.horizontalSpace}>
                  <Selector
                    value={estadoEndosoOptions[estado]}
                    options={estadoEndosoFilter}
                    onChange={(value) =>
                      handleChange({ target: { value } }, selectEstado)
                    }
                    onClickDisabled={() => {
                      dispatch(queueMessage(messageLoading));
                    }}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className={classes.searcherAndTitle}>
                <Typography variant="h3" className={classes.label}>
                  BUSCAR
                </Typography>
                <div className={classes.horizontalSpaceSearcher}>
                  <Searcher
                    borderSquared
                    value={searchCriteria}
                    filter={searchCriteria}
                    placeholder="N° póliza, Dominio, Doc Asegurado"
                    onChange={(event) =>
                      handleChange(event, selectSearchCriteria)
                    }
                    onClear={() =>
                      handleChange(
                        { target: { value: "" } },
                        selectSearchCriteria
                      )
                    }
                  />
                </div>
              </div>
            </div>

            {!isLoading && (
              <div className={classes.filters}>
                <Pagination
                  rowsPerPage={take}
                  onChangeRowsPerPage={(value) =>
                    handleChange({ target: { value } }, setTake)
                  }
                  page={page}
                  onChangePage={handleChangePage}
                  totalRegistros={totalRegistros}
                  options={paginationOptions}
                />
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default FiltrosEndosos;
