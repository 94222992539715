import React from "react";
import pxToRem from "~libs/pxToRem";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  button: (props) => ({
    background: props.backgroundColor,
    borderRadius: 4,
    border: 0,
    color: "white",
    height: 53,
    display: "flex",
    marginBottom: props.marginBottom,
    padding: "0 35px 0 0",
    textTransform: "none",
    //width: "295px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
    "&:focus": {
      background: props.backgroundColor + "80",
    },
    "&:hover": {
      background: props.backgroundColor + "80",
    },
  }),
  buttonIcon: (props) => ({
    width: 50,
    height: 53,
    padding: 12,
    borderRadius: "4px 0 0 4px",
    background: props.backgroundColorIcon,
  }),
}));

const SocialButton = ({
  onClick,
  icon,
  label,
  backgroundColor,
  backgroundColorIcon,
  marginBottom,
  disabled,
}) => {
  const classes = useStyles({
    backgroundColor,
    backgroundColorIcon,
    marginBottom,
  });

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      size="large"
      color="primary"
      fullWidth
      className={classes.button}
    >
      <img src={icon} alt="" className={classes.buttonIcon} />
      <Typography
        variant="caption"
        noWrap
        style={{
          flex: 1,
          fontWeight: "bold",
          lineHeight: "1.5",
          fontSize: pxToRem(16),
        }}
      >
        {label}
      </Typography>
    </Button>
  );
};

export default SocialButton;
