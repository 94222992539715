import React, { useState, useEffect } from "react";
import VehiculoProvinciaSelector from "~styled/vehiculoProvinciaSelector/VehiculoProvinciaSelector";
import LocalidadSelector from "../../localidadSelector/LocalidadSelector";
import CodigoPostalSelector from "../../codigoPostalSelector/CodigoPostalSelector";

const LOCALIDAD_STEP = "LOCALIDAD";
const PROVINCIA_STEP = "PROVINCIA";
const CODIGO_POSTAL_STEP = "CODIGO_POSTAL";

const LocalidadStepper = ({ onChange }) => {
  const [localidad, setLocalidad] = useState(null);
  const [provincia, setProvincia] = useState(null);
  const [codigoPostal, setCodigoPostal] = useState(null);

  useEffect(() => {
    const allCompleted = localidad && provincia && codigoPostal;
    if (allCompleted && onChange) onChange(localidad, provincia, codigoPostal);
  }, [localidad, provincia, codigoPostal, onChange]);

  const step = getStep(localidad, provincia, codigoPostal);
  return (
    <div style={{ minHeight: "90vh" }}>
      {step === PROVINCIA_STEP && (
        <VehiculoProvinciaSelector
          value={provincia}
          onChange={(event) => setProvincia(event.target.value)}
        />
      )}
      {step === LOCALIDAD_STEP && (
        <LocalidadSelector
          provinciaId={provincia}
          onSelected={(event) => setLocalidad(event.target.value)}
        />
      )}
      {step === CODIGO_POSTAL_STEP && (
        <CodigoPostalSelector
          value={provincia}
          provinciaId={provincia}
          localidad={localidad}
          onChange={(event) => setCodigoPostal(event.target.value)}
        />
      )}
    </div>
  );
};

export default LocalidadStepper;

const getStep = (localidad, provincia, codigoPostal) => {
  if (!provincia) return PROVINCIA_STEP;
  if (!localidad) return LOCALIDAD_STEP;
  if (!codigoPostal) return CODIGO_POSTAL_STEP;
};
