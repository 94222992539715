import React from "react";
import pxToRem from "~libs/pxToRem";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  base: (props) => ({
    fontFamily: theme.typography.fontFamily,
    padding: pxToRem(4),
    fontSize: pxToRem(12),
    minHeight: pxToRem(22.6),
    minWidth: pxToRem(85.5),
    backgroundColor: props.custom.background,
    color: props.custom.text,
    fontWeight: props.bold ? "750" : "bold",
    lineHeight: 1.33,
    letterSpacing: pxToRem(0.12),
    textAlign: "center",
    borderRadius: 4,
    marginBottom: props.marginBottom,
  }),
  otros: (props) => ({
    backgroundColor: props.otros.background,
    color: props.otros.text,
  }),
  success: (props) => ({
    backgroundColor: props.success.background,
    color: props.success.text,
  }),
  info: (props) => ({
    backgroundColor: props.info.background,
    color: props.info.text,
  }),
  warning: (props) => ({
    backgroundColor: props.warning.background,
    color: props.warning.text,
  }),
  danger: (props) => ({
    backgroundColor: props.danger.background,
    color: props.danger.text,
  }),
}));

const ColorLabel = ({
  text,
  variant,
  textColor,
  backgroundColor,
  marginBottom,
  bold,
}) => {
  const colors = {
    otros: { text: "#009bba", background: "#c7f2fa" },
    success: { text: "#069661", background: "#d2f6e2" },
    info: { text: "#ee9d00", background: "#fff7cc" },
    warning: { text: "#f17f00", background: "#ffeece" },
    danger: { text: "#dd1111", background: "#ffe0e0" },
    custom: {
      text: textColor ? textColor : "#000000",
      background: backgroundColor ? backgroundColor : "#e0e0e0",
    },
  };
  const classes = useStyles({ ...colors, marginBottom, bold });
  const variantClass = classes[variant];
  const fullClasses =
    variantClass && variantClass !== classes.base
      ? [classes.base, variantClass].join(" ")
      : classes.base;

  return <span className={fullClasses}>&nbsp;{text}&nbsp;</span>;
};

export default ColorLabel;
