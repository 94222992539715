import React from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSearchCriteria,
  selectFechaDesde,
  selectFechaHasta,
  selectEstado,
  selectNegocio,
  selectOficina,
} from "./actions";
import { setPage, setTake } from "../Filtros/actions";
import { calculateDate } from "~libs/dateCalculator";
import Searcher from "~styled/searcher/searcher";
import { Typography } from "@mui/material";
import Pagination from "~styled/pagination/Pagination";
import DateRangePicker from "~styled/dateRangePicker/DateRangePicker";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { paginationOptions } from "~constants/pagination";
import {
  estadosSolicitudEmisionFilter,
  estadosSolicitudEmisionOptions,
} from "./estados";
import {
  estadosSolicitudEmisionAdminFilter,
  estadosSolicitudEmisionAdminOptions,
} from "./estadosAdmin";
import { negociosFilter, negociosOptions } from "./negocios";
import Selector from "~styled/selector/Selector";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: 20,
    width: "100%",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: "1%",
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingLeft: 5,
    paddingBottom: 5,
  },
  dateSelectorAndTitle: {
    display: "flex",
    flexDirection: "column",
    minWidth: 250,
  },
  horizontalSpace: { paddingRight: "10px", minWidth: 250 },
  horizontalSpaceSearcher: { paddingRight: "10px", minWidth: 250 },
  textItemContainer: { display: "flex", flexDirection: "column" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.solicitudesEmisionReducer.isLoading,
    totalRegistros: state.solicitudesEmisionReducer.totalRegistros,
    oficinasOptions: state.solicitudesEmisionReducer.oficinasOptions,
    oficinasFilter: state.solicitudesEmisionReducer.oficinasFilter,

    take: state.solicitudesEmisionFiltrosReducer.take,
    page: state.solicitudesEmisionFiltrosReducer.page,
    fechaDesde: state.solicitudesEmisionFiltrosReducer.fechaDesde,
    fechaHasta: state.solicitudesEmisionFiltrosReducer.fechaHasta,
    searchCriteria: state.solicitudesEmisionFiltrosReducer.searchCriteria,
    estado: state.solicitudesEmisionFiltrosReducer.estado,
    negocio: state.solicitudesEmisionFiltrosReducer.negocio,
    oficina: state.solicitudesEmisionFiltrosReducer.oficina,

    isAdminUser: state.authenticatedViewReducer.isAdminUser,
  }));

const messageLoading = "Cargando su consulta previa";

const Filtros = () => {
  const {
    isLoading,
    take,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    page,
    totalRegistros,
    estado,
    negocio,
    isAdminUser,
    oficinasOptions,
    oficinasFilter,
    oficina,
  } = useReduxSelector();

  const dispatch = useDispatch();

  const handleChange = (event, action) => {
    dispatch(setPage(0));
    dispatch(action(event.target.value));
  };

  const handleChangeInterval = (value) => {
    dispatch(setPage(0));
    dispatch(selectFechaDesde(calculateDate(new Date(), value)));
    dispatch(selectFechaHasta(new Date()));
  };

  const handleChangeEstado = (value) => {
    dispatch(setPage(0));
    dispatch(selectEstado(value, isAdminUser));
  };

  const handleChangeNegocio = (value) => {
    dispatch(setPage(0));
    dispatch(selectNegocio(value));
  };

  const handleChangeOficina = (value) => {
    dispatch(setPage(0));
    dispatch(selectOficina(value));
  };

  const handleChangePage = (value) => {
    dispatch(setPage(value));
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <>
        <div className={classes.filters}>
          <div className={classes.textItemContainer}>
            <Typography variant="h3" className={classes.label}>
              BUSCAR
            </Typography>
            <div className={classes.horizontalSpaceSearcher}>
              <Searcher
                borderSquared
                value={searchCriteria}
                filter={searchCriteria}
                placeholder="Nombre titular, Dominio"
                onChange={(event) => handleChange(event, selectSearchCriteria)}
                onClear={() =>
                  handleChange({ target: { value: "" } }, selectSearchCriteria)
                }
              />
            </div>
          </div>
          <div className={classes.dateSelectorAndTitle}>
            <Typography variant="h3" className={classes.label}>
              FECHA
            </Typography>
            <div className={classes.horizontalSpace}>
              <DateRangePicker
                fechaDesde={fechaDesde}
                fechaHasta={fechaHasta}
                onChangeInterval={handleChangeInterval}
                onChangeStartDate={(value) =>
                  handleChange({ target: { value } }, selectFechaDesde)
                }
                onChangeEndDate={(value) =>
                  handleChange({ target: { value } }, selectFechaHasta)
                }
                onClickDisabled={() => {
                  dispatch(queueMessage(messageLoading));
                }}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className={classes.textItemContainer}>
            <Typography variant="h3" className={classes.label}>
              ESTADO
            </Typography>

            <div className={classes.horizontalSpace}>
              <Selector
                value={
                  isAdminUser
                    ? estadosSolicitudEmisionAdminOptions[estado.join()]
                    : estadosSolicitudEmisionOptions[estado.join()]
                }
                options={
                  isAdminUser
                    ? estadosSolicitudEmisionAdminFilter
                    : estadosSolicitudEmisionFilter
                }
                onChange={handleChangeEstado}
                onClickDisabled={() => {
                  dispatch(queueMessage(messageLoading));
                }}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </>
      <>
        <div className={classes.filtersContainer}>
          <div className={classes.filters}>
            <div className={classes.textItemContainer}>
              <Typography variant="h3" className={classes.label}>
                NEGOCIO
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={negociosOptions[negocio]}
                  options={negociosFilter}
                  onChange={handleChangeNegocio}
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            {isAdminUser && oficinasOptions && (
              <div className={classes.textItemContainer}>
                <Typography variant="h3" className={classes.label}>
                  OFICINA
                </Typography>

                <div className={classes.horizontalSpace}>
                  <Selector
                    value={oficinasOptions[oficina]}
                    options={oficinasFilter}
                    onChange={handleChangeOficina}
                    onClickDisabled={() => {
                      dispatch(queueMessage(messageLoading));
                    }}
                    disabled={isLoading}
                  />
                </div>
              </div>
            )}
          </div>

          {!isLoading && (
            <Pagination
              rowsPerPage={take}
              onChangeRowsPerPage={(value) =>
                handleChange({ target: { value } }, setTake)
              }
              page={page}
              onChangePage={handleChangePage}
              totalRegistros={totalRegistros}
              options={paginationOptions}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default Filtros;
