import React from "react";
import { makeStyles } from "@mui/styles";
import pxToRem from "~libs/pxToRem";
import NumberFormat from "react-number-format";
import CardButton from "~components/styled/cardButton/CardButton";
import Typography from "@mui/material/Typography";
import ContentLoader from "react-content-loader";
import emptyState from "~images/empty-cuotas_novedades.svg";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const CardCuota = ({ isLoading, cuota, isEmpty, descriptionEmpty }) => {
  const classes = useStyles();

  if (isEmpty) return <CuotasEmpty description={descriptionEmpty} />;
  return (
    <>
      {isLoading && displayLoadingTemplate()}
      {!isLoading && (
        <CardButton marginBottom={16} color={getSituacionColor("Pagada")}>
          <div className={classes.container}>
            <Typography
              noWrap
              style={{
                fontSize: pxToRem(12),
                fontWeight: 600,
                lineHeight: 1.33,
                color: "#9e9e9e",
              }}
            >
              {`CUOTA Nº ${cuota.numeroCuota}`}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  noWrap
                  className={classes.title}
                  style={{ textAlign: "left" }}
                >
                  {"Vencimiento"}
                </Typography>
                <Typography
                  noWrap
                  className={classes.content}
                  style={{ textAlign: "left" }}
                >
                  {format(
                    Date.parse(cuota.fechaVencimiento),
                    "dd'/'MM'/'yyyy",
                    { locale: es }
                  )}
                </Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  noWrap
                  className={classes.title}
                  style={{ textAlign: "right" }}
                >
                  {"Premio"}
                </Typography>
                <Typography
                  noWrap
                  className={classes.content}
                  style={{ textAlign: "right" }}
                >
                  <NumberFormat
                    value={cuota.premio}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                  />
                </Typography>
              </div>
            </div>
          </div>
        </CardButton>
      )}
    </>
  );
};

export default CardCuota;

const displayLoadingTemplate = () => (
  <CardButton marginBottom={16} color={"#FA8F82"}>
    <ContentLoader
      speed={1}
      backgroundColor="#f7f8ff"
      foregroundColor="#B9A7D7"
    >
      <rect x="16" y="20" rx="4" ry="4" width="20%" height="18" />
      <rect x="16" y="64" rx="4" ry="4" width="90%" height="15" />
      <rect x="16" y="84" rx="4" ry="4" width="90%" height="24" />
    </ContentLoader>
  </CardButton>
);

const getSituacionColor = (situacion) => {
  const otros = "#4CBFD6";
  const warning = "#FAB673";
  const success = "#9ADDCF";
  const danger = "#FA8F82";
  const grey = "#E0E0E0";

  if (situacion === "Pagada") return success;
  if (situacion === "Pagada_Vencida") return warning;
  if (situacion === "Vencida") return danger;
  if (situacion === "No_Vencida") return otros;
  return grey;
};

const useStyles = makeStyles((theme) => ({
  container: { padding: 16, display: "flex", flexDirection: "column" },
  title: {
    fontSize: `${pxToRem(11)} !important`,
    fontWeight: "600 !important",
    lineHeight: "1.33 !important",
    color: "#bdbdbd",
  },
  content: {
    fontSize: `${pxToRem(16)} !important`,
    fontWeight: "bold !important",
    lineHeight: "1.38 !important",
    color: "#424242",
  },
  expandedIcon: { transform: "rotate(180deg)" },
  notExpandedIcon: { transform: "rotate(0deg)" },
  downloadingLink: {
    fontSize: pxToRem(16),
    lineHeight: 1.5,
    opacity: 0.79,
    fontWeight: "bold 400",
    color: theme.palette.primary.light,
  },
}));

const useStylesCuotasEmpty = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: "200px",
  },
  cuotasEmptyText: {
    width: 244,
    height: 44,
    fontSize: 16,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.colours.grey600,
    paddingTop: 20,
  },
}));

const CuotasEmpty = ({ description }) => {
  const classes = useStylesCuotasEmpty();
  return (
    <div className={classes.root}>
      <img src={emptyState} className={classes.image} alt="" />
      <Typography color="textPrimary" className={classes.cuotasEmptyText}>
        {description}
      </Typography>
    </div>
  );
};
