import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import { paths, datosPagoPath } from "~constants/referenceNames";

const datosPago = () => {
  return {
    getInformacionDatosPago: (id) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      const url = environment.segurosUrl + paths[datosPagoPath] + "/" + id;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export default datosPago;
