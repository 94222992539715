import React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/AssignmentIndSharp";
import { siniestrosPath } from "~constants/referenceNames";
import SectionLayout from "~styled/layouts/sectionLayout/SectionLayout";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import {
  selectSearchCriteria,
  selectEstado,
  selectTipo,
  setTake,
  setPage,
  selectFechaDesde,
  selectFechaHasta,
} from "./filtros/actions";

import { getSiniestros } from "./listadoSiniestros/actions";
import ListadoSiniestros from "./listadoSiniestros/ListadoSiniestros";

import {
  getUrlWithParameters,
  updateUrlParameters,
} from "~hooks/urlSearcherManager";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    alignItems: "right",
    width: "100%",
  },
  tableContainer: {
    padding: "5vh",
  },
  table: {
    backgroundColor: theme.colours.white,
    borderRadius: "8px 8px 8px 8px",
    boxShadow: "7px 8px 24px -6px #CCCCE4",
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonFilter: {
    //display: "flex",
    width: "10vw",
    height: "5vh",
    borderRadius: "4px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    siniestros: state.listadoSiniestrosReducer.siniestros,
    isLoading: state.listadoSiniestrosReducer.isLoading,
    failed: state.listadoSiniestrosReducer.failed,
    siniestroModificado: state.listadoSiniestrosReducer.siniestroModificado,

    fechaDesde: state.listadoSiniestrosFiltroReducer.fechaDesde,
    fechaHasta: state.listadoSiniestrosFiltroReducer.fechaHasta,
    take: state.listadoSiniestrosFiltroReducer.take,
    page: state.listadoSiniestrosFiltroReducer.page,
    searchCriteria: state.listadoSiniestrosFiltroReducer.searchCriteria,
    estado: state.listadoSiniestrosFiltroReducer.estado,
    tipo: state.listadoSiniestrosFiltroReducer.tipo,
  }));

const params = [
  {
    name: "searchCriteria",
    nameUrl: "SearchCriteria",
    action: selectSearchCriteria,
  },
  { name: "estado", nameUrl: "Estado", action: selectEstado },
  { name: "tipo", nameUrl: "Tipo", action: selectTipo },
  { name: "take", nameUrl: "Take", action: setTake },
  {
    name: "fechaDesde",
    nameUrl: "Desde",
    action: selectFechaDesde,
    isDate: true,
  },
  {
    name: "fechaHasta",
    nameUrl: "Hasta",
    action: selectFechaHasta,
    isDate: true,
  },
];

const SiniestrosContainer = () => {
  const currentState = useReduxSelector();

  const {
    failed,
    page,
    take,
    searchCriteria,
    fechaDesde,
    fechaHasta,
    estado,
    tipo,
    siniestroModificado,
  } = currentState;

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const url = getUrlWithParameters({
      params,
      currentState,
      index: siniestrosPath,
    });

    setUrl(url);
    history.replace(url);

    //eslint-disable-next-line
  }, [
    history,
    searchCriteria,
    estado,
    tipo,
    page,
    take,
    fechaDesde,
    fechaHasta,
  ]);

  useEffect(() => {
    updateUrlParameters({
      params,
      location,
      dispatch,
      setPage,
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (url) {
      dispatch(
        getSiniestros({
          skip: take * page,
          take,
          searchCriteria,
          estado,
          tipo,
          fechaDesde,
          fechaHasta,
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, url, siniestroModificado]);

  if (failed) return <UnexpectedError />;

  return (
    <SectionLayout
      Icon={AssignmentIcon}
      title={"Siniestros"}
      selectedDefault={siniestrosPath}
    >
      <div className={classes.root}>
        <div className={classes.body}>
          <ListadoSiniestros />
        </div>
      </div>
    </SectionLayout>
  );
};

export default SiniestrosContainer;
