import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";

import DetalleCard from "~business/Cards/DetalleCard.js";

import DescriptionIcon from "@mui/icons-material/Description";
import {
  setPolizaFileName,
  setTarjetaFileName,
  setCedulaMercosurFileName,
  setCuponeraFileName,
  patchPoliza,
  getPoliza,
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import ItemFileUploaderCard from "~business/Cards/ItemFileUploaderCard";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  saveButton: {
    marginTop: "5%",
    marginLeft: "30%",
    marginRight: "30%",
  },
}));

const title = "Documentación";

const adjuntos = {
  poliza: setPolizaFileName,
  tarjeta: setTarjetaFileName,
  cedulaMercosur: setCedulaMercosurFileName,
  cuponera: setCuponeraFileName,
};

const useReduxSelector = () =>
  useSelector((state) => {
    const certificados = state.detallePolizaReducer.currentPoliza
      ? state.detallePolizaReducer.currentPoliza.certificados
      : {};

    return {
      ...state.detallePolizaReducer,
      ubicacionCedulaMercosur: certificados.ubicacionCedulaMercosur,
      ubicacionCuponera: certificados.ubicacionCuponera,
      ubicacionPoliza: certificados.ubicacionPoliza,
      ubicacionTarjeta: certificados.ubicacionTarjeta,
      canUploadImpresos: state.authenticatedViewReducer.canUploadImpresos,
      negocio: state.detallePolizaReducer.currentPoliza
        ? state.detallePolizaReducer.currentPoliza.negocio
        : undefined,
    };
  });

const ImpresosCard = ({ isLoading, idPoliza }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const state = useReduxSelector();
  const {
    polizaFileNameSelected,
    tarjetaFileNameSelected,
    cedulaMercosurFileNameSelected,
    cuponeraFileNameSelected,
    ubicacionCedulaMercosur,
    ubicacionCuponera,
    ubicacionTarjeta,
    ubicacionPoliza,
    canUploadImpresos,
    negocio,
    polizaActualizada,
  } = state;

  const handleAdded = (event, adjunto) => {
    const action = adjuntos[adjunto];
    const value = `temp/${event.target.value}`;
    dispatch(
      action({
        value: value,
        valid: true,
      })
    );
  };
  const handleRemoved = (event, adjunto) => {
    const action = adjuntos[adjunto];
    dispatch(
      action({
        value: null,
        valid: false,
      })
    );
  };

  const handleSave = () => {
    let data = {
      urlPoliza: polizaFileNameSelected,
      urlTarjeta: tarjetaFileNameSelected,
      urlCedulaMercosur: cedulaMercosurFileNameSelected,
      urlCuponera: cuponeraFileNameSelected,
    };

    dispatch(patchPoliza(idPoliza, data));
  };

  useEffect(() => {
    if (polizaActualizada) dispatch(getPoliza(idPoliza));
  }, [polizaActualizada, dispatch, idPoliza]);

  const showSaveButton =
    !isLoading &&
    canUploadImpresos &&
    (!ubicacionCedulaMercosur ||
      !ubicacionCuponera ||
      !ubicacionTarjeta ||
      !ubicacionPoliza);
  const polizaVisible = ubicacionPoliza || canUploadImpresos;
  const tarjetaVisible = ubicacionTarjeta || canUploadImpresos;
  const cedulaMercosurVisible = ubicacionCedulaMercosur || canUploadImpresos;
  const cuponeraVisible =
    (negocio === "MercadoAbierto" && ubicacionCuponera) || canUploadImpresos;

  return (
    <DetalleCard
      isLoading={isLoading}
      title={title}
      IconHeader={DescriptionIcon}
    >
      {polizaVisible && (
        <ItemFileUploaderCard
          isLoading={isLoading}
          label={ubicacionPoliza ? "Póliza" : "Póliza (requerido)"}
          onAdded={(event) => handleAdded(event, "poliza")}
          onRemoved={(event) => handleRemoved(event, "poliza")}
          acceptImage={false}
          acceptPdf={true}
          value={ubicacionPoliza}
          readOnly={ubicacionPoliza ? true : false}
        />
      )}

      {tarjetaVisible && (
        <ItemFileUploaderCard
          isLoading={isLoading}
          label={"Tarjeta"}
          onAdded={(event) => handleAdded(event, "tarjeta")}
          onRemoved={(event) => handleRemoved(event, "tarjeta")}
          acceptImage={false}
          acceptPdf={true}
          value={ubicacionTarjeta}
          readOnly={ubicacionTarjeta ? true : false}
        />
      )}

      {cedulaMercosurVisible && (
        <ItemFileUploaderCard
          isLoading={isLoading}
          label={"Cédula Mercosur"}
          onAdded={(event) => handleAdded(event, "cedulaMercosur")}
          onRemoved={(event) => handleRemoved(event, "cedulaMercosur")}
          acceptImage={false}
          acceptPdf={true}
          value={ubicacionCedulaMercosur}
          readOnly={ubicacionCedulaMercosur ? true : false}
        />
      )}

      {cuponeraVisible && (
        <ItemFileUploaderCard
          isLoading={isLoading}
          label={"Cuponera"}
          onAdded={(event) => handleAdded(event, "cuponera")}
          onRemoved={(event) => handleRemoved(event, "cuponera")}
          acceptImage={false}
          acceptPdf={true}
          value={ubicacionCuponera}
          readOnly={ubicacionCuponera ? true : false}
        />
      )}

      {showSaveButton && (
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          onClick={handleSave}
        >
          GUARDAR
        </Button>
      )}
    </DetalleCard>
  );
};

export default ImpresosCard;
