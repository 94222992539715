import React from "react";
import DetalleCard from "~business/Cards/DetalleCard.js";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { useSelector } from "react-redux";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ItemDominioCard from "~business/Cards/ItemDominioCard";

const title = "Vehículo";

const useReduxSelector = () =>
  useSelector((state) => {
    const datosVehiculo = state.detallePolizaReducer.currentPoliza
      ? state.detallePolizaReducer.currentPoliza.datosVehiculo
      : {};

    return {
      ...datosVehiculo,
    };
  });

const DatosVehiculoCard = ({ isLoading }) => {
  const {
    marca,
    modelo,
    anio,
    codia,
    codigoPostal,
    nombreLocalidad,
    nombreProvincia,
    tieneGNC,
    dominio,
    numeroMotor,
    numeroChasis,
  } = useReduxSelector();

  const localidad =
    nombreProvincia && nombreLocalidad
      ? `(CP ${codigoPostal}) ${nombreLocalidad}, ${nombreProvincia}`
      : `(CP ${codigoPostal})`;
  const tieneGNCFormatted =
    tieneGNC === null ? "No informado" : tieneGNC ? "SI" : "NO";

  const vehiculo = marca && modelo ? `(${anio}) ${marca} ${modelo}` : "";
  return (
    <DetalleCard title={title} IconHeader={DriveEtaIcon}>
      <ItemInputCard
        isLoading={isLoading}
        label={"Vehículo"}
        value={vehiculo.toUpperCase()}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"CODIA"}
        value={codia}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Localidad"}
        value={localidad}
        readOnly
      />
      <ItemDominioCard
        isLoading={isLoading}
        label={"Dominio"}
        value={dominio}
        readOnly
      />
      <ItemInputCard
        label={"Nº de Motor"}
        isLoading={isLoading}
        value={numeroMotor}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Nº de Chasis"}
        value={numeroChasis}
        readOnly
      />
      <ItemInputCard
        isLoading={isLoading}
        label={"Tiene GNC"}
        value={tieneGNCFormatted}
        readOnly
      />
    </DetalleCard>
  );
};

export default DatosVehiculoCard;
