import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import ItemInputCard from "~business/Cards/ItemInputCard";

const useReduxSelector = () =>
  useSelector((state) => {
    const currentSiniestro = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro
      : {};
    const vehiculo = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro.vehiculo
      : {};
    return {
      ...currentSiniestro,
      ...vehiculo,
    };
  });

const title = "Identificación del Automotor";

const DatosAutomotorCard = ({ isLoading }) => {
  const { dominio, modeloAnio, marca, modelo } = useReduxSelector();

  return (
    <>
      <DetalleCard title={title} IconHeader={DriveEtaIcon}>
        <ItemInputCard
          id={"dominio"}
          isLoading={isLoading}
          label={"Dominio"}
          value={dominio}
          readOnly
        />
        <ItemInputCard
          id={"modeloAnio"}
          isLoading={isLoading}
          label={"Modelo Año"}
          value={modeloAnio}
          readOnly
        />
        <ItemInputCard
          id={"marca"}
          isLoading={isLoading}
          label={"Marca"}
          value={marca}
          readOnly
        />
        <ItemInputCard
          id={"modelo"}
          isLoading={isLoading}
          label={"Modelo"}
          value={modelo}
          readOnly
        />
      </DetalleCard>
    </>
  );
};

export default DatosAutomotorCard;
