import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ItemInputCard from "~business/Cards/ItemInputCard";
import ColorLabelEstadoSiniestro from "~styled/colorLabelEstadoSiniestro/ColorLabelEstadoSiniestro";

const useReduxSelector = () =>
  useSelector((state) => {
    const currentSiniestro = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro
      : {};
    return {
      ...currentSiniestro,
    };
  });

const title = "Datos del Siniestro";

const DatosSiniestroCard = ({ isLoading }) => {
  const {
    tipo,
    numeroPoliza,
    compania,
    estado,
    sucursal,
    numeroSiniestroInterno,
    numeroSiniestroCompania,
  } = useReduxSelector();

  return (
    <>
      <DetalleCard title={title} IconHeader={VerifiedUserIcon}>
        <ItemInputCard
          id={"tipo"}
          isLoading={isLoading}
          label={"Tipo de Siniestro"}
          value={tipo}
          readOnly
        />
        <ItemInputCard
          id={"estado"}
          isLoading={isLoading}
          label={"Estado"}
          value={<ColorLabelEstadoSiniestro estado={estado} />}
          readOnly
        />
        <ItemInputCard
          id={"numeroSiniestroInterno"}
          isLoading={isLoading}
          label={"Número de Siniestro Interno"}
          value={numeroSiniestroInterno}
          readOnly
        />
        <ItemInputCard
          id={"compania"}
          isLoading={isLoading}
          label={"Compañía"}
          value={compania}
          readOnly
        />
        <ItemInputCard
          id={"numeroSiniestroCompania"}
          isLoading={isLoading}
          label={"Número de Siniestro de Compañía"}
          value={numeroSiniestroCompania}
          readOnly
        />
        <ItemInputCard
          id={"numeroPoliza"}
          isLoading={isLoading}
          label={"Número de Póliza"}
          value={numeroPoliza}
          readOnly
        />
        <ItemInputCard
          id={"sucursal"}
          isLoading={isLoading}
          label={"Sucursal"}
          value={sucursal}
          readOnly
        />
      </DetalleCard>
    </>
  );
};

export default DatosSiniestroCard;
