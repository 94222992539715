import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GalleryWithLightbox from "~styled/gallery/GalleryWithLightBox";

import FileUpload from "~components/styled/fileUpload/FileUpload";
import {
  setFotoFrenteVehiculo,
  clearFotoFrenteVehiculo,
  clearOmitirCargaFotos,
  setOmitirCargaFotos,
} from "../actions";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";
import CallToActionButton from "~styled/callToActionButton/CallToActionButton";
import pxToRem from "~libs/pxToRem";
import fileService from "~libs/fileService";

import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";

import { Typography, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContentLoader from "react-content-loader";

const photoWidth = "320px";
const photoHeight = "180px";

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    display: "flex",
    width: "100%",
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
  },
  linkButton: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
    marginLeft: 5,
    marginTop: 5,
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.38,
    color: "#616161",
  },
  gallery: { marginLeft: `calc( (100% - ${photoWidth}) / 2 )` },
  removePhotoLink: { display: "flex", justifyConent: "center", width: "100%" },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: pxToRem(24),
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    fileNameDefault:
      state.multicotizadorReducer.fotoFrenteVehiculoSelected.value,
  }));

const FotoFrenteVehiculoStep = () => {
  const dispatch = useDispatch();

  const { fileNameDefault } = useReduxSelector();

  const [fileName, setFileName] = useState(fileNameDefault);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    dispatch(clearFotoFrenteVehiculo());
    dispatch(clearOmitirCargaFotos());
  }, [dispatch]);

  const setUrl = async () => {
    if (fileName) {
      const url = (await fileService("temp").download(fileName)).url;
      setPhoto(url);
    }
  };

  useEffect(() => {
    setUrl();
    //eslint-disable-next-line
  }, [fileName]);

  const classes = useStyles();

  const isLoading = !photo && fileName;

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={"Adjuntá una foto del frente del vehículo"}
        marginBottom={60}
      />
      {!isLoading && !photo && (
        <div>
          <FileUpload
            onAdded={(event) => setFileName(event.target.value)}
            onRemoved={(event) => setFileName(null)}
            acceptImage={true}
            acceptPdf={false}
          />
          <Typography className={classes.secondaryText}>
            <Link
              style={{ width: "auto" }}
              className={classes.linkButton}
              component="button"
              underline="always"
              onClick={() => {
                dispatch(setOmitirCargaFotos({ value: null, selected: true }));
              }}
            >
              Omitir la carga de fotos
            </Link>
          </Typography>
        </div>
      )}

      {fileName && (
        <>
          <div className={classes.gallery}>
            {!isLoading && (
              <GalleryWithLightbox
                imagenes={[
                  {
                    src: photo,
                    thumbnail: photo,
                    thumbnailWidth: photoWidth,
                    thumbnailHeight: photoHeight,
                  },
                ]}
              />
            )}
            {isLoading && (
              <ContentLoader
                height={photoHeight}
                width={photoWidth}
                speed={1}
                backgroundColor="#f7f8ff"
                foregroundColor="#d9e8df"
              >
                <rect
                  x="0"
                  y="0"
                  rx="4"
                  ry="4"
                  width={photoWidth}
                  height={photoHeight}
                />
              </ContentLoader>
            )}
          </div>

          <div className={classes.removePhotoLink}>
            <Typography className={classes.secondaryText}>
              <Link
                className={classes.linkButton}
                component="button"
                underline="always"
                onClick={() => {
                  setFileName(null);
                  setPhoto(null);
                }}
              >
                Eliminar foto
              </Link>
            </Typography>
          </div>
        </>
      )}

      <div className={classes.infoContainer}>
        <IconButton>
          <InfoOutlinedIcon />
        </IconButton>

        <Typography className={classes.text}>
          Recordá que la patente debe estar visible en la foto
        </Typography>
      </div>

      <div style={{ paddingTop: pxToRem(24) }}>
        <CallToActionButton
          disabled={!photo}
          label={"Continuar"}
          onClick={() =>
            dispatch(setFotoFrenteVehiculo({ value: fileName, selected: true }))
          }
        />
      </div>
    </MulticotizadorContent>
  );
};

export default FotoFrenteVehiculoStep;
