import React, { useEffect } from "react";
import { useParams } from "react-router";
import { makeStyles } from "@mui/styles";

import { useSelector, useDispatch } from "react-redux";
import { getPoliza, setTabSelected } from "./actions";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import AssignmentIcon from "@mui/icons-material/AssignmentIndSharp";
import DetailsLayout from "~styled/layouts/detailsLayout/DetailsLayout";
import DetailsTabs from "~styled/layouts/detailsLayout/DetailsTabs";
import DetallePolizaTab from "./detallePolizaTab/DetallePolizaTab";
import HistorialEndososTab from "./historialEndososTab/HistorialEndososTab";
import { MERCADO_ABIERTO } from "~constants/negocios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.detallePolizaNuevoReducer,
  }));

const DetallePoliza = () => {
  const classes = useStyles();
  let { id } = useParams();
  const { tabSelected, currentPoliza } = useReduxSelector();

  const { failed } = useReduxSelector();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPoliza(id));
    //eslint-disable-next-line
  }, []);

  const isMercadoAbierto = currentPoliza?.negocio === MERCADO_ABIERTO;
  if (failed) return <UnexpectedError />;
  return (
    <>
      <DetailsLayout
        Icon={AssignmentIcon}
        title={"Detalle de la Poliza"}
        fixed
        tabsComponent={() => (
          <DetailsTabs
            defaultValue={tabSelected}
            labels={["DETALLE", "ENDOSOS"]}
            disabled={!isMercadoAbierto}
            onChange={(event) => {
              if (isMercadoAbierto)
                dispatch(setTabSelected(event.target.value));
            }}
          />
        )}
      >
        <div className={classes.root}>
          {tabSelected === 0 && <DetallePolizaTab key="detalle-poliza-tab" />}
          {tabSelected === 1 && (
            <HistorialEndososTab key="historial-endosos-poliza-tab" />
          )}
        </div>
      </DetailsLayout>
    </>
  );
};

export default DetallePoliza;
