import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { Chip, Avatar } from "@mui/material";
import { TC, TP, TR } from "~constants/tipoPoliza";
import { emphasize } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: "white",
    height: "100%",
    backgroundColor: theme.colours.violeta,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    width: "100%",
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
}));

const ChipCobertura = ({
  label,
  onClick,
  onDelete,
  tipoCobertura,
  clickable,
}) => {
  const classes = useStyles();

  return (
    <Chip
      clickable={clickable}
      tabIndex={-1}
      label={label}
      className={classes.chip}
      onDelete={onDelete}
      onClick={onClick}
      avatar={
        tipoCobertura === TP ? (
          <Avatar className={classes.avatar}>&#9733;</Avatar>
        ) : (
          <Fragment />
        )
      }
      color={
        tipoCobertura === TR
          ? "primary"
          : tipoCobertura === TC || tipoCobertura === TP
          ? "secondary"
          : "default"
      }
    />
  );
};

export default ChipCobertura;
