import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import FormaDePagoSelector from "../../formaDePagoSelector/FormaDePagoSelector";
import { TextField } from "@mui/material";
import CBUInput from "~styled/input/CBUInput";
import isCBUValid from "~libs/CBUValidator";
import CallToActionButton from "~styled/callToActionButton/CallToActionButton";
import CreditCardInput from "~styled/input/CreditCardInput";

import {
  isCreditCardNumberValid,
  getCreditCardType,
} from "~libs/creditCardValidator";
import visaIcon from "~images/logos/visa-icon.png";
import mastercardIcon from "~images/logos/mastercard-icon.png";
import amexIcon from "~images/logos/amex-icon.png";
import dinersIcon from "~images/logos/diners-icon.png";
import Avatar from "@mui/material/Avatar";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles((theme) => ({
  logoSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const FORMA_PAGO_STEP = "FORMA_PAGO_STEP";
const DATOS_PAGO_CBU_STEP = "DATOS_PAGO_CBU_STEP";
const DATOS_PAGO_TC_STEP = "DATOS_PAGO_TC_STEP";

const ENTER_KEY = 13;

const FormaPagoStepper = ({ onChange }) => {
  const classes = useStyles();
  const [formaPago, setFormaPago] = useState(null);
  const [cbu, setCbu] = useState(null);
  const [tarjetaCredito, setTarjetaCredito] = useState(null);

  const creditCardType = getCreditCardType(tarjetaCredito);
  const cardTypeIcon = getCardIcon(creditCardType);

  const canContinueCBU = cbu && cbu.length > 0 && isCBUValid(cbu);
  const canContinueTC =
    tarjetaCredito?.length >= 13 &&
    tarjetaCredito?.length <= 19 &&
    (!creditCardType || isCreditCardNumberValid(tarjetaCredito));
  const canContinue = canContinueTC || canContinueCBU;

  useEffect(() => {
    if (formaPago?.value === "CuponPago" && onChange) onChange({ formaPago });
  }, [formaPago, onChange]);

  const handleConfirmChanges = () => {
    const allCompleted = formaPago && canContinue;
    if (allCompleted && onChange)
      onChange({
        formaPago,
        cbu,
        tarjetaCredito,
        marcaTarjetaCredito: creditCardType,
      });
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) handleConfirmChanges();
  };

  const step = getStep(formaPago?.value);
  return (
    <div style={{ minHeight: "90vh" }}>
      {step === FORMA_PAGO_STEP && (
        <FormaDePagoSelector
          onChange={(event) => setFormaPago(event.target.value)}
        />
      )}
      <div style={{ paddingTop: 24 }}>
        {step === DATOS_PAGO_CBU_STEP && (
          <TextField
            autoFocus
            onFocus={(event) => event.target.select()}
            fullWidth={true}
            onKeyDown={onKeyDown}
            value={cbu}
            onChange={(event) => setCbu(event.target.value)}
            placeholder="Ej: 9999999999999999999999"
            InputProps={{
              inputComponent: CBUInput,
            }}
          />
        )}
        {step === DATOS_PAGO_TC_STEP && (
          <TextField
            fullWidth
            onKeyDown={onKeyDown}
            autoFocus
            onFocus={(event) => event.target.select()}
            type="tel"
            value={tarjetaCredito}
            onChange={(event) => {
              const value = event.target.value.replace(/\s/g, "");
              setTarjetaCredito(value);
            }}
            placeholder="Ej: 1111 2222 3333 4444"
            InputProps={{
              inputComponent: CreditCardInput,
              startAdornment: (
                <InputAdornment position="start">
                  {creditCardType !== null && (
                    <Avatar
                      alt={creditCardType}
                      src={cardTypeIcon}
                      className={classes.logoSize}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
        )}
        {(step === DATOS_PAGO_CBU_STEP || step === DATOS_PAGO_TC_STEP) && (
          <div style={{ paddingTop: 24 }}>
            <CallToActionButton
              disabled={!canContinue}
              label={"Continuar"}
              onClick={handleConfirmChanges}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormaPagoStepper;

const getStep = (formaPago) => {
  if (!formaPago) return FORMA_PAGO_STEP;
  if (formaPago === "DebitoConCBU") return DATOS_PAGO_CBU_STEP;
  if (formaPago === "DebitoConTarjetaCredito") return DATOS_PAGO_TC_STEP;
};

const getCardIcon = (type) => {
  if (type === "MasterCard") return mastercardIcon;
  if (type === "Visa") return visaIcon;
  if (type === "AmericanExpress") return amexIcon;
  if (type === "Diners") return dinersIcon;
  return null;
};

/*
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setValidadorDatosPago,
  clearValidadorDatosPago,
  setMarcaTarjetaCredito,
  clearMarcaTarjetaCredito,
} from "../actions";
import {
  isCreditCardNumberValid,
  getCreditCardType,
} from "~libs/creditCardValidator";
import TextField from "@mui/material/TextField";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import { makeStyles } from '@mui/styles';
import CreditCardInput from "~components/styled/input/CreditCardInput";
import pxToRem from "~libs/pxToRem";
import visaIcon from "~images/logos/visa-icon.png";
import mastercardIcon from "~images/logos/mastercard-icon.png";
import amexIcon from "~images/logos/amex-icon.png";
import dinersIcon from "~images/logos/diners-icon.png";
import Avatar from "@mui/material/Avatar";
import InputAdornment from "@mui/material/InputAdornment";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const ENTER_KEY = 13;



const TarjetaCreditoStep = () => {
  const dispatch = useDispatch();
  const [tarjetaCredito, setCampoTarjeta] = useState("");
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearValidadorDatosPago());
    dispatch(clearMarcaTarjetaCredito());
  }, [dispatch]);

  const getCardIcon = (type) => {
    if (type === "MasterCard") return mastercardIcon;
    if (type === "Visa") return visaIcon;
    if (type === "AmericanExpress") return amexIcon;
    if (type === "Diners") return dinersIcon;
    return null;
  };

  let creditCardType = getCreditCardType(tarjetaCredito);
  let cardTypeIcon = getCardIcon(creditCardType);

  let allowContinue =
    creditCardType !== null ? isCreditCardNumberValid(tarjetaCredito) : true;

  const canContinue =
    tarjetaCredito.length >= 13 && tarjetaCredito.length <= 19 && allowContinue;

  const handleNext = (value) => {
    dispatch(setValidadorDatosPago({ value, selected: true }));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) {
      if (creditCardType !== null)
        dispatch(
          setMarcaTarjetaCredito({
            value: creditCardType,
            selected: true,
          })
        );
      handleNext(tarjetaCredito);
    }
  };

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={
          "Ingresá el número de tarjeta de crédito con la que realizas el pago"
        }
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
        type="tel"
        value={tarjetaCredito}
        onChange={(event) => {
          const value = event.target.value.replace(/\s/g, "");

          setCampoTarjeta(value);
        }}
        placeholder="Ej: 1111 2222 3333 4444"
        InputProps={{
          inputComponent: CreditCardInput,
          startAdornment: (
            <InputAdornment position="start">
              {creditCardType !== null && (
                <Avatar
                  alt={creditCardType}
                  src={cardTypeIcon}
                  className={classes.logoSize}
                />
              )}
            </InputAdornment>
          ),
        }}
      ></TextField>
      <div style={{ paddingTop: 24 }}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            if (creditCardType !== null)
              dispatch(
                setMarcaTarjetaCredito({
                  value: creditCardType,
                  selected: true,
                })
              );
            handleNext(tarjetaCredito);
          }}
        />
      </div>
    </MulticotizadorContent>
  );
};

export default TarjetaCreditoStep;
*/
