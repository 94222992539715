import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { setSubmitting } from "./actions";
import { estadosSolicitudEmisionAdminFilter } from "../listadoSolicitudes/Filtros/estadosAdmin";

import AseguradoCard from "./cards/AseguradoCard";
import OriginadorCard from "./cards/OriginadorCard";
import DatosGeneralesCard from "./cards/DatosGeneralesCard";
import { useParams, useHistory } from "react-router";
import DatosVehiculoCard from "./cards/DatosVehiculoCard";
import DatosPolizaCard from "./cards/DatosPolizaCard";

import CuotasCard from "./cards/CuotasCard";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";
import CustomMessageError from "~styled/errorPages/CustomMessageError";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "4.5%",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },

  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  errorMessage: {
    display: "flex",
    flexDirection: "column",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.detalleSolicitudEmisionReducer,
    isAdminUser: state.authenticatedViewReducer.isAdminUser,
  }));

const DetalleSolicitudEmision = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const {
    detalleSolicitudEmision,
    isLoading,
    creatingPoliza,
    cuotasSelected,
    submitting,
  } = useReduxSelector();

  if (
    !isLoading &&
    !estadosSolicitudEmisionAdminFilter.some(
      (estadoValido) => estadoValido.value === detalleSolicitudEmision.estado
    )
  ) {
    return (
      <CustomMessageError
        errorMessage={
          <div className={classes.errorMessage}>
            {`La solicitud se encuentra en estado ${detalleSolicitudEmision.estado}.`}
            <Link
              href="#"
              onClick={() =>
                history.push(`/riesgos/automotores/solicitudesHistoricas/${id}`)
              }
            >
              Ver en bandeja de solicitudes históricas
            </Link>
          </div>
        }
      />
    );
  }

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  return (
    <>
      {creatingPoliza && <LoadingViewGears />}
      {!creatingPoliza && (
        <div className={classes.root}>
          <div className={classes.body}>
            <div className={classes.cardsAndCuotasContainer}>
              <CardsColumn>
                <DatosGeneralesCard isLoading={isLoading} solicitudId={id} />
                <AseguradoCard
                  editable
                  isLoading={isLoading}
                  displayError={submitting}
                  onBlur={handleBlur}
                />

                <OriginadorCard isLoading={isLoading} />
              </CardsColumn>

              <CardsColumn>
                <DatosPolizaCard
                  isLoading={isLoading}
                  onBlur={handleBlur}
                  displayError={submitting}
                />
                <DatosVehiculoCard
                  editable
                  isLoading={isLoading}
                  displayError={submitting}
                  onBlur={handleBlur}
                />

                {detalleSolicitudEmision.negocio === "Prendarios" && (
                  <CuotasCard
                    editable
                    isLoading={isLoading}
                    displayError={submitting}
                    onBlur={handleBlur}
                    cuotas={cuotasSelected}
                  />
                )}
              </CardsColumn>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetalleSolicitudEmision;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
