import React from "react";
import pxToRem from "~libs/pxToRem";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  base: (props) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    color: theme.palette.text.hint,
    lineHeight: 1.5,
    fontWeight: 400,
    marginBottom: props.marginBottom,
  }),
  purple: {
    color: `${theme.palette.primary.light} !important`,
    opacity: 0.79,
    fontWeight: `bold !important`,
    lineHeight: `2.13 !important`,
  },
}));

const LinkButton = ({ label, onClick, variant, marginBottom }) => {
  const classes = useStyles({ marginBottom });

  const variantClass = classes[variant];
  const fullClasses =
    variantClass && variantClass !== classes.base
      ? [classes.base, variantClass].join(" ")
      : classes.base;

  return (
    <Link
      className={fullClasses}
      component="button"
      underline="always"
      onClick={onClick}
    >
      {label}
    </Link>
  );
};

export default LinkButton;
