import React, { memo, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import useTheme from "@mui/material/styles/useTheme";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IntegerInputField from "~styled/integerInputField/IntegerInputField";
import { tipoCoberturasOptions } from "~constants/tipoCoberturas";
import Selector from "~styled/selector/Selector";
import { usosVehiculoOptions, usosVehiculoFilter } from "./usosVehiculo";
import SelectorMultiple from "~styled/selectorMultiple/SelectorMultiple";
import tiposVehiculo from "./tiposVehiculo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  compania: {
    textAlign: "center",
    fontWeight: 900,
    color: theme.colours.violeta,
    paddingTop: 15,
    paddingBottom: 15,
    display: "block",
    flex: 1,
  },
  tipoPoliza: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
  },
  importe: {
    textAlign: "center",
    fontWeight: 800,
    color: theme.colours.violeta,
  },
  content: {
    paddingBottom: 60,
  },
  sentenceTitle: {
    textAlign: "center",
    fontWeight: 900,
    color: "#afafaf",
    textTransform: "uppercase",
    paddingTop: "2vh",
    paddingBottom: "2vh",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "5%",
    alignItems: "center",
    maxWidth: 650,
    width: "100%",
  },
  buttonBack: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.white,
    color: "#455597",
    borderRadius: 50,
    border: 0,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  button: {
    width: "40vw",
    maxWidth: 200,
    marginTop: 20,
    background: theme.colours.verdeMgBroker,
    borderRadius: 50,
    border: 0,
    color: theme.colours.white,
    boxShadow: "2px 2px 10px 0 #CCCCE4",
    height: 47,
    fontWeight: 900,
    fontSize: 16,
    padding: "0 20px",
    textTransform: "none",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "0.96px",
    "&:disabled": {
      background: "#616161",
      color: "#C4C4C4",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const CoberturaEditor = memo(
  ({ isOpened, onClose, onClickAceptar, compania, cobertura }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const [tiposVehiculoSelected, setTiposVehiculoSelected] = useState(
      cobertura.tiposVehiculo ? cobertura.tiposVehiculo : []
    );

    const [antiguedad, setAntiguedad] = useState(
      cobertura.antiguedadVehiculoMinima
        ? cobertura.antiguedadVehiculoMinima
        : 0
    );
    const [usoVehiculo, setUsoVehiculo] = useState(
      cobertura.usoVehiculo ? cobertura.usoVehiculo : ""
    );

    useEffect(() => {
      clearData();
      //eslint-disable-next-line
    }, [compania, cobertura]);

    const handleChangeAntiguedad = (event) => {
      setAntiguedad(event.target.value);
    };

    const handleChangeUsoVehiculo = (event) => {
      setUsoVehiculo(event);
    };

    const handleChangeTipoVehiculo = (value) => {
      setTiposVehiculoSelected(value);
    };

    const clearData = () => {
      setAntiguedad(
        cobertura.antiguedadVehiculoMinima
          ? cobertura.antiguedadVehiculoMinima
          : 0
      );
      setUsoVehiculo(cobertura.usoVehiculo ? cobertura.usoVehiculo : "");
      setTiposVehiculoSelected(
        cobertura.tiposVehiculo ? cobertura.tiposVehiculo : []
      );
    };

    const handleClose = () => {
      clearData();
      onClose();
    };

    return (
      <div className={classes.root}>
        <Dialog
          open={isOpened}
          scroll="paper"
          onClose={handleClose}
          fullScreen={fullScreen}
        >
          <DialogTitle
            style={{
              padding: 0,
            }}
            disableTypography
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: 48 }} />
              <Typography variant={"h5"} className={classes.compania}>
                {`Cobertura ${compania} ${cobertura.idCobertura}`}
              </Typography>

              <div style={{ width: 48 }}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="Close"
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <Typography variant={"subtitle2"} className={classes.tipoPoliza}>
              {tipoCoberturasOptions[cobertura.tipoCobertura]}
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Divider />
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"Antigüedad vehículo mínima (en años)"}
            </Typography>
            <IntegerInputField
              onChange={(event) => handleChangeAntiguedad(event)}
              value={antiguedad}
              id={"antiguedad"}
              // hasError={showErrorOnImporte}
              //helperText={showErrorOnImporte ? errorText : ""}
              // onBlur={handleBlur}
              // reference={importeReference}
            />
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"Uso vehículo"}
            </Typography>
            <Selector
              id={"usoVehiculo"}
              value={usosVehiculoOptions[usoVehiculo]}
              options={usosVehiculoFilter}
              onChange={(event) => handleChangeUsoVehiculo(event)}
              // error={displayError}
              variant={"big"}
            />
            <Typography variant={"subtitle2"} className={classes.sentenceTitle}>
              {"Tipo vehículo"}
            </Typography>
            <SelectorMultiple
              onChange={(event) => handleChangeTipoVehiculo(event)}
              value={tiposVehiculoSelected}
              options={tiposVehiculo}
            />
          </DialogContent>
          <div className={classes.buttonContainer}>
            <Button
              onClick={handleClose}
              className={classes.buttonBack}
              variant="contained"
            >
              CANCELAR
            </Button>
            <Button
              className={classes.button}
              onClick={() =>
                onClickAceptar(antiguedad, usoVehiculo, tiposVehiculoSelected)
              }
              variant="contained"
              color="primary"
            >
              ACEPTAR
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
);

export default CoberturaEditor;
