import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import ListView from "~components/styled/listView/ListView";
import filterItems from "~libs/filterItems";
import { fetchCodigosPostales } from "./actions";
import SearcherList from "~styled/searcherList/SearcherList";
const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => {
    const codigoPostalSelectorReducer = state.codigoPostalSelectorReducer;

    return {
      codigosPostales: codigoPostalSelectorReducer.codigosPostales,
      isLoading: codigoPostalSelectorReducer.isLoading,
    };
  });
const CodigoPostalSelector = ({
  label,
  onChange,
  provinciaId,
  localidad = "",
}) => {
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const { codigosPostales, isLoading } = useReduxSelector();

  useEffect(() => {
    if (provinciaId) dispatch(fetchCodigosPostales(provinciaId, localidad));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemSelected = (item) => {
    setFilter("");
    if (onChange) onChange({ target: { value: item.value } });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <div className={classes.searcher}>
      <SearcherList
        onClear={() => setFilter("")}
        filter={filter}
        onChange={handleFilterChange}
        placeholder={label}
      />
      <ListView
        isLoading={isLoading}
        onItemSelected={handleItemSelected}
        list={
          filter === "" ? codigosPostales : filterItems(codigosPostales, filter)
        }
      />
    </div>
  );
};

export default CodigoPostalSelector;
