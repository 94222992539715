import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import AseguradoCard from "./cards/AseguradoCard";
import DatosGeneralesCard from "./cards/DatosGeneralesCard";
import DatosVehiculoCard from "./cards/DatosVehiculoCard";
import DatosAnulacionCard from "./cards/DatosAnulacionCard";
import CuotasCard from "./cards/CuotasCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingTop: "4.5%",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },

  cardItem: { minWidth: 300 },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.detallePolizaReducer,
    canUploadImpresos: state.authenticatedViewReducer.canUploadImpresos,
  }));

const DetallePoliza = () => {
  const classes = useStyles();
  const currentState = useReduxSelector();
  const { currentPoliza, isLoading, failed } = currentState;

  if (failed) return <UnexpectedError />;
  return (
    <div className={classes.root}>
      <div className={classes.cardsAndCuotasContainer}>
        <CardsColumn>
          <div className={classes.cardItem}>
            <DatosGeneralesCard isLoading={isLoading || !currentPoliza} />
          </div>
          <div className={classes.cardItem}>
            <DatosVehiculoCard isLoading={isLoading || !currentPoliza} />
          </div>
        </CardsColumn>

        <CardsColumn>
          {currentPoliza && currentPoliza.estado === "Anulada" && (
            <div className={classes.cardItem}>
              <DatosAnulacionCard
                isLoading={isLoading || !currentPoliza}
                data={currentPoliza ? currentPoliza.datosAnulacion : null}
              />
            </div>
          )}
          <div className={classes.cardItem}>
            <div className={classes.cardItem}>
              <AseguradoCard isLoading={isLoading || !currentPoliza} />
            </div>
          </div>
          <div className={classes.cardItem}>
            <div className={classes.cardItem}>
              <CuotasCard isLoading={isLoading || !currentPoliza} />
            </div>
          </div>
        </CardsColumn>
      </div>
    </div>
  );
};

export default DetallePoliza;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
