import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import FileUpload from "~styled/fileUpload/FileUpload";
import ItemLoadingCard from "./ItemLoadingCard";
import IconEdit from "~assets/icons/ic_edit.svg";
import pxToRem from "~libs/pxToRem";
import useField from "~hooks/useField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  fileUploadContainer: {
    paddingTop: "2%",
    paddingBottom: "2%",
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #8B8B8B",
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
}));

const validate = (required) => (value) => value || !required;

const errorText = "Requerido";

const ItemFileUploaderCard = ({
  label,
  onChange,
  onAdded,
  onProcessStart,
  onRemoved,
  acceptImage,
  acceptPdf,
  isLoading,
  readOnly,
  value,
  acceptedMimeTypes = [],
  required,
  variant,
}) => {
  const classes = useStyles();

  const validateFunction = useCallback(validate(required), [required]);

  const [localValue, setValue, handleEdit] = useField(
    value,
    validateFunction,
    null,
    isLoading,
    readOnly
  );

  const handleChangeValue = (value) => {
    if (value) handleEdit(false);
    setValue((prev) => ({ ...prev, value: value }));
    if (onChange)
      onChange({
        ...localValue,
        value: value,
        valid: validateFunction(value),
      });
  };

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <>
      {localValue.editing && (
        <div className={classes.fileUploadContainer}>
          <Typography className={classes.caption}>{label}</Typography>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <div style={{ width: "100%" }}>
              <FileUpload
                onProcessStart={onProcessStart}
                onAdded={(event) => {
                  //TODO: then replace onAdded and onRemoved and use onChange instead
                  if (onAdded) onAdded(event);
                  else handleChangeValue(event.target.value);
                }}
                onRemoved={(event) => {
                  //TODO: then replace onAdded and onRemoved and use onChange instead
                  if (onRemoved) onAdded(event);
                  else handleChangeValue(null);
                }}
                acceptImage={acceptImage}
                acceptPdf={acceptPdf}
                acceptedMimeTypes={acceptedMimeTypes}
              />
            </div>
            {!localValue.valid && required && (
              <Tooltip title={errorText}>
                <div className={classes.buttonLink}>
                  <InfoOutlinedIcon style={{ width: 30, height: 30 }} />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      )}
      {!localValue.editing && (
        <FileUploaderReadOnly
          label={label}
          value={localValue.value}
          editable={!readOnly}
          onClickEdit={(event) => {
            handleChangeValue(null);
            handleEdit(true);
          }}
          variant={variant}
        />
      )}
    </>
  );
};

export default ItemFileUploaderCard;

const useStylesReadOnly = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
}));

const FileUploaderReadOnly = ({
  id,
  label,
  value,
  editable,
  onClickEdit,
  isLoading,
  variant,
}) => {
  const classes = useStylesReadOnly();

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <div
      className={classes.container}
      style={{ padding: variant === "small" ? "1%" : "2%" }}
    >
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {value && (
          <div className={classes.value}>
            <a href={value} target="_blank" rel="noopener noreferrer">
              Ver Certificado
            </a>
          </div>
        )}
        {!value && <div>&mdash;</div>}
        {editable && (
          <div className={classes.buttonLink} onClick={onClickEdit}>
            <img alt="" src={IconEdit} className={classes.icon} />
          </div>
        )}
      </div>
    </div>
  );
};
