import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import { RC, TC, TP, TR } from "~constants/tipoPoliza";
import Paper from "@mui/material/Paper";
import PolizaButton from "./PolizaButton";

const useStyles = makeStyles((theme) => ({
  paperTitle: {
    background: theme.colours.white,
    borderRadius: "solid 1px 1px 1px 1px",
    width: "100%",
  },
  divider: {
    marginLeft: 10,
    marginRight: 10,
  },
  headerTitle: {
    textAlign: "center",
    fontWeight: 800,
    color: theme.colours.violeta,
    paddingTop: 5,
    paddingBottom: 5,
    lineHeight: 1.29,
    display: "block",
    flex: 1,
    whiteSpace: "pre-wrap",
    fontSize: "1rem",
  },
  sinCotizaciones: {
    display: "flex",
    color: theme.colours.brownGrey2,
    lineHeight: "13px",
    fontSize: "0.9em",
    textAlign: "center",
    padding: 20,
    textTransform: "uppercase",
    justifyContent: "center",
    alignItems: "center",
  },
  cotizacionesColumn: {
    width: "25%",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    justifyItems: "center",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "row",
  },
}));

const pendiente = "pendiente";

const CompaniaGroup = ({ isPending, compania, onClick }) => {
  const isCompaniaPending = compania.estado === pendiente;

  const polizaRC =
    !compania.cotizaciones || compania.cotizaciones.length === 0
      ? null
      : compania.cotizaciones.filter((item) => {
          return item.tipoPlanComercial === RC ? item : null;
        });
  const polizaTC =
    !compania.cotizaciones || compania.cotizaciones.length === 0
      ? null
      : compania.cotizaciones.filter((item) => {
          return item.tipoPlanComercial === TC ? item : null;
        });
  const polizaTP =
    !compania.cotizaciones || compania.cotizaciones.length === 0
      ? null
      : compania.cotizaciones.filter((item) => {
          return item.tipoPlanComercial === TP ? item : null;
        });
  const polizaTR =
    !compania.cotizaciones || compania.cotizaciones.length === 0
      ? null
      : compania.cotizaciones.filter((item) => {
          return item.tipoPlanComercial === TR ? item : null;
        });

  const hasPolizas = polizaRC || polizaTC || polizaTP || polizaTR;

  const cotizacionError = compania.error;

  const companiaTitle = compania ? compania.compania : null;
  const tiempoRespuesta =
    compania.tiempoRespuesta !== null
      ? `(Rta. en: ${compania.tiempoRespuesta} seg.)`
      : "(Rta en: N/D)";

  const classes = useStyles();
  return (
    <div style={{ paddingTop: 15, height: "100%" }}>
      <Paper className={classes.paperTitle}>
        <div style={{ textAlign: "center" }}>
          <Typography
            variant={"h6"}
            className={classes.headerTitle}
            style={{ display: "inline", marginRight: "0.2em" }}
          >
            {companiaTitle}
          </Typography>
          <Divider className={classes.divider} />
          {!isPending && !isCompaniaPending && hasPolizas && (
            <Typography
              style={{ color: "#757575", fontSize: "0.9em", display: "inline" }}
            >
              {tiempoRespuesta}
              {" // Nº Cotización Cía.:" +
                (compania.cotizaciones[0]?.cotizacionIdExterno ?? "N/D")}
            </Typography>
          )}
        </div>
        <Divider className={classes.divider} />
        <>
          {compania && (
            <div className={classes.loadingContainer}>
              <div className={classes.cotizacionesColumn}>
                <PolizaButton
                  //key={"Poliza-Button-" + polizaRC.cotizacionPlanId}
                  polizas={polizaRC}
                  onClick={onClick}
                  compania={compania}
                  isLoading={isPending && isCompaniaPending}
                />
              </div>
              <div className={classes.cotizacionesColumn}>
                <PolizaButton
                  //key={"Poliza-Button-" + polizaTC.cotizacionPlanId}
                  polizas={polizaTC}
                  onClick={onClick}
                  compania={compania}
                  isLoading={isPending && isCompaniaPending}
                />
              </div>
              <div className={classes.cotizacionesColumn}>
                <PolizaButton
                  //key={"Poliza-Button-" + polizaTP.cotizacionPlanId}
                  polizas={polizaTP}
                  onClick={onClick}
                  compania={compania}
                  isLoading={isPending && isCompaniaPending}
                />
              </div>
              <div className={classes.cotizacionesColumn}>
                <PolizaButton
                  //key={"Poliza-Button-" + polizaTR.cotizacionPlanId}
                  polizas={polizaTR}
                  onClick={onClick}
                  compania={compania}
                  isLoading={isPending && isCompaniaPending}
                />
              </div>
            </div>
          )}
          {!(isPending && isCompaniaPending) &&
            !hasPolizas &&
            !cotizacionError && (
              <Typography className={classes.sinCotizaciones}>
                {"No hay cotizaciones"}
              </Typography>
            )}
          {!(isPending && isCompaniaPending) && cotizacionError && (
            <Typography className={classes.sinCotizaciones}>
              {cotizacionError}
            </Typography>
          )}
        </>
      </Paper>
    </div>
  );
};

export default CompaniaGroup;
