import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setEmail, clearEmail } from "../actions";
import EmailValidator from "email-validator";
import TextField from "@mui/material/TextField";
import CallToActionButton from "~components/styled/callToActionButton/CallToActionButton";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import pxToRem from "~libs/pxToRem";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

import { Typography, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ENTER_KEY = 13;

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: pxToRem(16),
    fontWeight: "bold",
    lineHeight: "1.36",
    textAlign: "left",
    color: "#7644c9",
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.38,
    color: "#616161",
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: pxToRem(24),
  },
}));

const disableCode = true;

const ContactoEmailStep = () => {
  const dispatch = useDispatch();
  const [email, setCampoEmail] = useState("");
  const classes = useStyles();

  useEffect(() => {
    dispatch(clearEmail());
  }, [dispatch]);

  const canContinue = email.length > 0 && EmailValidator.validate(email);

  const handleNext = (value) => {
    dispatch(setEmail({ value, selected: true }));
  };

  const onKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY && canContinue) handleNext(email);
  };

  return (
    <MulticotizadorContent>
      <PrimaryTitle
        label={"Ingresá el correo electrónico del asegurado"}
        marginBottom={60}
      />
      <TextField
        fullWidth={true}
        onKeyDown={onKeyDown}
        autoFocus
        onFocus={(event) => event.target.select()}
        type="email"
        value={email}
        onChange={(event) => setCampoEmail(event.target.value)}
        placeholder="Ej: micorreo@mail.com"
      ></TextField>
      <div style={{ display: "flex", justifyConent: "space-around" }}>
        <Typography className={classes.secondaryText}>
          <Link
            className={classes.secondaryText}
            style={{ marginLeft: 5, marginTop: 5 }}
            component="button"
            underline="always"
            onClick={() => {
              handleNext("");
            }}
          >
            Omitir este paso
          </Link>
        </Typography>
      </div>

      {!disableCode && (
        <div className={classes.infoContainer}>
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>

          <Typography className={classes.text}>
            Recorda que completando todos los pasos podras descargar generar el
            certificado.
          </Typography>
        </div>
      )}
      <div style={{ paddingTop: pxToRem(24) }}>
        <CallToActionButton
          disabled={!canContinue}
          label={"Continuar"}
          onClick={() => {
            handleNext(email);
          }}
        />
      </div>
    </MulticotizadorContent>
  );
};

export default ContactoEmailStep;
