import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AgenciaSelector from "~business/agenciaSelector/agenciaSelector";
import { makeStyles } from "@mui/styles";
import { setAgencia, clearAgencia } from "../actions";
import MulticotizadorContent from "./MulticotizadorContent";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: "16px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    agenciaSelected: state.multicotizadorReducer.agenciaSelected,
  }));

const AgenciaStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { agenciaSelected } = useReduxSelector();

  const handleChange = (event) => {
    dispatch(setAgencia({ ...event.target.value, selected: true }));
  };

  useEffect(() => {
    dispatch(clearAgencia());
  }, [dispatch]);

  return (
    <>
      <MulticotizadorContent>
        <div className={classes.title}>
          <PrimaryTitle label="Ingresá la Agencia" marginBottom={32} />
        </div>
        <AgenciaSelector value={agenciaSelected} onChange={handleChange} />
      </MulticotizadorContent>
    </>
  );
});

export default AgenciaStep;
