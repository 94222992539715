import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import PeopleIcon from "@mui/icons-material/People";
import ItemInputCard from "~business/Cards/ItemInputCard";

const useReduxSelector = () =>
  useSelector((state) => {
    const datosTercero = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro.tercero
      : {};
    return {
      ...datosTercero,
    };
  });

const title = "Datos del Tercero";

const DatosTerceroCard = ({ isLoading }) => {
  const {
    nombre,
    apellido,
    numeroDocumento,
    numeroPoliza,
    compania,
    dominio,
    modelo,
    marca,
    telefono,
    danios,
  } = useReduxSelector();

  return (
    <>
      <DetalleCard title={title} IconHeader={PeopleIcon}>
        <ItemInputCard
          id={"nombreTercero"}
          isLoading={isLoading}
          label={"Nombre"}
          value={nombre}
          readOnly
        />
        <ItemInputCard
          id={"apellidoTercero"}
          isLoading={isLoading}
          label={"Apellido"}
          value={apellido}
          readOnly
        />
        <ItemInputCard
          id={"numeroDocumentoTercero"}
          isLoading={isLoading}
          label={"DNI"}
          value={numeroDocumento}
          readOnly
        />
        <ItemInputCard
          id={"companiaTercero"}
          isLoading={isLoading}
          label={"Compañía"}
          value={compania}
          readOnly
        />
        <ItemInputCard
          id={"numeroPolizaTercero"}
          isLoading={isLoading}
          label={"Número de Póliza"}
          value={numeroPoliza}
          readOnly
        />
        <ItemInputCard
          id={"telefonoTercero"}
          isLoading={isLoading}
          label={"Teléfono"}
          value={telefono}
          readOnly
        />
        <ItemInputCard
          id={"dominioTercero"}
          isLoading={isLoading}
          label={"Dominio"}
          value={dominio}
          readOnly
        />
        <ItemInputCard
          id={"marcaTercero"}
          isLoading={isLoading}
          label={"Marca"}
          value={marca}
          readOnly
        />
        <ItemInputCard
          id={"modeloTercero"}
          isLoading={isLoading}
          label={"Modelo"}
          value={modelo}
          readOnly
        />
        <ItemInputCard
          id={"daniosTercero"}
          isLoading={isLoading}
          label={"Daños al Vehículo"}
          value={danios}
          readOnly
        />
      </DetalleCard>
    </>
  );
};

export default DatosTerceroCard;
