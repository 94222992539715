import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { useHistory } from "react-router-dom";

import { useParams } from "react-router";
import { paths, solicitudesEmisionPath } from "~constants/referenceNames";
import {
  getDetalleSolicitudEmision,
  clearData,
  postEmisionManual,
  setSubmitting,
  clearBase64File,
  setEmail,
  setDominio,
  setNroChasis,
  setNroMotor,
  setPremioTotal,
  setCuota,
  setVigenciaDesde,
  setVigenciaHasta,
  setSumaAsegurada,
  patchRechazarSolicitud,
} from "./actions";
import {
  estadosSolicitudEmisionAdminOptions,
  DATOSINCOMPLETOS,
} from "../listadoSolicitudes/Filtros/estadosAdmin";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import startOfDay from "date-fns/startOfDay";
import differenceInDays from "date-fns/differenceInDays";
import { es } from "date-fns/esm/locale";
import JSZip from "jszip";
import FileSaver from "file-saver";
import DetailsLayout from "~styled/layouts/detailsLayout/DetailsLayout";
import DetailsTabs from "~styled/layouts/detailsLayout/DetailsTabs";
import DetalleSolicitudEmision from "./DetalleSolicitudEmision";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import Documentacion from "./Documentacion";
import Historia from "./Historia";
import SuccessDialog from "./SuccessDialog";
import PopupRechazar from "../PopupRechazar";
import SaveIcon from "@mui/icons-material/Save";
import { Tooltip, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  btnFinalizar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  btnRechazar: {
    marginRight: "2%",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    ...state.detalleSolicitudEmisionReducer,
    isAdminUser: state.authenticatedViewReducer.isAdminUser,
  }));

const DetalleSolicitudEmisionContainer = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [isPopupRechazarOpened, setIsPopupRechazarOpened] = useState(false);

  const {
    detalleSolicitudEmision,
    isLoading,
    polizaCreada,
    emailSelected,
    dominioSelected,
    vigenciaDesdeSelected,
    vigenciaHastaSelected,
    nroChasisSelected,
    nroMotorSelected,
    numeroPolizaSelected,
    premioTotalSelected,
    polizaFileNameSelected,
    tarjetaFileNameSelected,
    cedulaMercosurFileNameSelected,
    cuponeraFileNameSelected,
    cuotasSelected,
    loadingError,
    solicitudRechazada,
    vigenciaSelected,
    base64Files,
    downloadFiles,
    sumaAseguradaSelected,
    fotoFrontalFileNameSelected,
    fotoTraseraFileNameSelected,
    fotoLateralIzquierdoFileNameSelected,
    fotoLateralDerechoFileNameSelected,
    cantidadCuotasSelected,
    isAdminUser,
    isProcessingPoliza,
    isProcessingTarjeta,
    isProcessingCedulaMercosur,
    isProcessingCuponera,
  } = useReduxSelector();

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    dispatch(getDetalleSolicitudEmision(id));

    return () => {
      dispatch(clearData());
    };
  }, [dispatch, id]);

  const zipAndDownloadFiles = () => {
    let zip = new JSZip();
    Object.keys(base64Files).forEach((key, index) => {
      zip.file(`foto_${index + 1}.png`, base64Files[key].file, {
        base64: true,
      });
    });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, `fotos_vehiculo_${id}.zip`);
    });

    dispatch(clearBase64File());
  };

  // transform to array
  const cuotasSelector = (cuotasObj) => {
    let array = Object.keys(cuotasObj).map((cuotasKey) => cuotasObj[cuotasKey]);
    let result = array.map((elem) => ({
      importe: elem.importe.value,
      fechaVencimiento: elem.fechaVencimiento.value,
    }));

    return result;
  };

  const handleRechazar = () => {
    setIsPopupRechazarOpened(true);
  };

  const handleClosePopupRechazo = () => {
    setIsPopupRechazarOpened(false);
  };

  const handleConfirmarRechazo = (motivoRechazo) => {
    let data = {
      motivoRechazo,
    };

    dispatch(patchRechazarSolicitud(id, data));
    setIsPopupRechazarOpened(false);
  };

  const handleGuardarSolicitud = () => {
    dispatch(setSubmitting(true));

    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    const inputs = [
      { setFocusFunction: setPremioTotal, ...premioTotalSelected },
      { setFocusFunction: setSumaAsegurada, ...sumaAseguradaSelected },
      { setFocusFunction: setVigenciaDesde, ...vigenciaDesdeSelected },
      { setFocusFunction: setVigenciaHasta, ...vigenciaHastaSelected },
      { setFocusFunction: setEmail, ...emailSelected },
      { setFocusFunction: setDominio, ...dominioSelected },
      { setFocusFunction: setNroMotor, ...nroMotorSelected },
      { setFocusFunction: setNroChasis, ...nroChasisSelected },
    ];

    let inputToFocus = inputs.find((input) => !input.valid);

    if (inputToFocus)
      dispatch(
        inputToFocus.setFocusFunction({
          value: inputToFocus.value,
          valid: inputToFocus.valid,
          focus: true,
        })
      );

    const cuotasInputs = Object.keys(cuotasSelected).map((key) => {
      return {
        setFocusFunction: setCuota,
        ...cuotasSelected[key],
      };
    });

    let cuotaInputToFocus = cuotasInputs.find(
      (input) => !input.importe.valid || !input.fechaVencimiento.valid
    );

    if (cuotaInputToFocus)
      dispatch(
        cuotaInputToFocus.setFocusFunction({
          ...cuotaInputToFocus,
          importe: {
            value: cuotaInputToFocus.importe.value,
            valid: cuotaInputToFocus.importe.valid,
            focus: !cuotaInputToFocus.importe.valid,
          },
          fechaVencimiento: {
            value: cuotaInputToFocus.fechaVencimiento.value,
            valid: cuotaInputToFocus.fechaVencimiento.valid,
            focus: !cuotaInputToFocus.fechaVencimiento.valid,
          },
        })
      );

    if (inputToFocus || cuotaInputToFocus) {
      dispatch(queueMessage("Hay campos sin completar o no válidos"));
      return;
    }

    if (
      isProcessingPoliza ||
      isProcessingTarjeta ||
      isProcessingCedulaMercosur ||
      isProcessingCuponera
    ) {
      dispatch(
        queueMessage(
          "Aguarda a que todos los archivos se hayan subido completamente."
        )
      );
      return;
    }

    if (
      detalleSolicitudEmision.negocio === "Prendarios" &&
      !isAdminUser &&
      Math.abs(
        differenceInDays(
          Date.parse(detalleSolicitudEmision.vigenciaDesde),
          startOfDay(Date.parse(vigenciaDesdeSelected.value))
        )
      ) > 60
    ) {
      dispatch(queueMessage("No se debe modificar la fecha en más de 60 días"));
      return;
    }

    if (
      detalleSolicitudEmision.negocio === "Prendarios" &&
      differenceInDays(
        Date.parse(cuotasSelected[1].fechaVencimiento.value),
        startOfDay(Date.parse(vigenciaDesdeSelected.value))
      ) < 0
    ) {
      dispatch(
        queueMessage(
          "La fecha de vencimiento de las cuotas no pueden ser menores a la vigencia desde"
        )
      );
      return;
    }

    if (
      (detalleSolicitudEmision.negocio === "MercadoAbierto" &&
        numeroPolizaSelected.value !== "" &&
        polizaFileNameSelected.value === null) ||
      (detalleSolicitudEmision.negocio === "Prendarios" &&
        numeroPolizaSelected.value !== "" &&
        (polizaFileNameSelected.value === null ||
          tarjetaFileNameSelected.value === null))
    ) {
      dispatch(queueMessage("Debe cargarse la Documentación requerida"));
      return;
    }

    let cuotasArray = cuotasSelector(cuotasSelected);

    let sumaImportesCuotas = 0;
    cuotasArray.forEach(
      (element) => (sumaImportesCuotas += parseFloat(element.importe))
    );

    if (
      detalleSolicitudEmision.negocio === "Prendarios" &&
      Math.round(
        (parseFloat(premioTotalSelected.value) + Number.EPSILON) * 100
      ) /
        100 !==
        Math.round((sumaImportesCuotas + Number.EPSILON) * 100) / 100
    ) {
      dispatch(
        queueMessage("La suma de cuotas no coinciden con el premio total")
      );
      return;
    }

    let fotosVehiculo = null;

    if (!detalleSolicitudEmision.fotosVehiculo) {
      let seHaCargadoAlgunaFoto = !(
        fotoFrontalFileNameSelected.value === null &&
        fotoTraseraFileNameSelected.value === null &&
        fotoLateralIzquierdoFileNameSelected.value === null &&
        fotoLateralDerechoFileNameSelected.value === null
      );

      let seHanCargadoTodasLasFotos =
        fotoFrontalFileNameSelected.value !== null &&
        fotoTraseraFileNameSelected.value !== null &&
        fotoLateralIzquierdoFileNameSelected.value !== null &&
        fotoLateralDerechoFileNameSelected.value !== null;

      if (!seHanCargadoTodasLasFotos && seHaCargadoAlgunaFoto) {
        dispatch(queueMessage("Deben cargarse todas las fotos del vehículo"));
        return;
      }

      fotosVehiculo = seHanCargadoTodasLasFotos
        ? [
            fotoFrontalFileNameSelected.value,
            fotoTraseraFileNameSelected.value,
            fotoLateralIzquierdoFileNameSelected.value,
            fotoLateralDerechoFileNameSelected.value,
          ]
        : null;
    }

    let data = {
      premioTotal: premioTotalSelected.value,
      fechaVigenciaDesde: vigenciaDesdeSelected.value,
      fechaVigenciaHasta: vigenciaHastaSelected.value,
      numeroPoliza: numeroPolizaSelected.value,
      cuotas: cuotasArray,
      polizaFileName: polizaFileNameSelected.value,
      tarjetaFileName: tarjetaFileNameSelected.value,
      cedulaMercosurFileName: cedulaMercosurFileNameSelected.value,
      cuponeraFileName: cuponeraFileNameSelected.value,
      dominio: dominioSelected.value,
      nroMotor: nroMotorSelected.value,
      nroChasis: nroChasisSelected.value,
      email: emailSelected.value,
      sumaAsegurada: sumaAseguradaSelected.value,
      fotosVehiculo: fotosVehiculo,
      cantidadCuotas: cantidadCuotasSelected.value,
      vigencia: vigenciaSelected.value,
    };

    dispatch(postEmisionManual(id, data));
  };

  useEffect(() => {
    if (downloadFiles) {
      zipAndDownloadFiles();
    }
    //eslint-disable-next-line
  }, [downloadFiles]);

  const backAction = () => {
    history.push(paths[solicitudesEmisionPath]);
  };

  if (!isAdminUser && detalleSolicitudEmision.estado === DATOSINCOMPLETOS)
    return <UnexpectedError />;

  if (loadingError) return <UnexpectedError />;

  return (
    <DetailsLayout
      title="Emisión de Póliza"
      backAction={backAction}
      subtitle={
        detalleSolicitudEmision
          ? estadosSolicitudEmisionAdminOptions[detalleSolicitudEmision.estado]
          : null
      }
      selectedDefault={solicitudesEmisionPath}
      data={
        <div className={classes.btnFinalizar}>
          {detalleSolicitudEmision &&
            detalleSolicitudEmision.negocio === "MercadoAbierto" && (
              <Tooltip title={isAdminUser ? "Rechazar" : "Devolver a Sucursal"}>
                <Button
                  className={classes.btnRechazar}
                  variant="outlined"
                  color="secondary"
                  onClick={handleRechazar}
                >
                  {isAdminUser
                    ? "Rechazar (sólo admin)"
                    : "Devolver a Sucursal"}
                </Button>
              </Tooltip>
            )}
          <Tooltip
            title={
              !numeroPolizaSelected.valid
                ? "Guardar como Pendiente en Compañía"
                : "Emitir Póliza a partir de esta Solicitud"
            }
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleGuardarSolicitud}
              startIcon={!numeroPolizaSelected.valid && <SaveIcon />}
            >
              {numeroPolizaSelected.valid
                ? "EMITIR PÓLIZA"
                : "PENDIENTE EN CIA"}
            </Button>
          </Tooltip>
        </div>
      }
      tabsComponent={() => (
        <DetailsTabs
          defaultValue={0}
          labels={[
            <TabIndex text="DETALLE" />,
            <TabIndex text="DOCUMENTACIÓN" />,
            <TabIndex text="HISTORIA" />,
          ]}
          onChange={(e) => {
            setCurrentTab(parseInt(e.target.value));
          }}
        />
      )}
    >
      {!isLoading && (
        <PopupRechazar
          isOpened={isPopupRechazarOpened}
          solicitudId={id}
          nombreSolicitante={
            detalleSolicitudEmision
              ? detalleSolicitudEmision.datosOriginador.nombre
              : null
          }
          creadaHace={formatDistanceToNow(
            Date.parse(detalleSolicitudEmision.fechaCreacion),
            { locale: es }
          )}
          onClickAceptar={handleConfirmarRechazo}
          onClose={handleClosePopupRechazo}
        />
      )}
      <SuccessDialog
        openDialog={solicitudRechazada}
        value={"Solicitud rechazada con éxito"}
      />
      <SuccessDialog
        openDialog={polizaCreada}
        value={
          numeroPolizaSelected.valid
            ? "Póliza creada con éxito"
            : "Solicitud guardada con éxito"
        }
      />

      {currentTab === 0 && <DetalleSolicitudEmision />}
      {currentTab === 1 && <Documentacion />}
      {currentTab === 2 && <Historia />}
    </DetailsLayout>
  );
};

export default DetalleSolicitudEmisionContainer;

const TabIndex = ({ text }) => {
  return (
    <>
      <span style={{ display: "flex", alignItems: "center" }}>{text}</span>
    </>
  );
};
