import React, { useState, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import filterItems from "~libs/filterItems";
import { useSelector, useDispatch } from "react-redux";
import SearcherList from "~styled/searcherList/SearcherList";
import { fetchVehiculoModelos } from "./actions";
import ListView from "~styled/listView/ListView";

const useStyles = makeStyles((theme) => ({
  searcher: {
    display: "flex",
    paddingTop: 10,
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around",
  },
  chip: {
    borderRadius: "6px",
    fontWeight: "bold",
    margin: 2,
    height: "inherit",
    paddingTop: 3,
    paddingBottom: 3,
    whiteSpace: "inherit",
  },
  chipLabel: {
    whiteSpace: "inherit",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.vehiculoModelosReducer.isLoading,
    modelos: state.vehiculoModelosReducer.modelos,
  }));

const VehiculoModeloSelector = memo(({ value, marca, anio, onChange }) => {
  const [filter, setFilter] = useState("");

  const { isLoading, modelos } = useReduxSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    if (marca.value && anio.value)
      dispatch(fetchVehiculoModelos(marca.value, anio.value));
  }, [dispatch, marca.value, anio.value]);

  const handleModelItem = (value) => {
    setFilter("");
    if (onChange) onChange({ target: { value } });
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const classes = useStyles();

  return (
    <>
      {!value.label && (
        <div className={classes.searcher}>
          <SearcherList
            onClear={() => handleModelItem({ value: null, label: null })}
            filter={filter}
            onChange={handleFilterChange}
            placeholder={"Buscar modelo"}
          />
          <ListView
            isLoading={isLoading}
            onItemSelected={handleModelItem}
            list={filter === "" ? modelos : filterItems(modelos, filter)}
          />
        </div>
      )}
    </>
  );
});

export default VehiculoModeloSelector;
