import React, { useEffect } from "react";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import { Tooltip, Button } from "@mui/material";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import logoPrendo from "~assets/logos/logo-prendo-MG.svg";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";
import BusquedaCard from "./cards/BusquedaCard";
import DatosSiniestroCard from "./cards/DatosSiniestroCard";
import DatosAseguradoCard from "./cards/DatosAseguradoCard";
import DatosConductorCard from "./cards/DatosConductorCard";
import DatosAutomotorCard from "./cards/DatosAutomotorCard";
import FechaLugarCard from "./cards/FechaLugarCard";
import DetalleSiniestroCard from "./cards/DetalleSiniestroCard";
import DatosTerceroCard from "./cards/DatosTerceroCard";
import DocumentacionCard from "./cards/DocumentacionCard";
import SuccessDialog from "./SuccessDialog";

import {
  postSiniestro,
  setSubmitting,
  clearData,
  setTipo,
  setNumeroDocumentoAsegurado,
  setNombreAsegurado,
  setTelefono,
  setMail,
  setCompania,
  setNumeroPoliza,
  setCalle,
  setAltura,
  setHora,
  setFecha,
  setLocalidad,
  setProvincia,
  setCodigoPostal,
  setLesionesFisicas,
  setComoOcurrio,
  setDaniosVehiculo,
  setNombreConductor,
  setApellidoConductor,
  setNumeroDocumentoConductor,
  setDominioTercero,
} from "./actions";
import { CHOQUE } from "~constants/tipoSiniestro";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "1.5%",
    minWidth: "10px",
    backgroundColor: `#793CFF`,
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "90%",
      flexWrap: "wrap",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "25px",
  },
  busquedaContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  helpIcon: {
    position: "absolute",
    right: "23.5%",
    color: "white",
    cursor: "pointer",
  },
  mainTitle: {
    color: "#FFFFFF",
    fontWeight: "bold",
    transform: "translateX(-100px)",
    fontSize: 42,
    paddingBottom: "25px",
    "@media (max-width:1024px)": {
      fontSize: 20,
      paddingTop: "25px",
      transform: "translateX(0)",
    },
  },
  logoPrendo: {
    alignSelf: "center",
    "@media (max-width:1024px)": {
      width: "100px",
      paddingTop: "20px",
    },
  },
  btnEnviar: {
    margin: "25px",
    backgroundColor: "#0398fc",
    borderRadius: "100px 100px 100px 100px",
    width: "242px",
    fontFamily: "Nunito",
    fontWeight: 800,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#00ace6",
      color: "#FFFFFF",
    },
  },
}));

const useReduxSelector = () =>
  useSelector((state) => {
    return {
      isLoading: state.cargaSiniestroPublicaReducer.isLoading,
      creatingSiniestro: state.cargaSiniestroPublicaReducer.creatingSiniestro,
      siniestroCreado: state.cargaSiniestroPublicaReducer.siniestroCreado,
      poliza: state.cargaSiniestroPublicaReducer.polizaVinculada,

      tipo: state.cargaSiniestroPublicaReducer.tipoSelected,
      compania: state.cargaSiniestroPublicaReducer.companiaSelected,
      numeroSiniestroCompania:
        state.cargaSiniestroPublicaReducer.numeroSiniestroCompaniaSelected,
      numeroPoliza: state.cargaSiniestroPublicaReducer.numeroPolizaSelected,
      idPoliza: state.cargaSiniestroPublicaReducer.idPolizaSelected,
      sucursal: state.cargaSiniestroPublicaReducer.sucursalSelected,
      oficinaId: state.cargaSiniestroPublicaReducer.oficinaIdSelected,
      nombreAsegurado:
        state.cargaSiniestroPublicaReducer.nombreAseguradoSelected,
      numeroDocumentoAsegurado:
        state.cargaSiniestroPublicaReducer.numeroDocumentoAseguradoSelected,
      telefono: state.cargaSiniestroPublicaReducer.telefonoSelected,
      mail: state.cargaSiniestroPublicaReducer.mailSelected,
      fecha: state.cargaSiniestroPublicaReducer.fechaSelected,
      hora: state.cargaSiniestroPublicaReducer.horaSelected,
      calle: state.cargaSiniestroPublicaReducer.calleSelected,
      altura: state.cargaSiniestroPublicaReducer.alturaSelected,
      localidad: state.cargaSiniestroPublicaReducer.localidadSelected,
      provincia: state.cargaSiniestroPublicaReducer.provinciaSelected,
      codigoPostal: state.cargaSiniestroPublicaReducer.codigoPostalSelected,
      lesionesFisicas:
        state.cargaSiniestroPublicaReducer.lesionesFisicasSelected,
      comoOcurrio: state.cargaSiniestroPublicaReducer.comoOcurrioSelected,
      daniosVehiculo: state.cargaSiniestroPublicaReducer.daniosVehiculoSelected,
      marca: state.cargaSiniestroPublicaReducer.marcaSelected,
      modeloAnio: state.cargaSiniestroPublicaReducer.modeloAnioSelected,
      dominio: state.cargaSiniestroPublicaReducer.dominioSelected,
      modelo: state.cargaSiniestroPublicaReducer.modeloSelected,
      nombreConductor:
        state.cargaSiniestroPublicaReducer.nombreConductorSelected,
      apellidoConductor:
        state.cargaSiniestroPublicaReducer.apellidoConductorSelected,
      numeroDocumentoConductor:
        state.cargaSiniestroPublicaReducer.numeroDocumentoConductorSelected,
      documentos: state.cargaSiniestroPublicaReducer.documentosSelected,
      nombreTercero: state.cargaSiniestroPublicaReducer.nombreTerceroSelected,
      apellidoTercero:
        state.cargaSiniestroPublicaReducer.apellidoTerceroSelected,
      numeroDocumentoTercero:
        state.cargaSiniestroPublicaReducer.numeroDocumentoTerceroSelected,
      numeroPolizaTercero:
        state.cargaSiniestroPublicaReducer.numeroPolizaTerceroSelected,
      companiaTercero:
        state.cargaSiniestroPublicaReducer.companiaTerceroSelected,
      telefonoTercero:
        state.cargaSiniestroPublicaReducer.telefonoTerceroSelected,
      dominioTercero: state.cargaSiniestroPublicaReducer.dominioTerceroSelected,
      marcaTercero: state.cargaSiniestroPublicaReducer.marcaTerceroSelected,
      modeloTercero: state.cargaSiniestroPublicaReducer.modeloTerceroSelected,
      daniosTercero: state.cargaSiniestroPublicaReducer.daniosTerceroSelected,
      isProcessingDocumentos:
        state.cargaSiniestroPublicaReducer.isProcessingDocumentos,
    };
  });

const CargaSiniestroPublica = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.mobile);
  const {
    isLoading,
    creatingSiniestro,
    siniestroCreado,
    tipo,
    compania,
    numeroPoliza,
    idPoliza,
    sucursal,
    oficinaId,
    mail,
    telefono,
    numeroDocumentoAsegurado,
    nombreAsegurado,
    fecha,
    hora,
    calle,
    altura,
    localidad,
    provincia,
    codigoPostal,
    lesionesFisicas,
    comoOcurrio,
    daniosVehiculo,
    nombreConductor,
    apellidoConductor,
    numeroDocumentoConductor,
    documentos,
    modelo,
    modeloAnio,
    marca,
    dominio,
    nombreTercero,
    apellidoTercero,
    numeroDocumentoTercero,
    numeroPolizaTercero,
    companiaTercero,
    telefonoTercero,
    dominioTercero,
    marcaTercero,
    modeloTercero,
    daniosTercero,
    isProcessingDocumentos,
  } = useReduxSelector();

  const infoBusquedaText =
    "Buscá tu póliza con tu DNI o el dominio de tu auto. Si tenés más de una póliza vigente con Prendo, elegí la correspondiente al auto siniestrado.";

  useEffect(() => {
    document.title = "Denunciá tu siniestro";
    let link = document.querySelector("link[rel~='icon']");
    link.href = "https://lira.mg-group.com.ar/faviconPrendo.png";
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
    //eslint-disable-next-line
  }, [dispatch]);

  const handleGuardarSolicitud = () => {
    dispatch(setSubmitting(true));

    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    let inputs = [
      { setFocusFunction: setTipo, ...tipo },
      { setFocusFunction: setCompania, ...compania },
      { setFocusFunction: setNumeroPoliza, ...numeroPoliza },
      { setFocusFunction: setNombreAsegurado, ...nombreAsegurado },
      {
        setFocusFunction: setNumeroDocumentoAsegurado,
        ...numeroDocumentoAsegurado,
      },
      { setFocusFunction: setMail, ...mail },
      { setFocusFunction: setTelefono, ...telefono },
      { setFocusFunction: setFecha, ...fecha },
      { setFocusFunction: setHora, ...hora },
      { setFocusFunction: setCalle, ...calle },
      { setFocusFunction: setAltura, ...altura },
      { setFocusFunction: setLocalidad, ...localidad },
      { setFocusFunction: setProvincia, ...provincia },
      { setFocusFunction: setCodigoPostal, ...codigoPostal },
      { setFocusFunction: setLesionesFisicas, ...lesionesFisicas },
      { setFocusFunction: setComoOcurrio, ...comoOcurrio },
      { setFocusFunction: setDaniosVehiculo, ...daniosVehiculo },
      { setFocusFunction: setNombreConductor, ...nombreConductor },
      { setFocusFunction: setApellidoConductor, ...apellidoConductor },
      {
        setFocusFunction: setNumeroDocumentoConductor,
        ...numeroDocumentoConductor,
      },
    ];

    tipo.value === CHOQUE &&
      inputs.push({ setFocusFunction: setDominioTercero, ...dominioTercero });

    let inputToFocus = inputs.find((input) => !input.valid);

    if (inputToFocus)
      dispatch(
        inputToFocus.setFocusFunction({
          value: inputToFocus.value,
          valid: inputToFocus.valid,
          focus: true,
        })
      );
    if (inputToFocus) {
      dispatch(queueMessage("Hay campos sin completar o no válidos"));
      return;
    }

    if (isProcessingDocumentos) {
      dispatch(
        queueMessage(
          "Aguarda a que todos los archivos se hayan subido completamente."
        )
      );
      return;
    }

    let data = {
      tipo: tipo.value,
      compania: compania.value,
      numeroPoliza: numeroPoliza.value,
      idPoliza: idPoliza.value,
      sucursal: sucursal.value,
      oficinaId: oficinaId.value,
      fechaSiniestro: fecha.value,
      hora: hora.value,
      calle: calle.value,
      altura: altura.value,
      provincia: provincia.value,
      localidad: localidad.value,
      codigoPostal: codigoPostal.value,
      lesionesFisicas: lesionesFisicas.value,
      comoOcurrio: comoOcurrio.value,
      daniosVehiculoAsegurado: daniosVehiculo.value,
      numeroDocumentoAsegurado: numeroDocumentoAsegurado.value,
      nombreAsegurado: nombreAsegurado.value,
      email: mail.value,
      telefono: telefono.value,
      dominio: dominio.value,
      modeloAnio: modeloAnio.value,
      marca: marca.value,
      modelo: modelo.value,
      nombreConductor: nombreConductor.value,
      apellidoConductor: apellidoConductor.value,
      numeroDocumentoConductor: numeroDocumentoConductor.value,
      nombreTercero: nombreTercero.value,
      apellidoTercero: apellidoTercero.value,
      numeroDocumentoTercero: numeroDocumentoTercero.value,
      companiaTercero: companiaTercero.value,
      numeroPolizaTercero: numeroPolizaTercero.value,
      telefonoTercero: telefonoTercero.value,
      dominioTercero: dominioTercero.value,
      marcaTercero: marcaTercero.value,
      modeloTercero: modeloTercero.value,
      daniosTercero: daniosTercero.value,
      documentacion: documentos,
    };
    dispatch(postSiniestro(data));
  };

  return (
    <>
      {creatingSiniestro && (
        <div className={classes.root}>
          <div className={classes.body}>
            <TopBar />
            <LoadingViewGears />
          </div>
        </div>
      )}
      {!creatingSiniestro && (
        <div className={classes.root}>
          <div className={classes.body}>
            <SuccessDialog
              openDialog={siniestroCreado}
              value={"Siniestro cargado con éxito"}
            />

            <TopBar />
            <div className={classes.busquedaContainer}>
              <BusquedaCard isLoading={isLoading} />
              {!isMobile && (
                <Tooltip
                  arrow={true}
                  placement={"right"}
                  title={<p style={{ fontSize: 15 }}>{infoBusquedaText}</p>}
                >
                  <HelpIcon className={classes.helpIcon} />
                </Tooltip>
              )}
            </div>

            <div className={classes.cardsAndCuotasContainer}>
              <CardsColumn>
                <DatosSiniestroCard isLoading={isLoading} />
                <DatosAseguradoCard isLoading={isLoading} />
                <DatosAutomotorCard isLoading={isLoading} />
                <DatosConductorCard isLoading={isLoading} />
                <FechaLugarCard isLoading={isLoading} />
                <DetalleSiniestroCard isLoading={isLoading} />
                <DatosTerceroCard isLoading={isLoading} />
                <DocumentacionCard isLoading={isLoading} />
              </CardsColumn>
            </div>
          </div>
          <div className={classes.btnContainer}>
            <Button
              variant="contained"
              size="large"
              onClick={handleGuardarSolicitud}
              className={classes.btnEnviar}
            >
              {"Enviar"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CargaSiniestroPublica;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};

const TopBar = () => {
  const classes = useStyles();
  return (
    <div className={classes.topBar}>
      <div>
        <a
          href={process.env.REACT_APP_MARKETPLACE_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={logoPrendo}
            alt="Prendo Logo"
            className={classes.logoPrendo}
            width={180}
          />
        </a>
      </div>

      <Typography variant="h2" className={classes.mainTitle}>
        {"Denunciá tu Siniestro"}
      </Typography>
      <div />
    </div>
  );
};
