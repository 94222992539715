import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMarcaTarjetaCredito, clearMarcaTarjetaCredito } from "../actions";
import { makeStyles } from "@mui/styles";
import MarcaTarjetaCreditoSelector from "~business/marcaTarjetaCreditoSelector/marcaTarjetaCreditoSelector";
import PrimaryTitle from "~styled/primaryTitle/PrimaryTitle";

const useStyles = makeStyles((theme) => ({
  cotizador: {
    flexDirection: "row",
    margin: "auto",
    padding: 20,
    paddingBottom: 100,
    maxWidth: 500,
    width: "100%",
    minHeight: "100vh",
  },
  title: {
    paddingBottom: "16px",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    marcaTarjetaCreditoSelected:
      state.multicotizadorReducer.marcaTarjetaCreditoSelected,
  }));

const MarcaTarjetaCreditoStep = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { marcaTarjetaCreditoSelected } = useReduxSelector();

  useEffect(() => {
    dispatch(clearMarcaTarjetaCredito());
  }, [dispatch]);

  const handleChange = (event) => {
    dispatch(setMarcaTarjetaCredito({ ...event.target.value, selected: true }));
  };

  return (
    <>
      <div className={classes.cotizador}>
        <div className={classes.title}>
          <PrimaryTitle
            label="¿Cuál es la marca de la tarjeta de crédito?"
            marginBottom={32}
          />
        </div>
        <MarcaTarjetaCreditoSelector
          value={marcaTarjetaCreditoSelected}
          onChange={handleChange}
        />
      </div>
    </>
  );
});

export default MarcaTarjetaCreditoStep;
