import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import pxToRem from "~libs/pxToRem";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import ItemLoadingCard from "./ItemLoadingCard";
import IconEdit from "~assets/icons/ic_edit.svg";
import useField from "~hooks/useField";
import InfoOrange from "~assets/icons/info-orange.png";
import WhatsappIcon from "~assets/icons/whatsapp.svg";
//import pxToRem from "../../../libs/pxToRem";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2%",
    borderBottom: "1px solid #8B8B8B",
  },
  caption: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#8B8B8B",
  },
  body: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    maxWidth: pxToRem(270),
    width: "50%",
  },
  value: {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.38,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#424242",
  },
  input: {
    padding: "5%",
    textAlign: "end",
    width: "100%",
    "& .Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
    "&:Mui-error": {
      borderRadius: "4px",
      border: "solid 1.7px #f77221",
    },
  },
  buttonLink: {
    display: "flex",
    alignItems: "center",
    color: "#f77221",
    paddingLeft: 8,
    cursor: "pointer",
  },
}));

const errorText = "Requerido";

const ItemInputCard = ({
  id,
  label,
  value,
  onChange,
  placeholder,
  required,
  displayError,
  focus,
  onBlur,
  readOnly,
  isLoading,
  maxLength,
  multiline,
}) => {
  const classes = useStyles();

  const validateFunction = useCallback(handleValidate(required), [required]);

  const [localValue, setValue, handleEdit, reference] = useField(
    value,
    validateFunction,
    focus,
    isLoading,
    readOnly
  );

  const handleBlur = () => {
    if (onBlur) onBlur();
  };

  const handleChangeValue = (value) => {
    setValue((prev) => ({ ...prev, value: value }));

    if (onChange)
      onChange({
        value: value,
        valid: validateFunction(value),
        focus: false,
      });
  };

  const showError = !localValue.valid && displayError;

  if (isLoading) return <ItemLoadingCard label={label} />;

  return (
    <div className={classes.container}>
      <Typography className={classes.caption}>{label}</Typography>
      <div className={classes.body}>
        {localValue.editing && (
          <TextField
            id={id}
            style={{ width: "100%" }}
            inputProps={{
              maxLength: maxLength,
              className: classes.input,
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
            onChange={(event) => handleChangeValue(event.target.value)}
            onBlur={handleBlur}
            value={localValue.value}
            variant="outlined"
            placeholder={placeholder}
            error={showError}
            inputRef={reference}
            multiline={multiline}
          />
        )}

        {!localValue.editing && (
          <>
            {value && (
              <Typography className={classes.value}>{value}</Typography>
            )}
            {!value && <div>&mdash;</div>}
            {readOnly && renderWhatsappIcon(id, value)}
            {!readOnly && (
              <div
                className={classes.buttonLink}
                onClick={() => handleEdit(true)}
              >
                <img alt="" src={IconEdit} className={classes.icon} />
              </div>
            )}
          </>
        )}
        {!localValue.valid && (
          <Tooltip title={errorText}>
            <div className={classes.buttonLink}>
              <img alt="Requerido" src={InfoOrange}></img>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ItemInputCard;

const renderWhatsappIcon = (id, value) => {
  let telefonoWhatsappFormat = `54${value
    ?.toString()
    .replace(/^0|15/, "")
    .replace(/\s/g, "")}`;

  if (id === "telefono") {
    return (
      <Tooltip title="Chatear por Whatsapp">
        <a
          href={"https://wa.me/" + telefonoWhatsappFormat}
          rel="noopener noreferrer"
          target="_blank"
          tool="true"
          style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}
        >
          <img
            alt=""
            src={WhatsappIcon}
            style={{ width: "1.3em", color: "#4aba7c" }}
          />
        </a>
      </Tooltip>
    );
  }
  return null;
};

const handleValidate = (required) => (value) => {
  const isDataValid = !required || value ? true : false;

  return isDataValid;
};

// const handleValidate = (required) => (value) => {
//   const isDataValid = !required || value;

//   return isDataValid;
// }; el isDataValid devolvia el valor del value y no un booleano
