import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  searchPaper: {
    display: "flex",
    border: "solid 1px #e4e4e6",
    backgroundColor: theme.colours.white,
    justifyContent: "center",
    alignItems: "baseline",
    width: "100%",
  },
  buttomWithoutRadious: {
    borderRadius: "5px",
  },
  searchPaperBottomSquared: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    display: "flex",
    flex: 1,
    fontSize: 12,
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey900,
    paddingLeft: "8px",
  },
  iconSearch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    paddingRight: 10,
    color: theme.colours.brownGrey2,
  },
  iconButton: {
    color: theme.colours.violeta,
    padding: 0,
  },
}));

const Searcher = ({
  filter,
  onChange,
  onClear,
  placeholder,
  borderBottomRounded,
  borderSquared,
}) => {
  const inputRef = useRef(null);

  const [value, setValue] = useState(filter);

  useEffect(() => {
    setValue(filter);
  }, [filter]);

  const handleChange = (event) => {
    const value = event.target.value;
    setValue(value);
  };

  const handleClickEnter = (event) => {
    if (onChange && event.key === "Enter") onChange({ target: { value } });
  };

  const handleBlur = () => {
    if (onChange) onChange({ target: { value } });
  };

  const handleClear = () => {
    setValue("");
    if (onClear) onClear();
  };

  const classes = useStyles();

  const filtering = value && value.length > 0;

  return (
    <div
      style={borderSquared ? { borderRadius: "5px" } : { borderRadius: "20px" }}
      className={
        filtering || !borderBottomRounded
          ? [classes.searchPaper, classes.searchPaperBottomSquared].join(" ")
          : classes.searchPaper
      }
    >
      <InputBase
        inputRef={inputRef}
        className={classes.input}
        placeholder={placeholder}
        onBlur={handleBlur}
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => handleClickEnter(e)}
      />
      {filtering && (
        <IconButton className={classes.iconButton} onClick={handleClear}>
          <Close />
        </IconButton>
      )}
      <div
        onClick={() => {
          if (inputRef.current) inputRef.current.focus();
        }}
        className={classes.iconSearch}
      >
        <SearchIcon />
      </div>
    </div>
  );
};

export default Searcher;
