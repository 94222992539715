import React from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { selectSearchCriteria, selectNegocio, selectEstado } from "./actions";
import { setPage, setTake } from "../Filtros/actions";
import { selectFechaDesde, selectFechaHasta } from "./actions";
import { calculateDate } from "~libs/dateCalculator";
import Searcher from "~styled/searcher/searcher";
import Selector from "~styled/selector/Selector";
import { negociosFilter, negociosSegurosOptions } from "~constants/negocios";
import { Typography } from "@mui/material";
import Pagination from "~styled/pagination/Pagination";
import DateRangePicker from "~styled/dateRangePicker/DateRangePicker";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import { paginationOptions } from "~constants/pagination";
import {
  estadosMulticotizacionOptions,
  estadosMulticotizacionFilter,
} from "~constants/multicotizacionEstados";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: 20,
    width: "100%",
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBottom: "1%",
  },
  label: {
    fontSize: "10px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.3",
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    paddingLeft: 5,
    paddingBottom: 5,
  },
  dateSelectorAndTitle: {
    display: "flex",
    flexDirection: "column",
    minWidth: 250,
  },
  horizontalSpace: { paddingRight: "10px", minWidth: 250 },
  horizontalSpaceSearcher: { paddingRight: "10px", minWidth: 250 },
  textItemContainer: { display: "flex", flexDirection: "column" },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    isLoading: state.cotizacionesReducer.isLoading,
    totalRegistros: state.cotizacionesReducer.totalRegistros,

    take: state.filtrosCotizacionesReducer.take,
    page: state.filtrosCotizacionesReducer.page,
    fechaDesde: state.filtrosCotizacionesReducer.fechaDesde,
    fechaHasta: state.filtrosCotizacionesReducer.fechaHasta,
    searchCriteria: state.filtrosCotizacionesReducer.searchCriteria,
    negocio: state.filtrosCotizacionesReducer.negocio,
    estado: state.filtrosCotizacionesReducer.estado,
  }));

const messageLoading = "Cargando su consulta previa";

const Filtros = () => {
  const {
    isLoading,
    take,
    fechaDesde,
    fechaHasta,
    searchCriteria,
    negocio,
    estado,
    page,
    totalRegistros,
  } = useReduxSelector();

  const dispatch = useDispatch();

  const handleChange = (event, action) => {
    dispatch(setPage(0));
    dispatch(action(event.target.value));
  };

  const handleChangeNegocio = (value) => {
    dispatch(setPage(0));
    dispatch(selectNegocio(value));
  };

  const handleChangeEstado = (value) => {
    dispatch(setPage(0));
    dispatch(selectEstado(value));
  };

  const handleChangeInterval = (value) => {
    dispatch(setPage(0));
    dispatch(selectFechaDesde(calculateDate(new Date(), value)));
    dispatch(selectFechaHasta(new Date()));
  };

  const handleChangePage = (value) => {
    dispatch(setPage(value));
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <>
        <div className={classes.filtersContainer}>
          <div className={classes.filters}>
            <div className={classes.textItemContainer}>
              <Typography variant="h3" className={classes.label}>
                BUSCAR
              </Typography>
              <div className={classes.horizontalSpaceSearcher}>
                <Searcher
                  borderSquared
                  value={searchCriteria}
                  filter={searchCriteria}
                  placeholder="Vehículo, Nombre/Apellido, Dominio"
                  onChange={(event) =>
                    handleChange(event, selectSearchCriteria)
                  }
                  onClear={() =>
                    handleChange(
                      { target: { value: "" } },
                      selectSearchCriteria
                    )
                  }
                />
              </div>
            </div>
            <div className={classes.dateSelectorAndTitle}>
              <Typography variant="h3" className={classes.label}>
                FECHA DE INGRESO
              </Typography>
              <div className={classes.horizontalSpace}>
                <DateRangePicker
                  fechaDesde={fechaDesde}
                  fechaHasta={fechaHasta}
                  onChangeInterval={handleChangeInterval}
                  onChangeStartDate={(value) =>
                    handleChange({ target: { value } }, selectFechaDesde)
                  }
                  onChangeEndDate={(value) =>
                    handleChange({ target: { value } }, selectFechaHasta)
                  }
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className={classes.textItemContainer}>
              <Typography variant="h3" className={classes.label}>
                NEGOCIO
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={negociosSegurosOptions[negocio]}
                  options={negociosFilter}
                  onChange={handleChangeNegocio}
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.filtersContainer}>
          <div className={classes.filters}>
            <div className={classes.textItemContainer}>
              <Typography variant="h3" className={classes.label}>
                ESTADO
              </Typography>

              <div className={classes.horizontalSpace}>
                <Selector
                  value={estadosMulticotizacionOptions[estado]}
                  options={estadosMulticotizacionFilter}
                  onChange={handleChangeEstado}
                  onClickDisabled={() => {
                    dispatch(queueMessage(messageLoading));
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>

          {!isLoading && (
            <div className={classes.filters}>
              <Pagination
                rowsPerPage={take}
                onChangeRowsPerPage={(value) =>
                  handleChange({ target: { value } }, setTake)
                }
                page={page}
                onChangePage={handleChangePage}
                totalRegistros={totalRegistros}
                options={paginationOptions}
              />
            </div>
          )}
        </div>
      </>
      <></>
    </div>
  );
};

export default Filtros;
