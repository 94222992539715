import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { paths, polizasPath } from "~constants/referenceNames";
import Layout from "~shared/layout/Layout";
import { queueMessage } from "~shared/snackbarsProvider/actions";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import { Tooltip, Button } from "@mui/material";
import AseguradoCard from "./cards/AseguradoCard";
import { useParams } from "react-router";
import DatosVehiculoCard from "./cards/DatosVehiculoCard";
import DatosPolizaCard from "./cards/DatosPolizaCard";
import ImpresosCard from "./cards/ImpresosCard";
import CuotasCard from "./cards/CuotasCard";

import LoadingViewGears from "~styled/loadingView/LoadingViewGears";
import CustomMessageError from "~styled/errorPages/CustomMessageError";
import SuccessDialog from "./SuccessDialog";

import startOfDay from "date-fns/startOfDay";
import differenceInDays from "date-fns/differenceInDays";

import {
  getPoliza,
  setSubmitting,
  setPremioTotal,
  setVigenciaDesde,
  setVigenciaHasta,
  //clearCuotas,
  //setBase64File,
  setSumaAsegurada,
  postRenovarPoliza,
  setNumeroPoliza,
  getCoberturas,
  setCobertura,
  postValidatePoliza,
  setCuota,
  clearData,
} from "./actions";
import { useFlags } from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "1.5%",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },

  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },

  cardItem: { minWidth: 300 },

  btnFinalizar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  btnRechazar: {
    marginRight: "2%",
  },
  errorMessage: {
    display: "flex",
    flexDirection: "column",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    failed: state.renovacionPolizaReducer.failed,
    isLoading: state.renovacionPolizaReducer.isLoading,
    submitting: state.renovacionPolizaReducer.submitting,
    premioTotalSelected: state.renovacionPolizaReducer.premioTotalSelected,
    sumaAseguradaSelected: state.renovacionPolizaReducer.sumaAseguradaSelected,
    vigenciaDesdeSelected: state.renovacionPolizaReducer.vigenciaDesdeSelected,
    vigenciaHastaSelected: state.renovacionPolizaReducer.vigenciaHastaSelected,
    numeroPolizaSelected: state.renovacionPolizaReducer.numeroPolizaSelected,
    cantidadCuotasSelected:
      state.renovacionPolizaReducer.cantidadCuotasSelected,
    polizaFileNameSelected:
      state.renovacionPolizaReducer.polizaFileNameSelected,
    tarjetaFileNameSelected:
      state.renovacionPolizaReducer.tarjetaFileNameSelected,
    cedulaMercosurFileNameSelected:
      state.renovacionPolizaReducer.cedulaMercosurFileNameSelected,
    cuponeraFileNameSelected:
      state.renovacionPolizaReducer.cuponeraFileNameSelected,
    isProcessingPoliza: state.renovacionPolizaReducer.isProcessingPoliza,
    isProcessingTarjeta: state.renovacionPolizaReducer.isProcessingTarjeta,
    isProcessingCedulaMercosur:
      state.renovacionPolizaReducer.isProcessingCedulaMercosur,
    isProcessingCuponera: state.renovacionPolizaReducer.isProcessingCuponera,
    vigenciaSelected: state.renovacionPolizaReducer.vigenciaSelected,
    currentPoliza: state.renovacionPolizaReducer.currentPoliza,
    coberturaSelected: state.renovacionPolizaReducer.coberturaSelected,
    polizaValidaParaRenovacion:
      state.renovacionPolizaReducer.polizaValidaParaRenovacion,
    polizaRenovada: state.renovacionPolizaReducer.polizaRenovada,
    validatingPoliza: state.renovacionPolizaReducer.validatingPoliza,
    cuotasSelected: state.renovacionPolizaReducer.cuotasSelected,
  }));

const RenovacionPoliza = () => {
  const flags = useFlags();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    failed,
    isLoading,
    submitting,
    premioTotalSelected,
    sumaAseguradaSelected,
    vigenciaDesdeSelected,
    vigenciaHastaSelected,
    numeroPolizaSelected,
    polizaFileNameSelected,
    tarjetaFileNameSelected,
    cedulaMercosurFileNameSelected,
    cuponeraFileNameSelected,
    cantidadCuotasSelected,
    isProcessingPoliza,
    isProcessingTarjeta,
    isProcessingCedulaMercosur,
    isProcessingCuponera,
    vigenciaSelected,
    currentPoliza,
    coberturaSelected,
    polizaValidaParaRenovacion,
    polizaRenovada,
    validatingPoliza,
    cuotasSelected,
  } = useReduxSelector();

  const { id } = useParams();

  const handleRenovarPoliza = () => {
    dispatch(setSubmitting(true));

    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    const inputs = [
      { setFocusFunction: setCobertura, ...coberturaSelected },
      { setFocusFunction: setNumeroPoliza, ...numeroPolizaSelected },
      { setFocusFunction: setPremioTotal, ...premioTotalSelected },
      { setFocusFunction: setSumaAsegurada, ...sumaAseguradaSelected },
      { setFocusFunction: setVigenciaDesde, ...vigenciaDesdeSelected },
      { setFocusFunction: setVigenciaHasta, ...vigenciaHastaSelected },
    ];

    let inputToFocus = inputs.find((input) => !input.valid);

    if (inputToFocus)
      dispatch(
        inputToFocus.setFocusFunction({
          value: inputToFocus.value,
          valid: inputToFocus.valid,
          focus: true,
        })
      );

    const cuotasInputs = Object.keys(cuotasSelected).map((key) => {
      return {
        setFocusFunction: setCuota,
        ...cuotasSelected[key],
      };
    });

    let cuotaInputToFocus = cuotasInputs.find(
      (input) => !input.importe.valid || !input.fechaVencimiento.valid
    );

    if (cuotaInputToFocus)
      dispatch(
        cuotaInputToFocus.setFocusFunction({
          ...cuotaInputToFocus,
          importe: {
            value: cuotaInputToFocus.importe.value,
            valid: cuotaInputToFocus.importe.valid,
            focus: !cuotaInputToFocus.importe.valid,
          },
          fechaVencimiento: {
            value: cuotaInputToFocus.fechaVencimiento.value,
            valid: cuotaInputToFocus.fechaVencimiento.valid,
            focus: !cuotaInputToFocus.fechaVencimiento.valid,
          },
        })
      );

    if (inputToFocus || cuotaInputToFocus) {
      dispatch(queueMessage("Hay campos sin completar o no válidos"));
      return;
    }

    if (
      isProcessingPoliza ||
      isProcessingTarjeta ||
      isProcessingCedulaMercosur ||
      isProcessingCuponera
    ) {
      dispatch(
        queueMessage(
          "Aguarda a que todos los archivos se hayan subido completamente."
        )
      );
      return;
    }

    if (
      currentPoliza.negocio === "Prendarios" &&
      differenceInDays(
        Date.parse(cuotasSelected[1].fechaVencimiento.value),
        startOfDay(Date.parse(vigenciaDesdeSelected.value))
      ) < 0
    ) {
      dispatch(
        queueMessage(
          "La fecha de vencimiento de las cuotas no pueden ser menores a la vigencia desde"
        )
      );
      return;
    }

    if (polizaFileNameSelected.value === null) {
      dispatch(queueMessage("Debe cargarse el PDF de Póliza"));
      return;
    }

    if (
      currentPoliza.negocio === "Prendarios" &&
      tarjetaFileNameSelected.value === null
    ) {
      dispatch(queueMessage("Debe cargarse el PDF de Tarjeta"));
      return;
    }

    let cuotasArray = cuotasSelector(cuotasSelected);

    let sumaImportesCuotas = 0;
    cuotasArray.forEach(
      (element) => (sumaImportesCuotas += parseFloat(element.importe))
    );

    if (
      currentPoliza.negocio === "Prendarios" &&
      Math.round(
        (parseFloat(premioTotalSelected.value) + Number.EPSILON) * 100
      ) /
        100 !==
        Math.round((sumaImportesCuotas + Number.EPSILON) * 100) / 100
    ) {
      dispatch(
        queueMessage("La suma de cuotas no coinciden con el premio total")
      );
      return;
    }

    let data = {
      premioTotal: premioTotalSelected.value,
      vigenciaDesde: vigenciaDesdeSelected.value,
      vigenciaHasta: vigenciaHastaSelected.value,
      numeroPoliza: numeroPolizaSelected.value,
      cuotas: cuotasArray,
      polizaFileName: polizaFileNameSelected.value,
      tarjetaFileName: tarjetaFileNameSelected.value,
      cedulaMercosurFileName: cedulaMercosurFileNameSelected.value,
      cuponeraFileName: cuponeraFileNameSelected.value,
      sumaAsegurada: sumaAseguradaSelected.value,
      cantidadCuotas: cantidadCuotasSelected.value,
      vigencia: vigenciaSelected.value,
      coberturaSeleccionadaId: coberturaSelected.value,
    };

    dispatch(postRenovarPoliza(id, data));
  };

  const handleBlur = () => {
    dispatch(setSubmitting(false));
  };

  // transform to array
  const cuotasSelector = (cuotasObj) => {
    let array = Object.keys(cuotasObj).map((cuotasKey) => cuotasObj[cuotasKey]);
    let result = array.map((elem) => ({
      importe: elem.importe.value,
      fechaVencimiento: elem.fechaVencimiento.value,
    }));

    return result;
  };

  useEffect(() => {
    dispatch(postValidatePoliza(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!validatingPoliza && polizaValidaParaRenovacion)
      dispatch(getPoliza(id));
    return () => {
      if (polizaRenovada || !polizaValidaParaRenovacion) dispatch(clearData());
    };
    //eslint-disable-next-line
  }, [validatingPoliza]);

  useEffect(() => {
    if (currentPoliza)
      dispatch(
        getCoberturas(currentPoliza.datosPlan.compania, currentPoliza.negocio)
      );
    //eslint-disable-next-line
  }, [currentPoliza]);

  const backAction = () => {
    history.push(paths[polizasPath]);
  };

  if (failed) return <UnexpectedError />;
  if (!flags["renovacionesLira"]) return <UnexpectedError />;

  if (!polizaValidaParaRenovacion) {
    return (
      <CustomMessageError
        errorMessage={
          <div className={classes.errorMessage}>
            {"La póliza no es válida para renovación"}
          </div>
        }
      />
    );
  }

  return (
    <>
      <Layout
        fixed
        title={"Renovación de Póliza"}
        selectedDefault={polizasPath}
        backAction={backAction}
        data={
          <div className={classes.btnFinalizar}>
            <Tooltip title={"Renovar póliza"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRenovarPoliza}
              >
                {"RENOVAR PÓLIZA"}
              </Button>
            </Tooltip>
          </div>
        }
      >
        <SuccessDialog
          openDialog={polizaRenovada}
          value={"Póliza renovada con éxito"}
        />
        {isLoading && <LoadingViewGears />}
        {!isLoading && (
          <div className={classes.cardsAndCuotasContainer}>
            <CardsColumn>
              <DatosVehiculoCard
                editable
                isLoading={isLoading}
                displayError={submitting}
                onBlur={handleBlur}
              />
              <AseguradoCard
                editable
                isLoading={isLoading}
                displayError={submitting}
                onBlur={handleBlur}
              />
            </CardsColumn>
            <CardsColumn>
              <DatosPolizaCard
                isLoading={isLoading}
                onBlur={handleBlur}
                displayError={submitting}
              />
              {currentPoliza.negocio === "Prendarios" && (
                <CuotasCard
                  editable
                  isLoading={isLoading}
                  displayError={submitting}
                  onBlur={handleBlur}
                  cuotas={cuotasSelected}
                />
              )}
              <ImpresosCard isLoading={isLoading} />
              {/* <OriginadorCard isLoading={isLoading} /> */}
            </CardsColumn>
          </div>
        )}
      </Layout>
    </>
  );
};

export default RenovacionPoliza;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
