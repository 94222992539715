import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import LoadingViewGears from "~styled/loadingView/LoadingViewGears";
import UnexpectedError from "~styled/errorPages/UnexpectedError";
import { estadosSolicitudEmisionFilter } from "../listado/filtros/estados";
import { useParams, useHistory } from "react-router";
import CustomMessageError from "~styled/errorPages/CustomMessageError";
import Link from "@mui/material/Link";
import AseguradoCard from "./cards/AseguradoCard";
import DatosEmisionCard from "./cards/DatosEmisionCard";
import VehiculoCard from "./cards/VehiculoCard";
import MotivoRechazoCard from "./cards/MotivoRechazoCard";
import OriginadorCard from "./cards/OriginadorCard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "4.5%",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  cardsAndCuotasContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  cards: {
    display: "flex",
    paddingBottom: "30px",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "50%",
    "@media (max-width:1024px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  errorMessage: {
    display: "flex",
    flexDirection: "column",
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    detalleSolicitudEmision:
      state.detalleSolicitudHistoricaReducer.detalleSolicitudEmision,
    isLoading: state.detalleSolicitudHistoricaReducer.isLoading,
    loadingError: state.detalleSolicitudHistoricaReducer.loadingError,
    errorMessage: state.detalleSolicitudHistoricaReducer.errorMessage,
  }));

const DetalleSolicitudHistorica = (props) => {
  const { detalleSolicitudEmision, isLoading, loadingError } =
    useReduxSelector();
  const { id } = useParams();
  const classes = useStyles();
  let history = useHistory();

  if (
    !isLoading &&
    !estadosSolicitudEmisionFilter.some(
      (estadoValido) => estadoValido.value === detalleSolicitudEmision.estado
    )
  ) {
    return (
      <CustomMessageError
        errorMessage={
          <div className={classes.errorMessage}>
            {`La solicitud se encuentra en estado ${detalleSolicitudEmision.estado}.`}
            <Link
              href="#"
              onClick={() =>
                history.push(`/riesgos/automotores/solicitudesPendientes/${id}`)
              }
            >
              Ver en bandeja de solicitudes pendientes
            </Link>
          </div>
        }
      />
    );
  }

  if (loadingError) return <UnexpectedError />;

  return (
    <>
      <div className={classes.root}>
        {isLoading && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoadingViewGears />
          </div>
        )}
        {!isLoading && (
          <div>
            <div className={classes.body}>
              <div className={classes.cardsAndCuotasContainer}>
                <CardsColumn>
                  <AseguradoCard />
                  <DatosEmisionCard />
                  <MotivoRechazoCard />
                </CardsColumn>
                <CardsColumn>
                  <VehiculoCard />
                  <OriginadorCard />
                </CardsColumn>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DetalleSolicitudHistorica;

const CardsColumn = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.cards}>{children}</div>;
};
