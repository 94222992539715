import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import pxToRem from "~libs/pxToRem";

import waveMobile from "~assets/shapes/wave-mobile.svg";
import logo from "~assets/logos/mg-group-logo-white.svg";
import logoDesktop from "~assets/logos/mg-lira-logo-color.svg";

import { Link, useLocation } from "react-router-dom";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import environment from "~libs/environment";

import { menuClicked } from "./actions";

const NAV_BAR_HEIGHT = 56;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  main: {
    backgroundColor: "#f9f7fc",
    flex: 1,
    paddingTop: 0,
    minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
    background:
      "linear-gradient(180deg, rgba(102,62,168,1) 0%, rgba(51,31,84,1) 100%)",
    backgroundSize: "100vw calc(31vh - 90px)",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    [theme.breakpoints.mobile]: {
      backgroundImage: `url(${waveMobile})`,
      backgroundSize: "100vw calc(31vh - 30px)",
    },
  },
  columnCentered: {
    padding: theme.typography.pxToRem(16),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  logoSize: {
    width: "15vh",
    [theme.breakpoints.mobile]: {
      width: "15vh",
    },
  },
  logoFigure: {
    marginTop: "3vh",
    minHeight: "12vh",
    display: "flex",
    margin: 0,
    [theme.breakpoints.mobile]: {
      minHeight: "8vh",
    },
  },
}));

const NavbarLayout = ({ children, navbar, topbar }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.mobile);

  return (
    <div className={!isMobile ? classes.container : null}>
      {!isMobile && (
        <>
          <DesktopNavBar items={navbar} />
        </>
      )}
      <div className={classes.main}>
        {topbar && topbar()}
        {!topbar && (
          <div className={classes.columnCentered}>
            <figure className={classes.logoFigure}>
              <img
                src={logo}
                alt="MG-Group logo"
                className={classes.logoSize}
              />
            </figure>
          </div>
        )}
        <div className={classes.columnCentered}>{children}</div>
      </div>
      {isMobile && <MobileNavBar items={navbar} />}
    </div>
  );
};

export default NavbarLayout;

const useDesktopNavBarStyles = makeStyles((theme) => ({
  root: {
    width: 225,
    height: "100vh",
    background: "#fff",
    display: "flex",
    position: "sticky",
    overflow: "hidden",
    boxShadow: "0 2px 3px 0 #00000029",
    top: 0,
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  leftMenu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc( 100vh - 110px)",
  },
  item: {
    borderLeft: `6px solid transparent`,
    minHeight: 56,
  },
  selected: {
    backgroundColor: "#faf7fc !important",
    borderLeft: `6px solid ${theme.palette.primary.main}`,
  },
  itemText: {
    fontSize: pxToRem(14),
    fontWeight: "bold",
    color: "#757575",
  },
  itemTextSelected: {
    fontSize: pxToRem(14),
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  itemIcon: {
    minWidth: 34,
  },
  logoEnvironmentMark: {
    position: "absolute",
    top: "70px",
    left: "190px",
    transform: "translate(-50%, -50%)",
    fontWeight: "bold",
    fontSize: pxToRem(19),
    color: theme.colours.verdeMgBroker,
  },
}));

const useSelected = (items) => {
  const location = useLocation();

  //Considering two different url paths
  //{feature}/id
  const firstPath = `/${location.pathname.substring(1).split("/")[0]}`;
  //riesgos/automotores/{feature}/params
  const thirdPath = location.pathname.substring(1).split("/")[2];

  const match = items.find((item) =>
    thirdPath ? item.to.includes(thirdPath) : item.to.includes(firstPath)
  );
  return match ? match.to : "/";
};

const DesktopNavBar = ({ items, tabsComponent }) => {
  const dispatch = useDispatch();
  const classes = useDesktopNavBarStyles();

  const salirKey = items.length - 1;

  const selected = useSelected(items);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          <img
            src={logoDesktop}
            style={{
              maxWidth: 160,
              margin: 15,
              marginTop: 25,
              alignSelf: "flex-start",
            }}
            alt="MG-Lira logo"
          />
          {environment.name !== "production" && (
            <Typography className={classes.logoEnvironmentMark}>
              {"TEST"}
            </Typography>
          )}
        </div>
        <List component="nav" style={{ height: "100%" }}>
          <div className={classes.leftMenu}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "calc( 100vh - 115px)",
                overflowY: "auto",
              }}
            >
              {Object.keys(items)
                .slice(0, items.length - 1)
                .map((key) => (
                  <ListItem
                    key={`DesktopNavBar-${key}`}
                    classes={{
                      root: classes.item,
                      selected: classes.selected,
                    }}
                    button
                    selected={selected === items[key].to}
                    component={Link}
                    to={items[key].to}
                    onClick={() => {
                      dispatch(menuClicked(key));
                      if (items[key].actionReset)
                        dispatch(items[key].actionReset());
                    }}
                  >
                    <ListItemIcon className={classes.itemIcon}>
                      {selected === items[key].to ? (
                        <IconFile src={items[key].activeIcon} />
                      ) : (
                        <IconFile src={items[key].inactiveIcon} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={items[key].label}
                      classes={{
                        primary:
                          selected === items[key].to
                            ? classes.itemTextSelected
                            : classes.itemText,
                      }}
                    />
                  </ListItem>
                ))}
            </div>

            {items.length > 0 && (
              <ListItem
                key={`DesktopNavBar-${salirKey}`}
                classes={{ root: classes.item, selected: classes.selected }}
                button
                component={Link}
                to={items[salirKey].to}
                onClick={() => dispatch(menuClicked(salirKey))}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <IconFile src={items[salirKey].inactiveIcon} />
                </ListItemIcon>
                <ListItemText
                  primary={items[salirKey].label}
                  classes={{
                    primary: classes.itemText,
                  }}
                />
              </ListItem>
            )}
          </div>
        </List>
      </div>
    </div>
  );
};

const useMobileNavBarStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    boxShadow: "0 -1px 3px 0 rgba(0, 0, 0, 0.1)",
    zIndex: 999,
    "@media only screen and (device-width : 375px) and (device-height : 812px)  and (-webkit-device-pixel-ratio : 3)":
      {
        paddingBottom: 34,
        height: 56 + 34,
      },
  },
  item: {
    padding: 0,
    paddingTop: "5px !important",
  },
}));

const MobileNavBar = ({ items }) => {
  const dispatch = useDispatch();
  const classes = useMobileNavBarStyles();

  const location = useLocation();
  const selected = `/${location.pathname.substring(1).split("/")[0]}`;

  return (
    <BottomNavigation value={selected} showLabels className={classes.root}>
      {Object.keys(items)
        .slice(0, 4)
        .map((key) => (
          <BottomNavigationAction
            key={`MobileNavBar-${key}`}
            className={classes.item}
            component={Link}
            label={items[key].label}
            to={items[key].to}
            value={items[key].to}
            onClick={() => dispatch(menuClicked(key))}
            icon={
              selected === items[key].to ? (
                <IconFile src={items[key].activeIcon} />
              ) : (
                <IconFile src={items[key].inactiveIcon} />
              )
            }
          />
        ))}
    </BottomNavigation>
  );
};

const IconFile = ({ src }) => {
  return (
    <img alt="" src={src} style={{ color: "#fff", width: 24, height: 24 }} />
  );
};

export { DesktopNavBar };
