import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import {
  paths,
  solicitudesEmisionBackendPath,
} from "~constants/referenceNames";

const solicitudesEmision = () => {
  return {
    getSolicitudesEmision: (data) => {
      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      parameters = data.fechaDesde
        ? parameters + `&FechaDesde=${data.fechaDesde.toISOString()}`
        : parameters;
      if (data.fechaHasta) {
        data.fechaHasta.setHours(23, 59, 59);
        parameters =
          parameters + `&FechaHasta=${data.fechaHasta.toISOString()}`;
      }
      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;

      parameters = data.negocio
        ? parameters + `&Negocio=${data.negocio}`
        : parameters;

      parameters = data.estado
        ? parameters + `&Estados=[${data.estado.join()}]`
        : parameters;

      parameters = data.oficina
        ? parameters + `&OficinaId=${data.oficina}`
        : parameters;

      parameters = data.dniCliente
        ? parameters + `&DNICliente=${data.dniCliente}`
        : parameters;

      const url =
        environment.segurosUrl +
        paths[solicitudesEmisionBackendPath] +
        "?" +
        parameters;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getDetalleSolicitudEmision: (id) => {
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${id}`;

      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getDatosPago: (data) => {
      const id = data;
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${id}/datosPago`;

      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getLogsUsuario: (data) => {
      const id = data;
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${id}/log`;

      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    patchSolicitudEmision: (solicitudEmisionId, data) => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${solicitudEmisionId}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    patchRechazarSolicitud: (solicitudEmisionId, data) => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[solicitudesEmisionBackendPath]}/${solicitudEmisionId}/rechazar`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { solicitudesEmision };
