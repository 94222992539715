import React, { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import waveBackground from "~images/auth/wave-bg.svg";
import Lottie from "lottie-light-react";
import Paper from "@mui/material/Paper";
import loading from "~assets/animations/loading.json";
import LayoutSignIn from "~shared/layout/LayoutSignIn";

interface WaveLayoutProps {
  isLoading: boolean;
  children: ReactNode;
  title?: string;
}

const WaveLayout: React.FC<WaveLayoutProps> = ({
  isLoading,
  children,
  title,
}) => {
  const classes = useStyles();

  return (
    <LayoutSignIn>
      <Paper className={classes.card}>
        {title && (
          <Typography
            variant="h6"
            style={{
              padding: 30,
              color: "#503181",
              fontWeight: "bold",
              fontSize: 19,
            }}
          >
            {title}
          </Typography>
        )}
        {!isLoading && <>{children}</>}
        {isLoading && (
          <div style={{ width: 100 }}>
            <Lottie autoPlay={true} animationData={loading} loop={true} />
          </div>
        )}
      </Paper>
    </LayoutSignIn>
  );
};

export default WaveLayout;

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  wave: {
    position: "absolute",
    width: "100%",
    minHeight: "100vh",
    margin: 0,
    padding: 0,
    backgroundImage: `url(${waveBackground})`,
    backgroundPosition: "-15vw top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "160vw 125vh",
    "@media (min-width:1280px)": {
      // equivalent to theme.breakpoints.up("lg")
      backgroundPosition: "top",
      backgroundSize: "100%",
    },
  },
  header: {
    zIndex: 1,
    display: "flex",
    textAlign: "left",
    maxWidth: "900px",
    flexDirection: "column",
    padding: "7vh 4vw",
    paddingLeft: "2vw",
    "@media (max-width:1024px)": {
      display: "none",
    },
  },
  brandIcon: {
    objectFit: "contain",
    alignSelf: "flex-start",
    height: "3em",
  },
  titleHeader: {
    zIndex: 1,
    display: "flex",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "2.5rem",
    lineHeight: "1.2",
    maxWidth: "35rem",
    marginTop: 30,
  },
  subtitleHeader: {
    zIndex: 1,
    color: "#ffffff",
    fontSize: "1.3rem",
    lineHeight: "1.2",
    maxWidth: "26rem",
    marginTop: 30,
  },
  loginBox: {
    zIndex: 1,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    width: "28rem",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "90vw",
    objectFit: "contain",
    paddingBottom: 10,
    paddingTop: "4em",
    "@media (max-width:1024px)": {
      paddingTop: 44,
    },
  },
  brandIconMobile: {
    height: 60,
    marginBottom: 44,
    "@media (min-width:1024px)": {
      display: "none",
    },
  },
  card: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
    borderRadius: 16,
    width: "90%",
    boxShadow: "0 0 20px 0 #663ea836",
    "@media (max-width:600px)": {
      // equivalent to theme.breakpoints.down("xs")
      width: "100%",
    },
  },
});
