import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import { paths, polizasPath } from "~constants/referenceNames";

let mockActive = false;

const polizas = () => {
  return {
    getPoliza: (id) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      let url = environment.segurosUrl + paths[polizasPath] + "/" + id;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },

    getPolizas: (data) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Skip=${data.skip}&Take=${data.take}`;

      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;

      parameters = data.estado
        ? parameters + `&Estado=${data.estado}`
        : parameters;

      parameters = data.negocio
        ? parameters + `&Negocio=${data.negocio}`
        : parameters;

      parameters = data.compania
        ? parameters + `&Compania=${data.compania}`
        : parameters;

      parameters = data.fechaDesde
        ? parameters + `&FechaDesde=${data.fechaDesde.toISOString()}`
        : parameters;

      if (data.fechaHasta) {
        data.fechaHasta.setHours(23, 59, 59);
        parameters =
          parameters + `&FechaHasta=${data.fechaHasta.toISOString()}`;
      }

      if (data.soloParaRenovar) {
        parameters = parameters + `&SoloParaRenovar=${data.soloParaRenovar}`;
      }

      let url = environment.segurosUrl + paths[polizasPath] + "?" + parameters;

      return mockActive
        ? getPolizasMock(data)
        : fetch(url, request)
            .then(errorUnlessOk)
            .then((response) => {
              return response.json();
            })
            .catch((error) => {
              console.error(error);
              throw error;
            });
    },
    patchPoliza: (polizaId, data) => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/impresos`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postEndosar: (polizaId, data) => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/endosos`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    putAplicarEndoso: (polizaId, endosoId, data) => {
      const request = buildRequestWithAuth("PUT", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/endosos/${endosoId}/aplicacion`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    putRechazarEndoso: (polizaId, endosoId, data) => {
      const request = buildRequestWithAuth("PUT", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/endosos/${endosoId}/rechazo`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postValidatePolizaARenovar: (polizaId) => {
      const request = buildRequestWithAuth("POST", null, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/validate/renovacion`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    postRenovarPoliza: (polizaId, data) => {
      const request = buildRequestWithAuth("POST", data, headersBackendSeguros);
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/renovar`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    patchAnularPoliza: (polizaId, data) => {
      const request = buildRequestWithAuth(
        "PATCH",
        data,
        headersBackendSeguros
      );
      const url = `${environment.segurosUrl}${paths[polizasPath]}/${polizaId}/anular`;
      console.log(url);
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { polizas };

const getPolizasMock = (data) => {
  const polizas = [
    {
      _id: "49d37a99-62e3-4f1a-8cb1-6b3452b4baa3",
      Compania: "Sancor",
      NumeroPoliza: "9002484",
      RenuevaA: null,
      CodigoOrganizador: "102446",
      CodigoProductor: "228074",
      PolizaVigenciaDesde: "2020-02-06T03:00:00.000Z",
      PolizaVigenciaHasta: "2020-08-06T03:00:00.000Z",
      FechaEmision: "2019-02-06T03:00:00.000Z",
      FechaRenovacion: null,
      FechaAnulacion: "2020-02-06T03:00:00.000Z",
      CantidadCuotas: 6,
      Cuotas: [],
      PrimaTotal: null,
      PremioTotal: null,
      CoberturaId: "7",
      TipoCobertura: "TCPremium",
      UbicacionPoliza: null,
      UbicacionTarjeta: null,
      Rama: "Automotores",
      DatosVehiculo: {
        Dominio: "KOM076",
        Codia: 460592,
        Marca: null,
        Modelo: "VOLKSWAGEN AMAROK 20TD 4X2 DC HIGHLINE PK",
        Anio: 2011,
        NumeroChasis: "8AGSS1950BR279123",
        NumeroMotor: "CDC 070564",
        TieneGNC: false,
        SumaAsegurada: "820000.0",
      },
      Asegurado: {
        TipoDocumento: "DNI",
        NumeroDocumento: "29369441",
        NombreCompleto: "TAPIA HEREDIA RICARDO DAVID",
        FechaNacimiento: "1982-04-20T03:00:00.000Z",
        EstadoCivil: null,
        Domicilio: "LEBINSON 386",
        Localidad: "Caleta Olivia",
        CodigoIsoProvincia: "Z",
        Sexo: 0,
        TelefonoFijo: "297 154315256",
        TelefonoCelular: null,
        Email: null,
      },
      OperacionCreditoId: 0,
      FechaVencimientoUltimaCuotaCredito: null,
      FechaCancelacionAnticipadaCredito: null,
      SucursalIdEuro: null,
      SucursalIdEGO: null,
      SucursalNombre: null,
      Negocio: "Prendarios",
      Origen: "Novedades",
      MotivoBajaAnticipadaPoliza: null,
      FechaBajaAnticipadaPoliza: null,
      FechaCreacion: "2020-01-17T18:25:10.538Z",
    },
  ];

  return {
    polizas: polizas,
    totalRegistros: polizas.length,
  };
};
