import React, { useState, useEffect } from "react";
import Select from "react-select";
import { makeStyles } from "@mui/styles";
import { emphasize } from "@mui/system";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import ChipCobertura from "~shared/chipCobertura/ChipCobertura";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 2,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const useReduxSelector = () =>
  useSelector((state) => ({
    tiposCobertura: state.tipoCoberturaReducer.tiposCobertura,
  }));

const CoberturasSelector = ({ value, idsCoberturas, onChangeCoberturas }) => {
  const classes = useStyles();

  const [coberturas, setCoberturas] = useState([]);
  useEffect(() => {
    setCoberturas(
      idsCoberturas
        ? idsCoberturas.map((id) => ({
            value: id.idCobertura,
            label: id.idCobertura,
            tipoCobertura: id.tipoCobertura,
          }))
        : null
    );
  }, [idsCoberturas]);

  const sugerencias = value.sugerencias.map((sugerencia) => ({
    value: sugerencia.idCobertura,
    label: sugerencia.idCobertura,
    tipoCobertura: sugerencia.tipoCobertura,
  }));

  const handleChangeCoberturas = (value) => {
    onChangeCoberturas(
      value
        ? value.map((id) => ({
            idCobertura: id.value,
            tipoCobertura: id.tipoCobertura,
          }))
        : null
    );
    setCoberturas(value);
  };

  return (
    <div className={classes.root}>
      {value && (
        <Select
          classes={classes}
          inputId="react-select-multiple"
          TextFieldProps={{
            InputLabelProps: {
              htmlFor: "react-select-multiple",
              shrink: true,
            },
          }}
          placeholder="Coberturas"
          options={sugerencias}
          components={components}
          value={coberturas}
          onChange={handleChangeCoberturas}
          isMulti
          isClearable={false}
        />
      )}
    </div>
  );
};

export default CoberturasSelector;

const NoOptionsMessage = () => {
  const classes = useStyles();
  return (
    <Typography color="textSecondary" className={classes.noOptionsMessage}>
      {"No hay más coberturas posibles"}
    </Typography>
  );
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control = (props) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

const Option = (props) => {
  const { tiposCobertura } = useReduxSelector();
  const tipoCobertura = tiposCobertura.find((tipo) => {
    return tipo.id === props.data.tipoCobertura;
  });

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children} {tipoCobertura ? `(${tipoCobertura.nombre})` : ""}
    </MenuItem>
  );
};

const Placeholder = (props) => {
  const { innerProps = {}, children } = props;
  const classes = useStyles();
  return (
    <Typography
      color="textSecondary"
      className={classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

const SingleValue = (props) => {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const ValueContainer = (props) => {
  const classes = useStyles();
  return <div className={classes.valueContainer}>{props.children}</div>;
};

const MultiValue = (props) => {
  return (
    <div style={{ padding: "5px 5px 0px 0px" }}>
      <ChipCobertura
        label={props.children}
        onDelete={props.removeProps.onClick}
        tipoCobertura={props.data.tipoCobertura}
      />
    </div>
  );
};

const Menu = (props) => {
  const classes = useStyles();
  return (
    <Paper square className={classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};
