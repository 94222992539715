import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import { format } from "date-fns";

const reportes = () => {
  return {
    getEmisiones: (data) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);

      let url =
        environment.segurosUrl +
        `/reportes/emisiones?Desde=${format(
          data.fechaDesde,
          "yyyy'-'MM'-'dd"
        )}&Hasta=${format(data.fechaHasta, "yyyy'-'MM'-'dd")}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getAnulaciones: (data) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let url =
        environment.segurosUrl +
        `/reportes/anulaciones?Desde=${format(
          data.fechaDesde,
          "yyyy'-'MM'-'dd"
        )}&Hasta=${format(data.fechaHasta, "yyyy'-'MM'-'dd")}`;
      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
    getSiniestros: (data) => {
      const dateWithoutTimezone = (date) => {
        const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
        const withoutTimezone = new Date(date.valueOf() - tzoffset);
        return withoutTimezone;
      }

      const fechaDesdeUTCClear = data.fechaDesde ? dateWithoutTimezone(data.fechaDesde) : "";
      const fechaHastaUTCClear = data.fechaHasta ? dateWithoutTimezone(data.fechaHasta) : "";

      const request = buildRequestWithAuth("GET", data, headersBackendSeguros);
      let parameters = `Estado=${data.estado}`;

      parameters = data.searchCriteria
        ? parameters + `&SearchCriteria=${data.searchCriteria}`
        : parameters;

      parameters = data.tipo && data.tipo !== "Seleccionar"
        ? parameters + `&Tipo=${data.tipo}`
        : parameters;

      parameters = data.fechaDesde
        ? parameters + `&FechaDesde=${fechaDesdeUTCClear.toISOString()}`
        : parameters;
      if (data.fechaHasta) {
        fechaHastaUTCClear.setUTCHours(23, 59, 59);
        parameters =
          parameters + `&FechaHasta=${fechaHastaUTCClear.toISOString()}`;
      }

      let url =
        environment.segurosUrl + `/reportes/siniestros?${parameters}`;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};

export { reportes };
