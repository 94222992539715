import React from "react";
import DetalleCard from "~business/Cards/DetalleCard";
import { useSelector } from "react-redux";
import SecurityIcon from "@mui/icons-material/Security";
import ItemInputCard from "~business/Cards/ItemInputCard";

const useReduxSelector = () =>
  useSelector((state) => {
    const currentSiniestro = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro
      : {};
    const conductor = state.detalleSiniestroReducer.currentSiniestro
      ? state.detalleSiniestroReducer.currentSiniestro.conductor
      : {};
    return {
      ...currentSiniestro,
      ...conductor,
    };
  });

const title = "Datos del Conductor";

const DatosConductorCard = ({ isLoading }) => {
  const { nombre, apellido, numeroDocumento } = useReduxSelector();

  return (
    <>
      <DetalleCard title={title} IconHeader={SecurityIcon}>
        <ItemInputCard
          id={"nombreConductor"}
          isLoading={isLoading}
          label={"Nombre"}
          value={nombre}
          readOnly
        />
        <ItemInputCard
          id={"apellidoConductor"}
          isLoading={isLoading}
          label={"Apellido"}
          value={apellido}
          readOnly
        />
        <ItemInputCard
          id={"numeroDocumento"}
          isLoading={isLoading}
          label={"Número de Documento"}
          value={numeroDocumento}
          readOnly
        />
      </DetalleCard>
    </>
  );
};

export default DatosConductorCard;
