import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import ItemLoadingCard from "./ItemLoadingCard.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  body: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  cardHeader: {
    display: "flex",
    flexWrap: "wrap",
    padding: "2%",
    borderBottom: "1px solid #6240a5",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "stretch",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 50,
    borderRadius: "5px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.colours.white,
    padding: "4%",
    margin: "2%",
  },
  cardIcon: {
    color: "#6240a5",
    marginRight: "1%",
  },
  cardTitle: {
    fontFamily: "Nunito",
    fontSize: "1.12em",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6240a5",
  },
}));

const DetalleCard = ({ isLoading, title, IconHeader, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <div className={classes.cardContainer}>
          <div className={classes.card}>
            <div className={classes.cardHeader}>
              {IconHeader && <IconHeader className={classes.cardIcon} />}
              <Typography className={classes.cardTitle}>{title}</Typography>
            </div>
            {isLoading && (
              <>
                <ItemLoadingCard />
                <ItemLoadingCard />
                <ItemLoadingCard />
                <ItemLoadingCard />
                <ItemLoadingCard />
                <ItemLoadingCard />
                <ItemLoadingCard />
              </>
            )}
            {!isLoading && children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalleCard;
