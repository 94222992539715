import React from "react";
import { makeStyles } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import { negociosSeguros } from "~constants/negocios";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexDirection: "row",
    paddingBottom: 10,
    alignItems: "flex-start",
  },
});

const MainData = ({ detalleCotizacion }) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <MainDataItem
        caption={"Negocio"}
        value={negociosSeguros[detalleCotizacion.negocio]}
      />
      <MainDataItem caption={"Origen"} value={detalleCotizacion.origen} />
      <MainDataItem
        caption={"Cotizó"}
        value={
          detalleCotizacion.nombrePersona !== null
            ? detalleCotizacion.nombrePersona
            : "-"
        }
      />
      <MainDataItem
        caption={"E-Mail"}
        value={detalleCotizacion.email !== null ? detalleCotizacion.email : "-"}
      />
      <MainDataItem
        caption={"Teléfono"}
        value={
          detalleCotizacion.telefono !== null ? detalleCotizacion.telefono : "-"
        }
      />
      <MainDataItem
        caption={"Vehículo"}
        value={`(${detalleCotizacion.anio}) ${detalleCotizacion.marca} ${detalleCotizacion.modelo}`}
      />
      <MainDataItem
        caption={"Localidad"}
        value={`(${detalleCotizacion.codigoPostal}) ${
          detalleCotizacion.localidad ?? "-"
        }, ${detalleCotizacion.provincia ?? "-"}`}
      />

      <MainDataItem
        caption={"Forma de Pago"}
        value={detalleCotizacion.formaPago}
      />

      <MainDataItem
        caption={"GNC"}
        value={detalleCotizacion.tieneGNC ? "Sí" : "No"}
      />
    </Toolbar>
  );
};

export default MainData;

const useStylesItem = makeStyles((theme) => ({
  mainDataItemContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 30,
  },
  mainDataTitle: {
    fontFamily: "Nunito",
    fontSize: "0.7em",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.3,
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey2,
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
  mainDataValue: {
    //width: "102px",
    height: "19px",
    fontFamily: "Nunito",
    fontSize: "0.8em",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.36,
    letterSpacing: "normal",
    textAlign: "left",
    color: theme.colours.grey5,
  },
}));

const MainDataItem = ({ caption, value }) => {
  const classes = useStylesItem();

  return (
    <div className={classes.mainDataItemContainer}>
      <Typography className={classes.mainDataTitle}>{caption}</Typography>
      <Typography className={classes.mainDataValue}>{value}</Typography>
    </div>
  );
};
