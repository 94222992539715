import {
  errorUnlessOk,
  buildRequestWithAuth,
  headersBackendSeguros,
} from "./utils.js";
import environment from "~libs/environment";
import { paths, endososPath } from "~constants/referenceNames";

const endosos = () => {
  return {
    getEndosos: ({ take, skip, searchCriteria, estado }) => {
      const request = buildRequestWithAuth("GET", null, headersBackendSeguros);
      let parameters = `Skip=${skip}&Take=${take}`;

      parameters = searchCriteria
        ? parameters + `&SearchCriteria=${searchCriteria}`
        : parameters;

      parameters = estado ? parameters + `&Estado=${estado}` : parameters;

      let url = environment.segurosUrl + paths[endososPath] + "?" + parameters;

      return fetch(url, request)
        .then(errorUnlessOk)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    },
  };
};
export { endosos };
